import React from 'react'
import { Grid, Typography, useTheme, Box } from '@mui/material'
import { infoTableContainerStyles, infoTableHeaderStyles, tableSectionStyles, infoTableTextStyles } from './style'

const GenerateTotalsTable = ({ options, section }) => {
  const theme = useTheme()

  return (
    <>
      {section && (
        <Box sx={{ bgcolor: theme.palette.background.default, p: 1 }}>
          <Typography variant='h6' sx={tableSectionStyles(theme)}>
            {section}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between', // Evenly space content inside
          // p: 2,
          borderRadius: '25px',
          bgcolor: theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ...infoTableContainerStyles(theme),
            p: 2,
          }}
        >
          {options.map((entry, rowIndex) => (
            <Grid
              item
              xs={12}
              key={`row-${rowIndex}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // Ensure the label and value are spaced evenly
                flexGrow: 1, // Allow the row to grow and fill available space
                p: 1,
              }}
            >
              {Object.keys(entry).map((key, keyIndex) => (
                <Box key={`cell-${rowIndex}-${keyIndex}`} sx={{ mb: 1 }}>
                  {/* Label */}
                  <Typography variant='caption' color='textSecondary' sx={infoTableHeaderStyles(theme)}>
                    {key}
                  </Typography>
                  {/* Value */}
                  <Typography
                    variant='body1'
                    sx={{
                      ...infoTableTextStyles(theme),
                      color: entry[key].color || theme.palette.text.primary, // Set color dynamically
                    }}
                  >
                    {entry[key].type === 'number' ? `$${entry[key].value}` : entry[key].value || 'N/A'}
                  </Typography>
                </Box>
              ))}
            </Grid>
          ))}
        </Box>
      </Box>
    </>
  )
}

export default GenerateTotalsTable
