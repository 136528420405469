import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/mutationFunctions'

const updateCampaign = async ({ campaignId, campaignData }) => {
  const url = `${baseUrl}/aws/updatecampaign/${campaignId}`
  const response = await axios.put(url, campaignData)
  return response.data
}

const useSaveCampaign = () => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()

  const mutation = useMutation({
    mutationFn: updateCampaign,
    onMutate: async ({ campaignId, campaignData }) => {
      await queryClient.cancelQueries(['/aws/campaigns', campaignId])
      const previousCampaign = queryClient.getQueryData(['/aws/campaigns', campaignId])

      // queryClient.setQueryData(['/aws/campaigns', campaignId], old => ({
      //   ...old,
      //   ...campaignData,
      // }))

      return { previousCampaign }
    },
    onError: (err, { campaignId }, context) => {
      queryClient.setQueryData(['/aws/campaigns', campaignId], context.previousCampaign)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: (_, { campaignId }) => {
      // queryClient.invalidateQueries(['/aws/campaigns', campaignId])
      // queryClient.invalidateQueries(['/aws/organizations'])
      showSnackbar('Campaign updated successfully', 'success')
    },
  })

  return mutation
}

export default useSaveCampaign
