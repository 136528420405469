import React from 'react'
import { Box, Avatar, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
const RenderContractedByColumn = ({ orgName, orgId, densityStyles }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        height: '100%',
        padding: densityStyles.padding,
        gap: densityStyles.gap,
      }}
    >
      <Link to={`/organizations/${orgId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'left',
            height: '100%',
            width: '100%',
            fontWeight: 'bold',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: densityStyles.fontSize,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              {orgName ? orgName : ''}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  )
}

export default RenderContractedByColumn
