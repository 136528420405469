import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

export const calculateDayjsTotalMonths = deal => {
  const products = deal?.products || []

  // Safely map over products and parse start and end dates using dayjs
  const dateRanges = products
    .map(product => {
      const startDate = product?.startDate?.$d
        ? dayjs(product.startDate.$d)
        : product?.startDate
        ? dayjs(product?.startDate)
        : null
      const endDate = product?.endDate?.$d
        ? dayjs(product.endDate.$d)
        : product?.endDate
        ? dayjs(product?.endDate)
        : null

      // Ensure that both dates are valid before proceeding
      if (!startDate || !endDate || !startDate.isValid() || !endDate.isValid()) {
        console.error('Invalid start or end date for product:', product)
        return null // Return null if dates are invalid
      }

      return { start: startDate, end: endDate }
    })
    .filter(Boolean) // Remove any null values from the array

  // If no valid date ranges are found, return early
  if (dateRanges.length === 0) {
    return {
      totalMonths: 0,
      dateRange: { start: null, end: null },
    }
  }

  // Find the earliest start date and the latest end date
  const earliestStartDate = dayjs.min(...dateRanges.map(range => range.start))
  const latestEndDate = dayjs.max(...dateRanges.map(range => range.end))

  // Calculate the total months between the earliest start and the latest end date
  const totalMonths = latestEndDate.diff(earliestStartDate, 'month', true)

  // Check if all product date ranges have the same duration
  const allSame = dateRanges.every(range => {
    const duration = range.end.diff(range.start, 'month', true)
    return Math.ceil(duration) === Math.ceil(totalMonths)
  })

  return {
    totalMonths: Math.ceil(totalMonths),
    dateRange: {
      start: earliestStartDate.format('MM/DD/YYYY'),
      end: latestEndDate.format('MM/DD/YYYY'),
    },
    allSame, // Returns true if all date ranges have the same duration
  }
}

export const calculateTotalMonthsForProducts = products => {
  // Safely map over products and parse start and end dates using dayjs
  const dateRanges = products
    .map(product => {
      const startDate = product?.startDate?.$d
        ? dayjs(product.startDate.$d)
        : product?.startDate
        ? dayjs(product?.startDate)
        : null
      const endDate = product?.endDate?.$d
        ? dayjs(product.endDate.$d)
        : product?.endDate
        ? dayjs(product?.endDate)
        : null

      // Ensure that both dates are valid before proceeding
      if (!startDate || !endDate || !startDate.isValid() || !endDate.isValid()) {
        console.error('Invalid start or end date for product:', product)
        return null // Return null if dates are invalid
      }

      return { start: startDate, end: endDate }
    })
    .filter(Boolean) // Remove any null values from the array

  // If no valid date ranges are found, return early
  if (dateRanges.length === 0) {
    return {
      totalMonths: 0,
      dateRange: { start: null, end: null },
    }
  }

  // Find the earliest start date and the latest end date
  const earliestStartDate = dayjs.min(...dateRanges.map(range => range.start))
  const latestEndDate = dayjs.max(...dateRanges.map(range => range.end))

  // Calculate the total months between the earliest start and the latest end date
  const totalMonths = latestEndDate.diff(earliestStartDate, 'month', true)

  // Check if all product date ranges have the same duration
  const allSame = dateRanges.every(range => {
    const duration = range.end.diff(range.start, 'month', true)
    return Math.ceil(duration) === Math.ceil(totalMonths)
  })

  return {
    totalMonths: Math.ceil(totalMonths),
    dateRange: {
      start: earliestStartDate.format('MM/DD/YYYY'),
      end: latestEndDate.format('MM/DD/YYYY'),
    },
    allSame, // Returns true if all date ranges have the same duration
  }
}
export const calculateRangeForProducts = products => {
  // Ensure products is an array
  if (!Array.isArray(products)) {
    console.error('Invalid products array')
    return []
  }

  // Map over products and calculate the number of months for each
  return products.map(product => {
    const startDate = product?.startDate?.$d
      ? dayjs(product.startDate.$d)
      : product?.startDate
      ? dayjs(product?.startDate)
      : null
    const endDate = product?.endDate?.$d ? dayjs(product.endDate.$d) : product?.endDate ? dayjs(product?.endDate) : null

    // Ensure that both dates are valid
    if (!startDate || !endDate || !startDate.isValid() || !endDate.isValid()) {
      console.error('Invalid start or end date for product:', product)
      return { ...product, months: 0 } // Return 0 months for invalid products
    }

    // Calculate the number of months for this product
    const totalMonthsForProduct = endDate.diff(startDate, 'month', true)
    return { ...product, months: Math.ceil(totalMonthsForProduct) }
  })
}
export const calculateTotalMonthsForSingleProduct = (startDate, endDate) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  // Check if both dates are valid
  if (!start.isValid() || !end.isValid()) {
    console.error('Invalid start or end date')
    return 0 // Return 0 if dates are invalid
  }

  // Calculate the total months between the start and end date
  const totalMonths = end.diff(start, 'month', true)
  return Math.ceil(totalMonths) // Round up to the nearest full month
}
export const calculateMonthsForDeal = (startDate, endDate) => {
  const start = dayjs(startDate)
  const end = dayjs(endDate)

  // Check if both dates are valid
  if (!start.isValid() || !end.isValid()) {
    console.error('Invalid start or end date')
    return 0 // Return 0 if dates are invalid
  }

  // Calculate the total months between the start and end date
  const totalMonths = end.diff(start, 'month', true)
  return Math.ceil(totalMonths) // Round up to the nearest full month
}
