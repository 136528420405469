import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import duration from 'dayjs/plugin/duration'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(duration)
dayjs.extend(minMax)

export const calculateAccountingSchedule = deal => {
  const paymentSchedule = {}
  const csaTypes = ['CSA', 'RCSA']

  // Check if the deal is a CSA type and has payments
  if (csaTypes.includes(deal.type) && deal.numberOfPayments) {
    const numberOfPayments = deal.numberOfPayments

    // Create a separate schedule entry for each payment
    for (let i = 0; i < numberOfPayments; i++) {
      const key = `Payment ${i + 1}`

      paymentSchedule[key] = {
        paymentNumber: i + 1,
        totalAmount: 0,
        products: [],
      }

      // Distribute product prices among payments
      deal.products.forEach(product => {
        const productPrice = product.payer === 'Client' ? 0 : parseFloat(product.price)
        const paymentAmount = productPrice / numberOfPayments

        paymentSchedule[key].totalAmount += paymentAmount
        paymentSchedule[key].products.push({
          productName: product.productName,
          paymentAmount,
        })
      })
    }
  } else {
    // Existing logic for non-CSA deals
    deal.products.forEach(product => {
      const productStart = dayjs(product.startDate)
      const productEnd = dayjs(product.endDate)

      // Set price to 0 if the payer is 'Client'
      const productPrice =
        product.payer === 'Client' && product.margin === 0
          ? 0
          : product.payer === 'Client' && product.margin > 0
          ? parseFloat(product.grossProfit)
          : parseFloat(product.price)

      for (
        let current = productStart.startOf('month');
        current.isSameOrBefore(productEnd);
        current = current.add(1, 'month')
      ) {
        const month = current.format('MM')
        const year = current.format('YYYY')
        const key = `${year}-${month}`

        if (!paymentSchedule[key]) {
          paymentSchedule[key] = {
            month,
            year,
            startDate: current.startOf('month').format('MM/DD/YYYY'),
            endDate: current.endOf('month').format('MM/DD/YYYY'),
            totalAmount: 0,
            products: [],
          }
        }

        const paymentAmount = productPrice
        paymentSchedule[key].totalAmount += paymentAmount
        paymentSchedule[key].payer = product.payer
        paymentSchedule[key].products.push({
          productName: product.productName,
          payer: product.payer,
          paymentAmount,
        })
      }
    })
  }

  return Object.values(paymentSchedule)
}

// import dayjs from 'dayjs'
// import utc from 'dayjs/plugin/utc'
// import timezone from 'dayjs/plugin/timezone'
// import advancedFormat from 'dayjs/plugin/advancedFormat'
// import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
// import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
// import duration from 'dayjs/plugin/duration'
// import minMax from 'dayjs/plugin/minMax'

// dayjs.extend(utc)
// dayjs.extend(timezone)
// dayjs.extend(advancedFormat)
// dayjs.extend(isSameOrBefore)
// dayjs.extend(isSameOrAfter)
// dayjs.extend(duration)
// dayjs.extend(minMax)

// export const calculateAccountingSchedule = deal => {
//   const paymentSchedule = {}

//   deal.products.forEach(product => {
//     const productStart = dayjs(product.startDate)
//     const productEnd = dayjs(product.endDate)

//     // Iterate over each month between the product's start and end date
//     for (
//       let current = productStart.startOf('month');
//       current.isSameOrBefore(productEnd);
//       current = current.add(1, 'month')
//     ) {
//       const month = current.format('MM')
//       const year = current.format('YYYY')
//       const key = `${year}-${month}`

//       if (!paymentSchedule[key]) {
//         paymentSchedule[key] = {
//           month,
//           year,
//           startDate: current.startOf('month').format('MM/DD/YYYY'),
//           endDate: current.endOf('month').format('MM/DD/YYYY'),
//           totalAmount: 0,
//           products: [],
//         }
//       }

//       const paymentAmount = parseFloat(product.price)
//       paymentSchedule[key].totalAmount += paymentAmount
//       paymentSchedule[key].products.push({
//         productName: product.productName,
//         paymentAmount,
//       })
//     }
//   })

//   return Object.values(paymentSchedule)
// }
