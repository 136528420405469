import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Typography, useTheme } from '@mui/material'
const EditStrategyButton = ({ onEdit }) => {
  const theme = useTheme()
  return (
    <IconButton
      onClick={onEdit}
      sx={{
        gap: 2,
        borderRadius: 0,
        color: theme.palette.text.secondary,
        '&:hover': {
          color: theme.palette.text.primary,
          fontWeight: 'bold',
        },
        '&:hover .MuiSvgIcon-root': {
          color: 'rgb(1, 251, 248)',
        },
        pr: 2,
      }}
    >
      <EditIcon sx={{ height: 30, width: 30, color: theme.palette.text.secondary }} />
      <Typography>Edit</Typography>
    </IconButton>
  )
}

export default EditStrategyButton
