import React, { createContext, useContext, useState } from 'react'

const ValidationContext = createContext()

export const ValidationProvider = ({ children }) => {
  const [errors, setErrors] = useState({})
  const [strategyErrors, setStrategyErrors] = useState({})
  const hasErrors = Object.values(errors).some(error => error)
  const errorStyle = {
    borderColor: 'red',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  }
  // const isValidWebsite = url => {
  //   // console.log('Validating: ', url)
  //   const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.[a-zA-Z]{2,6})(\/.*)?$/
  //   // console.log('isValidUrl? : ', pattern.test(url))
  //   return pattern.test(url)
  // }
  const isValidWebsite = url => {
    // Trim the URL and remove any non-printable characters
    const trimmedUrl = url.trim().replace(/[\u200B-\u200D\uFEFF]/g, '') // Removes zero-width characters

    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol (optional)
      "((([a-zA-Z0-9$_.+!*'(),;:&=~-]+)@)?" + // authentication (optional)
      '([a-zA-Z0-9-]+\\.)*' + // subdomain (optional)
      '([a-zA-Z0-9-]{1,63}\\.[a-zA-Z]{2,6}))' + // main domain and extension
      '(\\:\\d+)?' + // port (optional)
      '(\\/[-a-zA-Z0-9%_.~+]*)*' + // path (optional)
      '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string (optional)
        '(\\#[-a-zA-Z0-9_]*)?$' // fragment (optional)
    )

    return pattern.test(trimmedUrl)
  }

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        return value ? '' : 'Name is required'
      case 'email':
        return /\S+@\S+\.\S+/.test(value) ? '' : 'Email is invalid'
      case 'website': {
        if (!value) return ''

        // Trim and sanitize URL before validation
        const trimmedValue = value.trim().replace(/[\u200B-\u200D\uFEFF]/g, '') // Remove zero-width characters

        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol (optional)
          "((([a-zA-Z0-9$_.+!*'(),;:&=~-]+)@)?" + // authentication (optional)
          '([a-zA-Z0-9-]+\\.)*' + // subdomain (optional, can be multiple levels)
          '([a-zA-Z0-9-]{1,63}\\.[a-zA-Z]{2,6}))' + // main domain and extension
          '(\\:\\d+)?' + // port (optional)
          '(\\/[-a-zA-Z0-9%_.~+]*)*' + // path (optional)
          '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string (optional)
            '(\\#[-a-zA-Z0-9_]*)?$' // fragment (optional)
        )

        return urlPattern.test(trimmedValue) ? '' : 'Please enter a valid URL'
      }
      case 'vertical':
        return value ? '' : 'Vertical is required'
      default:
        return ''
    }
  }
  const validateStrategy = strategy => {
    const errors = {
      street: !strategy?.addtlInfo?.street,
      city: !strategy?.addtlInfo?.city,
      state: !strategy?.addtlInfo?.state,
      zip: !strategy?.addtlInfo?.zip,
      cmsUsed: !strategy?.addtlInfo?.cmsUsed,
      productsAndServices: !strategy?.addtlInfo?.productsAndServices,
      demographicTargeting: !strategy?.addtlInfo?.demographicTargeting,
      marketingObjective: !strategy?.addtlInfo?.marketingObjective,
      geoTargeting: !strategy?.addtlInfo?.geoTargeting,
      digitalAdPhone: !strategy?.addtlInfo?.digitalAdPhone,
      website: !strategy?.addtlInfo?.website,
    }

    // Check each product in `updatedProducts` for required fields
    const productErrors = {}
    if (strategy?.updatedProducts && Array.isArray(strategy.updatedProducts)) {
      strategy.updatedProducts.forEach(product => {
        const productError = {}
        if (!product.creativeSuppliedBy) productError.creativeSuppliedBy = 'Required'
        if (!product.copySuppliedBy) productError.copySuppliedBy = 'Required'
        if (!product.marketingObjective) productError.marketingObjective = 'Required'
        if (Object.keys(productError).length > 0) {
          productErrors[product.instanceId] = productError
        }
      })
    }

    setStrategyErrors({ ...errors, updatedProducts: productErrors })
    return (
      !Object.values(errors).some(Boolean) &&
      Object.values(productErrors).every(prodErr => !Object.values(prodErr).some(Boolean))
    )
  }

  return (
    <ValidationContext.Provider
      value={{
        validateStrategy,
        validateField,
        isValidWebsite,
        errors,
        setErrors,
        hasErrors,
        errorStyle,
        strategyErrors,
      }}
    >
      {children}
    </ValidationContext.Provider>
  )
}

export const useValidationContext = () => useContext(ValidationContext)
