import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/mutationFunctions'

const updateStrategy = async ({ strategyId, strategyData }) => {
  const url = `${baseUrl}/aws/updatestrategy/${strategyId}`
  const response = await axios.put(url, strategyData)
  return response.data
}

const useSaveStrategy = () => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()

  const mutation = useMutation({
    mutationFn: updateStrategy,
    onMutate: async ({ strategyId, strategyData }) => {
      await queryClient.cancelQueries(['/aws/strategy', strategyId])
      const previousStrategy = queryClient.getQueryData(['/aws/strategy', strategyId])

      // queryClient.setQueryData(['/aws/strategy', strategyId], old => ({
      //   ...old,
      //   ...strategyData,
      // }))

      return { previousStrategy }
    },
    onError: (err, { strategyId }, context) => {
      queryClient.setQueryData(['/aws/strategy', strategyId], context.previousStrategy)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: (_, { strategyId }) => {
      // queryClient.invalidateQueries(['/aws/strategy', strategyId])
      // queryClient.invalidateQueries(['/aws/organizations'])
      showSnackbar('Strategy updated successfully', 'success')
    },
  })

  return mutation
}

export default useSaveStrategy
