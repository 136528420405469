import { Button, styled } from '@mui/material'

export const HeaderButton = styled(Button)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'rgb(51, 67, 91)' : 'rgb(211, 211, 211)',
  backgroundColor: 'rgb(29, 106, 82)',
  color: theme.palette.mode === 'dark' ? 'white' : 'white',
}))
export const headerStyle = theme => ({
  fontWeight: 'bold',
  fontSize: 24,
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
})
