import { Box, Stack, Tooltip, useTheme } from '@mui/material'
import GroGoogleIcon from '../../images/icons/Google Ads.png'
import GroGoogleDisplayIcon from '../../images/icons/Google Display.png'
import GroMetaIcon from '../../images/icons/meta (1).svg'
import GroTikTokIcon from '../../images/icons/tik-tok.png'
import GroWebsiteHostingIcon from '../../images/icons/hosting.svg'
import GroSeoIcon from '../../images/icons/seo.png'
import GroSeoTier1Icon from '../../images/icons/SEO - Tier 1.png'
import GroSeoTier2Icon from '../../images/icons/SEO - Tier 2.png'
import GroSeoTier3Icon from '../../images/icons/SEO - Tier 3.png'
import GroSeoTierCustomIcon from '../../images/icons/SEO - Custom.png'
import GroSpotifyIcon from '../../images/icons/spotify.svg'
import GroMicrosoftIcon from '../../images/icons/bing.png'
import GroYouTubeIcon from '../../images/icons/youtube.png'
import GroCreativeIcon from '../../images/icons/Creative.png'
import GroWebsiteDevelopmentIcon from '../../images/icons/Website Development.png'
import GroVideographyIcon from '../../images/icons/Videography.png'
import GroPhotographyIcon from '../../images/icons/Photography.png'
import GroConsultingIcon from '../../images/icons/Consulting.png'
import YouTubeIcon from '../../images/icons/youtube.svg'
import LinkedinIcon from '../../images/icons/linkedin.svg'
import QuestionMarkIcon from '../../images/icons/favicon-16x16.png'
import SimplifiSvg from '../../images/icons/simplifi_logo.svg'
import Seo1Svg from '../../images/icons/seotier1.svg'
import Seo2Svg from '../../images/icons/seotier2.svg'
import Seo3Svg from '../../images/icons/seotier3.svg'
import ElementorSvg from '../../images/icons/elementor.svg'
import CreativeCloudSvg from '../../images/icons/creative-cloud.svg'
import AmazonSvg from '../../images/icons/amazon.svg'
import SnapChatSvg from '../../images/icons/snapchat.svg'
import BudgetSvg from '../../images/icons/budget.svg'
import DarkBudgetSvg from '../../images/icons/darkBudget.svg'

export const getProductIcon = productName => {
  switch (productName) {
    case 'Paid Search Advertising':
      return GroGoogleIcon
    case 'Google Display Advertising':
      return GroGoogleDisplayIcon
    case 'Meta Advertising':
      return GroMetaIcon
    case 'YouTube Advertising':
      return GroYouTubeIcon
    case 'Amazon Advertising':
      return AmazonSvg
    case 'SnapChat Advertising':
      return SnapChatSvg
    case 'TikTok Advertising':
      return GroTikTokIcon
    case 'Website Hosting':
      return GroWebsiteHostingIcon
    case 'Website Hosting + 30 Minutes of Maintenance':
      return GroWebsiteHostingIcon
    case 'Website Hosting + 60 Minutes of Maintenance':
      return GroWebsiteHostingIcon
    case 'Website Revisions':
      return ElementorSvg
    case 'SEO - Tier 1':
      return Seo2Svg
    case 'SEO - Tier 2':
      return Seo2Svg
    case 'SEO - Tier 3':
      return Seo2Svg
    case 'SEO - Tier 4':
      return GroSeoTierCustomIcon
    case 'Spotify Advertising':
      return GroSpotifyIcon
    case 'Microsoft Advertising':
      return GroMicrosoftIcon
    case 'Graphic Design - General':
      return GroCreativeIcon
    case 'Graphic Design - Logo Creation':
      return GroCreativeIcon
    case 'Graphic Design - Additional Logo Revisions':
      return GroCreativeIcon
    case 'Graphic Design - Revisions':
      return GroCreativeIcon
    case 'Video Shoot':
      return GroVideographyIcon
    case 'Video Editing':
      return GroVideographyIcon
    case 'Photo Shoot':
      return GroPhotographyIcon
    case 'Photo Editing':
      return GroPhotographyIcon
    case 'Consulting':
      return GroConsultingIcon
    case 'Youtube Advertising':
      return YouTubeIcon
    case 'Linkedin Advertising':
      return LinkedinIcon
    case 'Programmatic Display Advertising':
      return SimplifiSvg
    case 'Programmatic Video Advertising':
      return SimplifiSvg
    case 'Programmatic Audio Advertising':
      return SimplifiSvg
    case 'Standard Website Build':
      return ElementorSvg
    default:
      return QuestionMarkIcon
  }
}

const RenderServiceIcons = ({ services }) => {
  // console.log('SERVICES: ', services)
  const uniqueNames = new Set(services)
  const uniqueIcons = [...uniqueNames]
  const theme = useTheme()

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      {uniqueIcons.map((service, index) => {
        return (
          <Tooltip key={`${index}-${service}`} title={service}>
            <img
              key={`${index}-${service}`}
              src={getProductIcon(service)}
              alt={service}
              style={{ width: 24, height: 24 }}
            />
          </Tooltip>
        )
      })}
    </Stack>
  )
}

export default RenderServiceIcons
