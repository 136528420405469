import React, { useEffect } from 'react'
import { Grid, Box, Typography, TextField, MenuItem, useTheme, InputLabel } from '@mui/material'
import { productFieldsConfig } from './strategyFieldConfig'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'

const RenderFields = ({ strategy, service, isEditMode, onChange, errors = {} }) => {
  const fieldsConfig = productFieldsConfig[service.productName] || []
  const theme = useTheme()
  const handleChange = (fieldName, value) => {
    onChange(service.instanceId, fieldName, value)
  }
  const renderField = ({ fieldName, label, options }) => {
    // console.log(('fieldName', fieldName), 'label', label, 'options', options)
    const fieldValue = service[fieldName] || ''
    const hasError = Boolean(errors && errors[fieldName])
    const commonProps = {
      name: fieldName,
      value: fieldValue,
      onChange: e => handleChange(fieldName, e.target.value),
      fullWidth: true,
      variant: 'outlined',
      size: 'small',
      error: hasError,
      sx: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.input,
        borderRadius: '4px',
      },
    }
    return (
      <Grid item xs={12} sm={6} key={`${fieldName}-${service.instanceId}`}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 0.5 }}>
          <Typography sx={{ width: '40%', ml: 2 }}>{label}</Typography>
          {isEditMode ? (
            options ? (
              <TextField
                // helperText={field.placeholder}

                select
                {...commonProps}
              >
                {options.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField {...commonProps} />
            )
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                padding: '8px 16px',
                backgroundColor: theme.palette.background.input,
                borderRadius: '4px',
                fontSize: '0.875rem',
                color: theme.palette.mode === 'dark' ? 'rgb(181, 191, 205)' : 'rgb(97, 97, 97)',
              }}
            >
              {fieldValue || strategy?.addtlInfo?.[fieldName] || '—'}
            </Box>
          )}
        </Box>
      </Grid>
    )
  }

  return (
    <Box sx={{ padding: 1.5 }}>
      <Grid container spacing={0}>
        {fieldsConfig.map(renderField)}
      </Grid>
    </Box>
  )
}

export default RenderFields
