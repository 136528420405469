import React from 'react'
import { IconButton, Typography, TextField, useTheme, Grid, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { typographyStyle } from './style'
import EditAds from './EditAds'

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1)
}

export const renderLabels = key => {
  switch (key) {
    case 'performanceGoal':
      return 'Performance Goal'
    case 'setName':
      return 'Set Name'
    case 'conversionEvent':
      return 'Conversion Event'
    case 'conversionLocation':
      return 'Conversion Location'
    case 'geoTargeting':
      return 'Geo Targeting'
    case 'demographicTargeting':
      return 'Demographic Targeting'
    case 'landingPageUrl':
      return 'Landing Page Url'
    case 'keyWords':
      return 'Keywords'
    case 'adGroup':
      return 'Ad Group'
    default:
      return capitalizeFirstLetter(key)
  }
}

const EditCampaign = ({ campaignState, onChange, onCreateAdSet }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: theme.palette.background.section,
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
        p: 2,
        mb: 5,
      }}
    >
      <Grid container spacing={2}>
        {/* Main Campaign Fields */}
        {Object.entries(campaignState).map(([key, value], index) => {
          if (key === 'name') {
            return (
              <Grid item xs={12} key={`${index}-${key}`}>
                <TextField
                  label={renderLabels(key)}
                  name={key}
                  value={value || ''}
                  onChange={e => onChange(key, e.target.value)}
                  variant='outlined'
                  fullWidth
                  size='small'
                />
              </Grid>
            )
          }
        })}
        {Object.entries(campaignState).map(([key, value], index) => {
          if (
            [
              'name',
              'key',
              'id',
              'campaign_uuid',
              'strategy_uuid',
              'keywords',
              'negativeKeywords',
              'ads',
              'items',
              'timestamp',
              'exclusions',
            ].includes(key)
          )
            return null

          return (
            <Grid item xs={12} sm={6} key={`${index}-${key}`}>
              <TextField
                label={renderLabels(key)}
                name={key}
                value={value || ''}
                onChange={e => onChange(key, e.target.value)}
                variant='outlined'
                fullWidth
                size='small'
                disabled={key === 'type'}
              />
            </Grid>
          )
        })}

        {/* Render Keywords and Negative Keywords */}
        {['keywords', 'negativeKeywords', 'exclusions'].map((key, index) =>
          campaignState[key] ? (
            <Grid item xs={12} sm={6} key={`${index}-${key}`}>
              <TextField
                label={renderLabels(key)}
                name={key}
                value={campaignState[key]?.join('\n')}
                onChange={e => onChange(key, e.target.value.split('\n'))}
                variant='outlined'
                fullWidth
                size='small'
                multiline
                rows={4}
                placeholder='Enter each item on a new line'
              />
            </Grid>
          ) : null
        )}
      </Grid>

      {/* Render Ads */}
      {Array.isArray(campaignState.ads) &&
        campaignState.ads.map((ad, idx) => (
          <React.Fragment key={`ad-${idx}`}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>{`Ad Set ${idx + 1}`}</Typography>
            </Grid>
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'rgba(255,255,255,1)',
                bgcolor: theme.palette.background.default,
              }}
            >
              <Grid container spacing={2} p={2}>
                {/* Render all ad fields except audience */}
                {Object.entries(ad).map(([adKey, adValue]) => {
                  if (['id', 'key', 'setName', 'audience', 'timestamp'].includes(adKey)) return null

                  return (
                    <Grid item xs={12} sm={6} key={`${adKey}-${idx}`}>
                      <TextField
                        label={renderLabels(adKey)}
                        value={adValue || ''}
                        onChange={e => onChange(`ads[${idx}].${adKey}`, e.target.value)}
                        variant='outlined'
                        fullWidth
                        size='small'
                      />
                    </Grid>
                  )
                })}
                {/* Render audience field last */}
                {ad.audience && Array.isArray(ad.audience) && (
                  <Grid item xs={12} sm={6} key={`audience-${idx}`}>
                    <TextField
                      label='Audience'
                      value={ad.audience.join('\n')}
                      onChange={e => onChange(`ads[${idx}].audience`, e.target.value.split('\n'))}
                      variant='outlined'
                      fullWidth
                      size='small'
                      multiline
                      rows={6}
                      placeholder='Enter each audience on a new line'
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </React.Fragment>
        ))}

      {/* Render Campaign Items */}
      {Array.isArray(campaignState.items) &&
        campaignState.items.map((item, idx) => (
          <React.Fragment key={`item-${idx}`}>
            <Grid item xs={12}>
              <Typography variant='h6'>{`Campaign Item ${idx + 1}`}</Typography>
            </Grid>
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'rgba(255,255,255,1)',
                bgcolor: theme.palette.background.default,
              }}
            >
              <Grid container spacing={2} p={2}>
                {Object.entries(item).map(([itemKey, itemValue]) => {
                  if (['campaign_uuid', 'id'].includes(itemKey)) return null

                  return (
                    <Grid item xs={12} sm={6} key={`${itemKey}-${idx}`}>
                      <TextField
                        label={renderLabels(itemKey)}
                        value={Array.isArray(itemValue) ? itemValue.join('\n') : itemValue || ''}
                        onChange={e =>
                          onChange(
                            `items[${idx}].${itemKey}`,
                            Array.isArray(itemValue) ? e.target.value.split('\n') : e.target.value
                          )
                        }
                        variant='outlined'
                        fullWidth
                        size='small'
                        multiline={Array.isArray(itemValue)}
                        rows={Array.isArray(itemValue) ? 4 : 1}
                        placeholder={Array.isArray(itemValue) ? 'Enter each item on a new line' : ''}
                        disabled={itemKey === 'type'}
                      />
                    </Grid>
                  )
                })}
              </Grid>
              {/* Add ad sets if the campaign type is social */}
              {item.type === 'Social' && (
                <Grid container spacing={2} p={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='subtitle1'>Ads</Typography>
                    <IconButton onClick={() => onCreateAdSet(idx)} aria-label='Add Ad Set'>
                      <AddIcon />
                    </IconButton>
                  </Grid>
                  <Grid container spacing={2}>
                    {item.ads?.map((ad, adIdx) => (
                      <Grid item xs={12} key={`ad-${adIdx}`}>
                        <TextField
                          label={`Ad Set ${ad.setName}`}
                          value={ad.name || ''}
                          onChange={e => onChange(`items[${idx}].ads[${adIdx}].name`, e.target.value)}
                          variant='outlined'
                          fullWidth
                          size='small'
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Box>
          </React.Fragment>
        ))}
    </Box>
  )
}

export default EditCampaign
