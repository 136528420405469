import React from 'react'
import { Grid, Tooltip, Typography, useTheme } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import AddTaskIcon from '@mui/icons-material/AddTask'

interface RenderRoleProps {
  adminIds: string[]
  activeUserId: string
  approverId: string
  requestorId: string
}

const RenderRoleIcon: React.FC<RenderRoleProps> = ({ adminIds, activeUserId, approverId, requestorId }) => {
  const theme = useTheme()
  return (
    <>
      {adminIds.includes(activeUserId) && (
        <Grid item xs={1}>
          <Tooltip
            title={
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontStyle: 'italic',
                  borderRadius: '25px',
                  p: 0.5,
                }}
              >
                Admin: {activeUserId}
              </Typography>
            }
          >
            <AdminPanelSettingsIcon />
          </Tooltip>
        </Grid>
      )}
      {requestorId === activeUserId && approverId === activeUserId && !adminIds.includes(activeUserId) && (
        <Grid item xs={1}>
          <Tooltip
            title={
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontStyle: 'italic',
                  borderRadius: '25px',
                  p: 0.5,
                }}
              >
                Requestor && Approver: {activeUserId}
              </Typography>
            }
          >
            <ScheduleSendIcon />
          </Tooltip>
        </Grid>
      )}
      {requestorId === activeUserId && !adminIds.includes(activeUserId) && (
        <Grid item xs={1}>
          <Tooltip
            title={
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontStyle: 'italic',
                  borderRadius: '25px',
                  p: 0.5,
                }}
              >
                Requestor: {activeUserId}
              </Typography>
            }
          >
            <ScheduleSendIcon />
          </Tooltip>
        </Grid>
      )}
      {approverId === activeUserId && !adminIds.includes(activeUserId) && (
        <Grid item xs={1}>
          <Tooltip
            title={
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontStyle: 'italic',
                  borderRadius: '25px',
                  p: 0.5,
                }}
              >
                Approver: {activeUserId}
              </Typography>
            }
          >
            <AddTaskIcon />
          </Tooltip>
        </Grid>
      )}
    </>
  )
}

export default RenderRoleIcon
