import React, { useState } from 'react'
import { Autocomplete, TextField, Chip, Tooltip, useTheme } from '@mui/material'
import ContactsDialog from './ContactsDialog'
import { useCreateContact } from '../../api/aws/useCreateContact'
import AddRenderOptionButton from './AddRenderOptionButton'

const AdditionalContactsAutocomplete = ({ contacts, label, onChange }) => {
  const theme = useTheme()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('') // Temp input for dialog
  const [inputValue, setInputValue] = useState('') // Input state for Autocomplete
  const [selectedContacts, setSelectedContacts] = useState([])
  const { mutate: createContact } = useCreateContact()

  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
    setInputValue('') // Clear input when dialog closes
  }

  const handleCreateContacts = newValue => {
    createContact(newValue, {
      onSuccess: data => {
        const newContact = { ...data, contacts_uuid: data.contacts_uuid }
        const updatedContacts = [...selectedContacts, newContact]
        setSelectedContacts(updatedContacts)
        onChange(updatedContacts)
        handleCloseDialog() // Also clears input here
      },
    })
  }

  const handleSelectExistingContact = (event, newValue, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      setSelectedContacts(newValue)
      onChange(newValue)
    }
  }

  const handleRemoveContact = contactId => {
    const newSelectedContacts = selectedContacts.filter(contact => contact.contacts_uuid !== contactId)
    setSelectedContacts(newSelectedContacts)
    onChange(newSelectedContacts)
  }

  const renderExtraChip = (extraCount, extraContacts) => (
    <Tooltip title={extraContacts.map(contact => contact.name).join(', ')} arrow>
      <Chip label={`+${extraCount}`} size='small' />
    </Tooltip>
  )

  return (
    <>
      <Autocomplete
        multiple
        id='contacts-autocomplete'
        options={contacts}
        getOptionLabel={option => option.name || ''}
        value={selectedContacts ? selectedContacts : []}
        onChange={(event, newValue, reason) => handleSelectExistingContact(event, newValue, reason)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        filterOptions={(options, { inputValue }) => {
          let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
          if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
            filtered.unshift({
              inputValue,
              specialOption: true,
              id: `add-new-${inputValue}`,
            })
          }
          return filtered
        }}
        renderOption={(props, option) =>
          option.inputValue ? (
            <AddRenderOptionButton
              key={option.contacts_uuid}
              {...props} // Spread props correctly here
              option={option}
              handleOpenDialog={handleOpenDialog}
            />
          ) : (
            <li {...props} key={option.contacts_uuid}>
              {option.name}
            </li>
          )
        }
        renderTags={(tagValue = [], getTagProps) => {
          const maxVisibleChips = 1
          const extraCount = tagValue.length - maxVisibleChips
          const visibleTags = tagValue.slice(0, maxVisibleChips)
          const extraTags = tagValue.slice(maxVisibleChips)
          return (
            <>
              {visibleTags.map((option, index) => {
                const { key, ...rest } = getTagProps({ index })

                return (
                  <Chip
                    key={key}
                    label={option.name}
                    {...rest}
                    onDelete={() => handleRemoveContact(option.contacts_uuid)}
                  />
                )
              })}
              {extraCount > 0 && renderExtraChip(extraCount, extraTags)}
            </>
          )
        }}
        renderInput={params => <TextField {...params} label={label} />}
        fullWidth
        freeSolo
      />
      <ContactsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        tempInput={tempInput}
        onSaveContact={handleCreateContacts}
      />
    </>
  )
}

export default AdditionalContactsAutocomplete
