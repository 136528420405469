import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Typography, useTheme, Tooltip, CircularProgress } from '@mui/material'
import { useParams, Link, Outlet, useLocation } from 'react-router-dom'
import Loading from '../Loading/Loading'
import { useDeal } from '../../api/aws/useDeal'
import DealsDetailsHeader from './DealsDetailsHeader'
import GridLoading from '../Loading/GridLoading'
import { DealDetailsProvider, useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import DetailsLoading from '../Loading/DetailsLoading'
import { useQueryClient } from '@tanstack/react-query'
import { useTimeline } from '../../api/aws/useTimeline'

const DealPage = () => {
  const { dealId } = useParams()
  const { deal, isLoading, isError } = useDeal(dealId)
  const { timeline, isLoading: timelineLoading, isError: timelineError } = useTimeline(dealId)
  const theme = useTheme()
  const location = useLocation()
  const [tabValue, setTabValue] = React.useState(0)

  const queryClient = useQueryClient()
  useEffect(() => {
    const handleDealUpdate = event => {
      const data = event.detail
      if (data.tableName === 'deals' && data.itemId === dealId) {
        console.log('DealPage received update event for deal:', dealId)
        queryClient.invalidateQueries(['/aws/deals', dealId])
      }
    }

    // Listen for the custom event 'dealUpdate'
    window.addEventListener('dealUpdate', handleDealUpdate)

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('dealUpdate', handleDealUpdate)
    }
  }, [dealId, queryClient])
  useEffect(() => {
    // Determine the tab value based on the current route
    if (location.pathname.includes('/tasks')) {
      setTabValue(1)
    } else if (location.pathname.includes('/services')) {
      setTabValue(2)
    } else if (location.pathname.includes('/strategy')) {
      setTabValue(3)
    } else if (location.pathname.includes('/paymentschedule')) {
      setTabValue(4)
    } else if (location.pathname.includes('/reviewdeal')) {
      setTabValue(5)
    } else if (location.pathname.includes('/invoicing')) {
      setTabValue(6)
    } else if (location.pathname.includes('/timeline')) {
      setTabValue(7)
    } else {
      setTabValue(0)
    }
  }, [location])

  if (isLoading) {
    return <DetailsLoading />
  }

  if (isError) {
    return <Typography>Error loading deal details</Typography>
  }

  return (
    <>
      <Box sx={{ position: 'sticky', top: 0, zIndex: 1100 }}>
        <Box
          sx={{
            width: '100%',
          }}
        />
        <DealDetailsProvider dealData={deal}>
          <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
            <DealsDetailsHeader deal={deal} />
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Tabs
              value={tabValue}
              aria-label='deal tabs'
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgba(255,255,255,1)',
                mb: 1.5,
              }}
            >
              <Tab label='Details' component={Link} to={`/deals/${dealId}`} />
              <Tab label='Tasks' component={Link} to={`/deals/${dealId}/tasks`} disabled />
              <Tab label='Services' component={Link} to={`/deals/${dealId}/services`} disabled />
              <Tab label='Strategy' component={Link} to={`/deals/${dealId}/strategy`} />
              {/* )} */}
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label='Payment Schedule'
                component={Link}
                to={`/deals/${dealId}/paymentschedule`}
              />
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label='Review Deal'
                component={Link}
                to={`/deals/${dealId}/reviewdeal`}
              />
              <Tab
                disabled={deal && deal.products && Array.isArray(deal.products) && deal.products.length === 0}
                label='Invoicing'
                component={Link}
                to={`/deals/${dealId}/invoicing`}
              />
              <Tab
                label='Timeline'
                component={Link}
                to={`/deals/${dealId}/timeline`}
                disabled={!timeline || timeline?.length === 0}
              />
            </Tabs>
            <Box
              sx={{
                height: 'calc(100vh - 185px)', // Adjust this value based on your header height
                p: `0px !important`,
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </DealDetailsProvider>
      </Box>
    </>
  )
}

export default DealPage
