export const columns = [
  { id: 'rowId', label: 'Row Id', type: 'text', flex: '0 0 50px', style: { background: 'none', border: 'none' } },
  {
    id: 'productName',
    label: 'Product Name',
    type: 'autocomplete',
    flex: '1 1 350px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'description',
    label: '',
    type: 'popover',
    flex: '0 0 150px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'margin',
    label: 'Margin',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'mediaSpend',
    label: 'Media Spend',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
  },
  { id: 'dateRange', label: 'Date Range', type: 'dateRange', flex: '1 1 250px' },
  //   {
  //     id: 'status',
  //     label: 'Status',
  //     type: 'select',
  //     flex: '0 0 150px',
  //     style: { background: 'none', border: 'none' },
  //   },
  {
    id: 'payer',
    label: 'Payer',
    type: 'paidSelect',
    flex: '0 0 75px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'accountUsed',
    label: 'Account Used',
    type: 'paidSelect',
    flex: '0 0 75px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'grossProfit',
    label: 'Gross Profit',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'deleteButton',
    label: 'Delete',
    type: 'component',
    flex: '0 0 50px',
    style: { background: 'none', border: 'none' },
  },
]
export const csaColumns = [
  { id: 'rowId', label: 'Row Id', type: 'text', flex: '0 0 50px', style: { background: 'none', border: 'none' } },
  {
    id: 'productName',
    label: 'Product Name',
    type: 'autocomplete',
    flex: '1 1 350px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'description',
    label: '',
    type: 'popover',
    flex: '1 1 350px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'margin',
    label: 'Margin',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
  },
  { id: 'dateRange', label: 'Date Range', type: 'dateRange', flex: '1 1 250px' },
  {
    id: 'grossProfit',
    label: 'Gross Profit',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'deleteButton',
    label: 'Delete',
    type: 'component',
    flex: '0 0 50px',
    style: { background: 'none', border: 'none' },
  },
]
export const ioColumns = [
  { id: 'rowId', label: 'Row Id', type: 'text', flex: '0 0 50px', style: { background: 'none', border: 'none' } },
  {
    id: 'productName',
    label: 'Product Name',
    type: 'autocomplete',
    flex: '1 1 350px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'description',
    label: '',
    type: 'popover',
    flex: '0 0 150px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'qty',
    label: 'Qty',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'billedPrice',
    label: 'Billed Price',
    type: 'number',
    flex: '0 0 90px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'margin',
    label: 'Margin',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'mediaSpend',
    label: 'Media Spend',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
  },
  { id: 'dateRange', label: 'Date Range', type: 'dateRange', flex: '1 1 250px' },
  //   {
  //     id: 'status',
  //     label: 'Status',
  //     type: 'select',
  //     flex: '0 0 150px',
  //     style: { background: 'none', border: 'none' },
  //   },
  {
    id: 'payer',
    label: 'Payer',
    type: 'paidSelect',
    flex: '0 0 75px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'accountUsed',
    label: 'Account Used',
    type: 'paidSelect',
    flex: '0 0 75px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'grossProfit',
    label: 'Gross Profit',
    type: 'number',
    flex: '0 0 80px',
    style: { background: 'none', border: 'none' },
  },
  {
    id: 'deleteButton',
    label: 'Delete',
    type: 'component',
    flex: '0 0 50px',
    style: { background: 'none', border: 'none' },
  },
]
// export const columns = [
//   { id: 'rowId', label: 'Row Id', type: 'text', flex: '0 0 50px', style: { background: 'none', border: 'none' } },
//   {
//     id: 'productName',
//     label: 'Product Name',
//     type: 'autocomplete',
//     flex: '1 1 450px',
//     style: { background: 'none', border: 'none' },
//   },
//   {
//     id: 'price',
//     label: 'Price',
//     type: 'number',
//     flex: '0 0 90px',
//     style: { background: 'none', border: 'none' },
//   },
//   {
//     id: 'margin',
//     label: 'Margin',
//     type: 'number',
//     flex: '0 0 80px',
//     style: { background: 'none', border: 'none' },
//   },
//   {
//     id: 'mediaSpend',
//     label: 'Media Spend',
//     type: 'number',
//     flex: '0 0 80px',
//     style: { background: 'none', border: 'none' },
//   },
//   { id: 'dateRange', label: 'Date Range', type: 'dateRange', flex: '1 1 250px' },
//   {
//     id: 'status',
//     label: 'Status',
//     type: 'select',
//     flex: '0 0 150px',
//     style: { background: 'none', border: 'none' },
//   },
//   {
//     id: 'grossProfit',
//     label: 'Gross Profit',
//     type: 'number',
//     flex: '0 0 80px',
//     style: { background: 'none', border: 'none' },
//   },
//   {
//     id: 'deleteButton',
//     label: 'Delete',
//     type: 'component',
//     flex: '0 0 50px',
//     style: { background: 'none', border: 'none' },
//   },
// ]
