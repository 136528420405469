import React from 'react'
import {
  List,
  ListItem,
  Tooltip,
  Typography,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PersonIcon from '@mui/icons-material/Person'
import PandaDocImageComponent from '../../AvatarImageComponents/PandaDocImageComponent'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'

const BoardsNavigation = ({ handleNavigation, open, selectedPath }) => {
  const theme = useTheme()
  const bgSelectColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
  const bgHoverColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

  return (
    <List>
      {[
        { label: 'Leads', path: 'leads', icon: <OnlinePredictionIcon sx={{ color: 'rgb(94, 123, 167)' }} /> },
        {
          label: 'Approvals',
          path: 'approvals',
          icon: <PublishedWithChangesIcon sx={{ color: 'rgba(205, 254, 194, 1)' }} />,
        },
        {
          label: 'Organizations',
          path: 'organizations',
          icon: <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />,
        },
        { label: 'Deals', path: 'deals', icon: <AttachMoneyIcon sx={{ color: '#fbc11e' }} /> },
        { label: 'Contacts', path: 'contacts', icon: <PersonIcon sx={{ color: 'rgb(255, 123, 187)' }} /> },
        {
          label: 'PandaDoc',
          path: 'pandadocs',
          icon: <PandaDocImageComponent size={25} sx={{ color: 'rgba(255,255,255,0.3)' }} />,
        },
      ].map((item, index) => (
        <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              backgroundColor: item.path === selectedPath.replace(/^\//, '') ? bgSelectColor : 'transparent',
              '&:hover': {
                backgroundColor: bgHoverColor,
              },
            }}
            onClick={() => handleNavigation(item.path)}
          >
            <Tooltip enterDelay={500} leaveDelay={0} title={item.label} placement='right'>
              <ListItemIcon
                sx={{
                  minWidth: open ? 40 : 0, // Ensure consistent spacing for icons
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {item.icon}
              </ListItemIcon>
            </Tooltip>

            <ListItemText
              primary={item.label}
              sx={{
                opacity: open ? 1 : 0,
                ml: open ? 0 : -1, // Ensure the text moves closer to the icon when closed
                whiteSpace: 'nowrap', // Prevent text from wrapping
                fontSize: '14px', // Adjust as per your preference
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
                  transition: 'opacity 0.3s ease', // Smooth transition for the text visibility
                }}
              >
                {item.label}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default BoardsNavigation
