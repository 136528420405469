import React from 'react'
import { Button, IconButton } from '@mui/material'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
const ThumbsupButton = ({ isApproving, onApprove }) => {
  return (
    <>
      <IconButton size='small' disabled={isApproving} onClick={onApprove} name='isApproved' variant='contained'>
        <ThumbUpOffAltIcon
          sx={{
            color: 'rgb(5, 190, 113)',
          }}
        />
      </IconButton>
    </>
  )
}

export default ThumbsupButton
