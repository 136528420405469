import React from 'react'
import { Button, IconButton, useTheme } from '@mui/material'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
const ThumbsDownButton = ({ id, isRejecting, onReject }) => {
  const theme = useTheme()
  return (
    <>
      <IconButton id={id} size='small' disabled={isRejecting} onClick={onReject} name='isApproved' variant='contained'>
        <ThumbDownOffAltIcon
          sx={{
            color: theme.palette.icon.decline,
          }}
        />
      </IconButton>
    </>
  )
}

export default ThumbsDownButton
