// export const calculateProgress = approvers => {
//   return approvers.map(approver => {
//     if (approver && approver.hasRejected) {
//       return {
//         id: approver.id,
//         progress: approver && approver.hasApproved ? 1 : approver && approver.hasRejected ? 2 : 0,
//         reason: approver && approver.reason ? approver.reason : '',
//       }
//     } else if (approver && approver.hasApproved) {
//       return {
//         id: approver.id,
//         progress: approver && approver.hasApproved ? 1 : approver && approver.hasRejected ? 2 : 0,
//       }
//     } else {
//       return {
//         id: approver.id,
//         progress: approver && approver.hasApproved ? 1 : approver && approver.hasRejected ? 2 : 0,
//       }
//     }
//   })
// }
export const calculateProgress = approvers => {
  if (!approvers || approvers.length === 0) return 0 // Handle case with no approvers

  const approvedCount = approvers.reduce((count, approver) => {
    return count + (approver.hasApproved ? 1 : 0)
  }, 0)

  const totalApprovers = approvers.length
  const progressPercentage = (approvedCount / totalApprovers) * 100

  // Also returning individual progress for each approver if needed
  const individualProgress = approvers.map(approver => ({
    id: approver.id,
    progress: approver.hasApproved ? 1 : approver.hasRejected ? 2 : 0,
    reason: approver.reason || '',
  }))

  return {
    progressPercentage,
    individualProgress,
  }
}
