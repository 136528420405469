import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, Button, Chip, Typography, Box, useTheme, Tooltip } from '@mui/material'

import { useCreateContact } from '../../api/aws/useCreateContact'
import CreateDealOrgDialog from './CreateDealOrgDialog'
import { renderOption, renderTags } from '../CustomCompanyComponents/renderOptions'
import { useCreateOrganization } from '../../api/aws/useCreateOrganization'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { AutocompleteButton } from '../../style/styleElements'
import AddRenderOptionButton from '../Contacts/AddRenderOptionButton'
import RenderOptionsWithVertical from '../RenderChipComponents/RenderOptionsWithVertical'

const CreateDealOrgAutocomplete = ({
  organizations,
  multiple = true,
  label,
  value,
  addOrganization,
  setIsValid,
  error,
  helperText,
  openTooltip,
  setOpenTooltip,
}) => {
  const theme = useTheme()
  const { showSnackbar } = useSnackbarContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [tempInput, setTempInput] = useState('')
  const themeColor = theme.palette.mode === 'dark' ? 'white' : 'black'

  // const [selectedOrganizations, setSelectedOrganizations] = useState(value || [])

  const [selectedOrganizations, setSelectedOrganizations] = useState(Array.isArray(value) ? value : [])
  const { mutate: createOrganization } = useCreateOrganization()

  const handleOpenDialog = inputValue => {
    setTempInput(inputValue)
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setTempInput('')
  }

  const handleAddOrganization = (event, newOrganization) => {
    if (newOrganization) {
      addOrganization(newOrganization)

      // Check if the selected organization has a valid vertical (not 'No Vertical Assigned')
      const hasValidVertical =
        newOrganization.vertical &&
        newOrganization.vertical.length > 0 &&
        !newOrganization.vertical.includes('No Vertical Assigned')

      setIsValid(hasValidVertical)
      setOpenTooltip(!hasValidVertical) // Show tooltip if vertical is invalid
    } else {
      addOrganization(null)
      setIsValid(false) // Disable buttons if no organization is selected
      setOpenTooltip(false) // Hide tooltip when no organization is selected
    }
  }

  const handleRemoveOrganization = orgId => {
    const newSelectedOrganizations = selectedOrganizations.filter(org => org.org_uuid !== orgId)
    setSelectedOrganizations(newSelectedOrganizations)
  }
  const handleSave = newValue => {
    console.log('[OrgAutocomplete handleSave] newValue: ', newValue)
    createOrganization(newValue, {
      onSuccess: data => {
        console.log('data: ', data)
        const newOrg = { ...data, id: data.org_uuid }
        const updatedOrganizations = [...selectedOrganizations, newOrg]
        setSelectedOrganizations(updatedOrganizations)
        addOrganization(newOrg) // Ensure the newly created organization is selected
        handleCloseDialog()
        showSnackbar('Organization added successfully', 'success')
      },
    })
  }

  const filterOptions = (options, { inputValue }) => {
    let filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
    if (inputValue !== '' && !filtered.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
      filtered.unshift({
        inputValue,
        specialOption: true,
        id: `add-new-${inputValue}`,
      })
    }
    return filtered
  }

  return (
    <>
      <Tooltip
        title='No vertical assigned to this organization' // Tooltip text
        open={openTooltip} // Control tooltip visibility based on organization validity
        arrow
        placement='top-end' // Adjust placement as needed
      >
        <Autocomplete
          multiple={multiple}
          id='organizations-autocomplete'
          options={organizations}
          onChange={(event, newValue) => handleAddOrganization(event, newValue)}
          getOptionLabel={option => option.name || ''}
          filterOptions={filterOptions}
          renderOption={(props, option) => {
            return option.specialOption ? (
              <AddRenderOptionButton key={props.id} props={props} option={option} handleOpenDialog={handleOpenDialog} />
            ) : (
              <Box {...props} key={option.org_uuid}>
                <RenderOptionsWithVertical option={option} />
              </Box>
            )
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map(option => (
              <Box key={option.org_uuid}>
                <RenderOptionsWithVertical option={option} />
              </Box>
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              error={error} // Set error state
            />
          )}
          fullWidth
          freeSolo
        />
      </Tooltip>
      <CreateDealOrgDialog open={dialogOpen} onClose={handleCloseDialog} onSave={handleSave} tempInput={tempInput} />
    </>
  )
}

export default CreateDealOrgAutocomplete
