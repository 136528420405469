import React from 'react'
import { Box, Chip, Typography, Button, Divider, useTheme, Tooltip, Avatar, Switch, IconButton } from '@mui/material'
import { detailHeaderStyle, headerStyle, HeaderButton } from './style'
import PersonIcon from '@mui/icons-material/Person'
import { stringAvatar } from './style'

const ContactDetailsHeader = ({ contact }) => {
  const theme = useTheme()
  const textColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black'
  console.log('Contact: ', contact)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 8, m: 1, ml: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2, gap: 1 }}>
        {contact.photoUrl ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Avatar src={contact.photoUrl} sx={{ width: 56, height: 56 }} />
            <Typography sx={{ fontWeight: 'bold' }}>{contact.name}</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
              height: '100%',
              gap: 4,
            }}
          >
            <Avatar
              {...stringAvatar(contact.name)}
              sx={{
                backgroundColor: 'rgb(255, 188, 220)',
                width: 48,
                height: 48,
              }}
              alt={contact.name}
            />
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              {contact.name}
            </Typography>
          </Box>
        )}
      </Box>
      {/* <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
        {contact && <Typography sx={{ fontSize: 36 }}>{contact.name}</Typography>}
      </Box> */}
      <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
      <PersonIcon sx={{ color: 'rgb(255, 123, 187)' }} />
      <Typography sx={detailHeaderStyle(theme)}>{'Contact Details'}</Typography>
      <Divider orientation='vertical' flexItem sx={{ mx: 2 }} />
    </Box>
  )
}

export default ContactDetailsHeader
