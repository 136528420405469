import React from 'react'
import { Grid, Typography, useTheme, Box, InputLabel } from '@mui/material'
import { tableCellStyles, tableHeaderStyles, tableSectionStyles } from './style'
import RichTextDescription from './RichTextDescription' // Import your RichTextDescription component

const GenerateTable = ({ options, section }) => {
  const theme = useTheme()
  const headers = options.length > 0 ? Object.keys(options[0]) : []

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      {section && (
        <Box sx={{ bgcolor: theme.palette.background.default, p: 1 }}>
          <Typography variant='h6' sx={tableSectionStyles(theme)}>
            {section}
          </Typography>
        </Box>
      )}
      <Grid container sx={{ bgcolor: theme.palette.background.paper }}>
        {/* Render Headers */}
        {headers.map((header, headerIndex) => (
          <Grid
            item
            xs={header === 'Service' ? 3 : 9 / (headers.length - 1)} // Adjusting Service column width
            key={headerIndex}
            sx={{
              ...tableHeaderStyles(theme),
              p: 1,
              textAlign: 'center',
            }}
          >
            {header}
          </Grid>
        ))}
        {/* Render Data Rows */}
        {options.map((entry, index) => {
          return (
            <Grid container key={index}>
              {headers.map((header, headerIndex) => {
                return (
                  <Grid
                    item
                    xs={header === 'Service' ? 3 : 9 / (headers.length - 1)} // Adjusting Service column width
                    key={headerIndex}
                    sx={{
                      ...tableCellStyles(theme, entry[header]?.color, entry[header]?.width),
                      p: 1,
                      textAlign: header === 'Service' ? 'left' : 'center',
                      alignContent: 'center',
                      // whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {entry[header]?.type === 'number' ? `$${entry[header].value}` : entry[header]?.value}
                    {header === 'Service' && entry[header]?.description ? (
                      <RichTextDescription key={`${headerIndex}-description`} description={entry[header].description} />
                    ) : null}
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default GenerateTable
