import React from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import StrategyNotesHeader from './StrategyNotesHeader'
import StrategyNotes from './StrategyNotes'
const StrategyNotesSection = ({
  newNoteContent,
  setNewNoteContent,
  handleAddNote,
  handleClear,
  isCreatingNote,
  setIsCreatingNote,
  isEditMode,
  notes,
  setNotes,
}) => {
  const theme = useTheme()
  return (
    <>
      <Grid container>
        <Grid item xs={12} pl={2.5}>
          <StrategyNotes
            isEditMode={isEditMode}
            newNoteContent={newNoteContent}
            setNewNoteContent={setNewNoteContent}
            notes={notes}
            setNotes={setNotes}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default StrategyNotesSection
