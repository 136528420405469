import React, { useState, useEffect } from 'react'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import { renderContactsColumns } from '../DataGrid/createColumns/renderContactsColumns'
import { StyledDataGrid } from '../DataGrid/style'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { filterPanelProps, gridContainerStyles } from './style'
import { Box, useTheme } from '@mui/material'
import { ContactsGridFooter } from './ContactsGridFooter'
import CustomGridToolbar from '../DataGrid/CustomGridToolbar'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { useGetQuery } from '../../api/aws/useGetQuery'
import DetailsLoading from '../Loading/DetailsLoading'
import { useQueryClient } from '@tanstack/react-query'

export const initialContactsSortModel = [{ field: 'name', sort: 'asc' }]
export const initialContactsVisibilityState = {
  source: false,
  timeCreated: false,
}

const ContactsPage = () => {
  const { data: contacts, isLoading: contactsLoading, isError: contactsError } = useGetQuery(
    '/aws/contacts',
    'contacts'
  )
  const [selectedContacts, setSelectedContacts] = useState([])
  // const [selectedContacts, setSelectedContacts] = useState(null)
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations()
  const theme = useTheme()
  const filterMenuBorder =
    theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
  const { data: users } = useGetQuery('/aws/users', 'users')
  const queryClient = useQueryClient()
  const [density, setDensity] = useState('standard')
  const columns = renderContactsColumns(organizations, contacts, users, density)
  const apiRef = useGridApiRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])
  const [rowCount, setRowCount] = useState(contacts ? contacts.length : 0)
  const [isDeleting, setIsDeleting] = useState(false)
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem('contactsFilterModel')
    return savedFilters ? JSON.parse(savedFilters) : { items: [] }
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('contactsColumnVisibilityModel')
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : {
          source: false,
          timeCreated: false,
        }
  })
  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem('contactsSortModel')
    return savedSortModel ? JSON.parse(savedSortModel) : [{ field: 'name', sort: 'asc' }]
  })
  const { mutate: deleteItem } = useDeleteMutation()
  const { showSnackbar } = useSnackbarContext()
  ///////////////////////////////////////////////////////////////////////////\

  useEffect(() => {
    if (filterModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem('contactsFilterModel', JSON.stringify(filterModel))
    }
  }, [filterModel])

  useEffect(() => {
    if (selectionModel.length === 1) {
      const newContact = contacts.find(org => org.id === selectionModel[0])
      setSelectedContacts(newContact)
      // setMassUpdateData({ vertical: [], type: '' })
    } else {
      setSelectedContacts(null)
    }
  }, [selectionModel, contacts])
  const onRowSelected = contact => {
    setSelectedContacts(contact)
  }
  const handleDeleteClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    if (selectedContacts) {
      const params = {
        endpoint: `/aws/delete/contacts/${selectedContacts.contacts_uuid}`,
        table: 'contacts',
      }
      deleteItem(params, {
        onSuccess: message => {
          handleClosePopover()
          showSnackbar(message, 'success')
          setIsDeleting(false)
          console.log('invalidating queries: ', `['contacts', ${selectedContacts.contacts_uuid}]`)
          queryClient.invalidateQueries(['contacts', selectedContacts.contacts_uuid])
        },
        onError: error => {
          showSnackbar(error.message, 'error')
          setIsDeleting(false)
        },
      })
    }
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
    apiRef.current.setRowSelectionModel([]) // Unselect all rows
    setSelectionModel([])
    setSelectedContacts(null)
  }

  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }

  const handleDensityChange = newDensity => {
    setDensity(newDensity)
  }

  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel([...initialContactsSortModel]) // Reset the sort model
    localStorage.setItem('contactsSortModel', JSON.stringify(initialContactsSortModel)) // Also update localStorage
  }
  const handleClearVisibility = e => {
    e.preventDefault()
    const initialVisibilityState = {
      source: false,
      timeCreated: false,
    }
    localStorage.setItem('contactsColumnVisibilityModel', JSON.stringify(initialVisibilityState))
    setColumnVisibilityModel(initialVisibilityState)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const isLoading = isOrganizationsLoading || contactsLoading

  return (
    <>
      {isLoading && <DetailsLoading />}
      <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 20px)' }}>
        <DataGridPro
          initialState={{
            // pagination: {
            //   paginationModel: {
            //     pageSize: 10, // Set the default page size here
            //   },
            // },
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [],
              },
            },
            columns: {
              columnVisibilityModel: {
                source: false,
                timeCreated: false,
              },
            },
          }}
          rows={contacts || []}
          columns={columns}
          rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52} // Adjust row height based on density
          // pageSize={10}
          // pageSizeOptions={[5, 10, 25]}
          // pagination
          // paginationMode='client'
          getRowId={row => row.contacts_uuid}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => {
            setColumnVisibilityModel(newModel)
            localStorage.setItem('contactsColumnVisibilityModel', JSON.stringify(newModel))
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel)
            localStorage.setItem('contactsSortModel', JSON.stringify(newModel))
          }}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={model => setFilterModel(model)}
          apiRef={apiRef}
          slots={{ toolbar: CustomGridToolbar, footer: ContactsGridFooter }}
          slotProps={{
            toolbar: {
              filterModel: filterModel,
              setFilterModel: setFilterModel,
              visibilityModel: columnVisibilityModel,
              initialSortModel: initialContactsSortModel,
              sortModel: sortModel,
              onClearSorting: handleClearSorting,
              onClearVisibility: handleClearVisibility,
              context: 'contacts',
            },
            footer: {
              selectionModel: selectionModel,
              rowCount,
              // massUpdateData: massUpdateData,
              contacts: contacts,
              anchorEl: anchorEl,
              handleDeleteClick: handleDeleteClick,
              handleConfirmDelete: handleConfirmDelete,
              handleClosePopover: handleClosePopover,
              selectedData: selectedContacts,
              setSelectedData: setSelectedContacts,
              setSelectionModel: setSelectionModel,
              apiRef: apiRef,
            },
            panel: {
              sx: {
                border: filterMenuBorder,
                borderRadius: '4px',
              },
            },
            filterPanel: {
              sx: {
                ...filterPanelProps,
              },
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          onDensityChange={handleDensityChange}
          loading={contactsLoading}
        />
      </Box>
    </>
  )
}

export default ContactsPage
