import React, { useState } from 'react'
import { Box, Typography, Tooltip, IconButton, useTheme, ToggleButtonGroup, ToggleButton } from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps'
import { useNavigate } from 'react-router-dom'
import { headerStyle } from './style'

const DealsKanbanHeader = ({ extraStages, setExtraStages }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const handleToggleChange = (event, value) => {
    if (value !== null) {
      setExtraStages(value === 'all')
    }
  }

  const handleNavigateToDeals = () => {
    navigate('/deals')
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 50 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Tooltip title='View Grid'>
          <IconButton onClick={handleNavigateToDeals} sx={{ mr: 2 }}>
            {/* Add space between icon and text */}
            <AppsIcon sx={{ height: 30, width: 30, color: 'rgb(251, 193, 30)' }} />
          </IconButton>
        </Tooltip>
        <Typography sx={{ ...headerStyle(theme), mr: 4 }}>{'Deals Kanban'}</Typography>
        {/* Add space between the text and the ToggleButtonGroup */}
      </Box>
      <Box sx={{ ml: 4 }}>
        {' '}
        {/* Add margin-left to create space before ToggleButtonGroup */}
        <ToggleButtonGroup value={extraStages ? 'all' : 'default'} exclusive onChange={handleToggleChange}>
          <ToggleButton value='default'>Default Stages</ToggleButton>
          <ToggleButton value='all'>All Stages</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}

export default DealsKanbanHeader
