import { Button, Table, TableCell, TableRow } from '@mui/material'
import { setStageStyle } from '../DealsKanban/DealsKanban'
import { styled } from '@mui/material/styles'
import { renderTypeColor } from '../DataGrid/createColumns/renderDealsColumns'

export const dialogStyles = theme => ({
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  color: '#fff',
  minHeight: '80vh',
  maxHeight: '95vh',
  // padding: '60px',
  paddingTop: '60px',
  paddingLeft: '60px',
  paddingRight: '60px',
  paddingBottom: '30px',
  '& .MuiDialogContent-root': {
    width: '100%',
    padding: '20px',
  },
  '& .MuiTextField-root': {
    margin: '10px 0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // This targets the border of the TextField
        // border: 'none', // Removes the border
      },
      '&:hover fieldset': {
        // border: 'none', // Ensures border is removed on hover
      },
      '&.Mui-focused fieldset': {
        border: 'none', // Removes the border when the TextField is focused
      },
      '&.Mui-focused fieldset': {
        border: '2px solid rgb(159, 176, 219)', // Changes border when focused
        // backgroundColor: '#e3f2fd', // Changes background when focused
      },
    },
  },
  '& .MuiInputBase-root': {
    height: '45px',
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42)' : 'rgba(255,255,255,1)',
  },
  '& input[type="number"]': {
    // Hides the up and down arrows on webkit browsers like Chrome, Safari, and Edge
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    // Hides the up and down arrows on Firefox
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  },
})
export const dealDetailStyles = theme => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgb(232, 246, 252)',
  '& .MuiTextField-root': {
    backgroundColor: theme.palette.mode == 'dark' ? 'rgb(30, 39, 53)' : 'rgba(255,255,255,1)',
    margin: '10px 0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Removes the border
      },
      '&:hover fieldset': {
        border: '1px solid #ccc', // Optional: changes border on hover
      },
      '&.Mui-focused fieldset': {
        border: '2px solid rgb(159, 176, 219)', // Changes border when focused
        // backgroundColor: '#e3f2fd', // Changes background when focused
      },
    },
  },
  '& .MuiInputBase-root': {
    height: '45px',
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgba(255,255,255,1)',
  },
  '& input[type="number"]': {
    // Hides the up and down arrows on webkit browsers like Chrome, Safari, and Edge
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    // Hides the up and down arrows on Firefox
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  },
})

export const dialogContentStyles = {
  width: 'auto',
  maxWidth: '700px',
  margin: '0 auto',
}
export const disabledTextStyles = theme => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(229, 229, 229)',
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgb(67, 70, 75, 0.4)' : 'rgba(255,255,255,1)',
  },
})
export const contractTextStyles = theme => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'none' : 'rgb(229, 229, 229)',
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'rgba(255,255,255,1)',
    height: '40px',
    // color: setStageStyle(value),
  },
})
export const boxColumnStyles = theme => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : '',
})
export const chipTextStyles = theme => ({
  // backgroundColor: theme.palette.mode === 'dark' ? 'none' : 'rgb(229, 229, 229)',
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'rgba(255,255,255,1)',
    height: '40px',
    padding: '20px',
    // color: setStageStyle(value),
  },
})

export const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgb(42, 128, 200)',
}))
export const CreateButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgb(29, 106, 82)',
}))
export const authorizedChipStyle = {
  margin: '4px 0',
  fontWeight: 'bolder',
  color: 'rgb(118, 87, 184)',
  backgroundColor: '#ebdbfd',
  '& .MuiChip-deleteIcon': {
    color: 'rgba(0, 0, 0, 0.7)', // Darken the icon color
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.9)', // Darken more on hover
      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Add background color on hover
      borderRadius: '50%', // Make it round
    },
  },
}
export const contactChipStyle = {
  fontWeight: 'bolder',
  color: 'rgb(204, 91, 14)',
  backgroundColor: 'rgb(250, 238, 230)',
  '& .MuiChip-deleteIcon': {
    color: 'rgba(0, 0, 0, 0.7)', // Darken the icon color
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.9)', // Darken more on hover
      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Add background color on hover
      borderRadius: '50%', // Make it round
    },
  },
  '& .MuiInputBase-root': {
    padding: '8px 14px', // Add padding to the input container
  },
  '& .MuiChip-root': {
    margin: '4px 0', // Add vertical margin to the Chip
  },
}
export const gridItemStyles = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}

export const fieldStyles = fieldBgColor => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: fieldBgColor,
  '& .MuiOutlinedInput-root': {
    backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiInputBase-root': {
    backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiInputBase-input': {
    // backgroundColor: `${fieldBgColor} !important`, // Ensures this color takes priority
  },
  '& .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Makes the outline invisible
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Keeps the outline invisible on focus
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    // borderColor: 'transparent', // Keeps the outline invisible on hover
  },
})
export const readOnlyFieldStyles = fieldBgColor => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& .MuiInputBase-root': {
    pointerEvents: 'none', // Disables interaction
    border: 'none', // Removes border
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Makes the outline invisible
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on focus
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on hover
  },
  backgroundColor: 'transparent', // Ensures no background change on focus
})
export const stageFieldStyles = (stage, fieldBgColor) => ({
  '& .MuiInputBase-input': {
    color: setStageStyle(stage),
    fontWeight: 'bold',
  },
  '& .MuiInputBase-root': {
    pointerEvents: 'none', // Disables interaction
    border: 'none', // Removes border
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Makes the outline invisible
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on focus
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on hover
  },
  backgroundColor: 'transparent', // Ensures no background change on focus
})

export const typeFieldStyles = (type, fieldBgColor) => ({
  '& .MuiInputBase-input': {
    color: renderTypeColor(type),
    fontWeight: 'bold',
  },
  '& .MuiInputBase-root': {
    pointerEvents: 'none', // Disables interaction
    border: 'none', // Removes border
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Makes the outline invisible
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on focus
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on hover
  },
  backgroundColor: 'transparent', // Ensures no background change on focus
})
export const typeSelectStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}
export const EmptyRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.3)' : 'rgba(255,255,255,1)',
  border: '1px dotted', // Use a dotted border
  minHeight: '81px',
  width: '100%',
  height: '100%', // Full height
  display: 'flex', // Enable flexbox
  justifyContent: 'center', // Center horizontally
  alignItems: 'center', // Center vertically
}))

export const EmptyTable = styled(Table)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.1)' : 'rgba(255,255,255,1)',
  border: '1px',
  borderStyle: 'dotted',
  minHeight: '81px',
  height: '81px',
}))
