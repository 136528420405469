import React, { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, Grid, useTheme } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'
import KanbanColumn from './KanBanColumn'
import { aggregateStageTotals } from './aggregateStageTotals'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDeals } from '../../api/aws/useDeals'
import DealsKanbanHeader from './DealsKanbanHeader'

export const setStageStyle = stage => {
  if (stage === 'Lead') {
    return '#e6e6e6'
  } else if (stage === 'Discovery') {
    return '#3f8bff'
  } else if (stage === 'Proposal') {
    return '#b25eb2'
  } else if (stage === 'Awaiting Approval') {
    return 'rgba(241, 130, 141, 1)'
  } else if (stage === 'Pending Approval') {
    return 'rgba(241, 130, 141, 1)'
  } else if (stage === 'Contract Sent') {
    return 'rgb(217, 169, 102)'
  } else if (stage === 'Contract Created') {
    return 'rgba(11, 127, 171, 1)'
  } else if (stage === 'Deal Won') {
    return 'rgb(80, 200, 120)'
  } else if (stage === 'Deal Lost') {
    return '#ffb5ac'
    return '#ffcfc9'
  } else if (stage === 'Cold') {
    return '#a8caff'
  } else if (stage === 'Purgatory') {
    return '#ffdc85'
  }
}

const updateDealStages = deals => {
  return deals.map(deal => {
    if (deal.name === `Colin & Ryan's Org #1000`) {
      console.log(deal)
    }
    if (deal.stage === 'Qualified Lead') {
      deal.stage = 'Proposal'
    } else if (deal.stage === 'New Lead') {
      deal.stage = 'Lead'
    } else if (deal.stage === 'Proposed - Cold') {
      deal.stage = 'Cold'
    } else if (deal.stage === 'Agreement Sent') {
      deal.stage = 'Contract Sent'
    }
    return deal
  })
}

// const loadDealsIntoKanban = (deals, extraStages) => {
//   const defaultStages = {
//     Discovery: { items: [], label: 'Discovery' },
//     'Awaiting Approval': { items: [], label: 'Awaiting Approval' },
//     Proposal: { items: [], label: 'Proposal' },
//     'Contract Created': { items: [], label: 'Contract Created' },
//     'Contract Sent': { items: [], label: 'Contract Sent' },
//     'Deal Won': { items: [], label: 'Deal Won' },
//   }

//   const additionalStages = {
//     Cold: { items: [], label: 'Cold' },
//     Purgatory: { items: [], label: 'Purgatory' },
//   }

//   // Combine defaultStages and additionalStages if extraStages is true
//   const stages = extraStages ? { ...defaultStages, ...additionalStages } : defaultStages
//   // Sort deals by creation timestamp
//   // const sortedDeals = deals.sort((a, b) => new Date(b.creation_timestamp) - new Date(a.creation_timestamp))
//   // Sort deals from A-Z
//   const sortedDeals = deals.sort((a, b) => {
//     if (a.name && b.name) {
//       const nameA = a.name.toLowerCase() // Assuming the name attribute is used for sorting
//       const nameB = b.name.toLowerCase()
//       if (nameA < nameB) return -1
//       if (nameA > nameB) return 1
//       return 0
//     } else {
//       return null
//     }
//   })
//   // Group deals by their stages
//   sortedDeals.forEach(deal => {
//     if (stages[deal.stage]) {
//       stages[deal.stage].items.push({
//         id: deal.id || deal.deal_uuid,
//         primary: deal.name || deal.Deal_Name || deal.organizations.name,
//         secondary:
//           deal.secondary ||
//           `Stage: ${deal.stage} - ${deal.Media_Spend_Totals || 'No spend details'} Created: ${new Date(
//             deal.creation_timestamp
//           ).toLocaleDateString()}`,
//         details: `Contact Email: ${deal.contact ? deal.contact.email : 'No contact email'}, Website: ${
//           deal.contact ? deal.contact.websiteUrl : 'No website URL'
//         }, Total Value: ${deal.contact ? deal.contact.totalContractAmount : 'N/A'}`,
//         ...deal, // Spread the rest of the deal properties if needed elsewhere
//       })
//     }
//   })

//   return stages
// }
const loadDealsIntoKanban = (deals, extraStages) => {
  const defaultStages = {
    Discovery: { items: [], label: 'Discovery' },
    'Pending Approval': { items: [], label: 'Pending Approval' },
    Proposal: { items: [], label: 'Proposal' },
    'Contract Created': { items: [], label: 'Contract Created' },
    'Contract Sent': { items: [], label: 'Contract Sent' },
    'Deal Won': { items: [], label: 'Deal Won' },
  }

  const additionalStages = {
    Cold: { items: [], label: 'Cold' },
    Purgatory: { items: [], label: 'Purgatory' },
  }

  // Combine defaultStages and additionalStages if extraStages is true
  const stages = extraStages ? { ...defaultStages, ...additionalStages } : defaultStages

  // Sort deals from A-Z
  const sortedDeals = deals.sort((a, b) => {
    if (a.name && b.name) {
      const nameA = a.name.toLowerCase() // Assuming the name attribute is used for sorting
      const nameB = b.name.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    } else {
      return 0
    }
  })

  // Group deals by their stages
  sortedDeals.forEach(deal => {
    if (stages[deal.stage]) {
      stages[deal.stage].items.push({
        id: deal.id || deal.deal_uuid,
        primary: deal.name || deal.Deal_Name || deal.organizations.name,
        secondary:
          deal.secondary ||
          `Stage: ${deal.stage} - ${deal.Media_Spend_Totals || 'No spend details'} Created: ${new Date(
            deal.creation_timestamp
          ).toLocaleDateString()}`,
        details: `Contact Email: ${deal.contact ? deal.contact.email : 'No contact email'}, Website: ${
          deal.contact ? deal.contact.websiteUrl : 'No website URL'
        }, Total Value: ${deal.contact ? deal.contact.totalContractAmount : 'N/A'}`,
        ...deal, // Spread the rest of the deal properties if needed elsewhere
      })
    }
  })

  return stages
}

const Root = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  height: 'calc(100vh - 62px)', // Adjust based on your header and footer height
  width: 'calc(100vw - 60px)',
  padding: '16px',
  overflowY: 'hidden', // Prevent scrolling of the entire page
})

const DealsKanban = () => {
  const theme = useTheme()
  const { deals, isDealsLoading, isDealsError } = useDeals()

  const [stageTotals, setStageTotals] = useState({})
  const [extraStages, setExtraStages] = useState(false)

  const [kanbanData, setKanbanData] = useState({})
  const [localStageState, setLocalStageState] = useState({})
  const refinedDeals = updateDealStages(deals)

  useEffect(() => {
    const stages = loadDealsIntoKanban(refinedDeals, extraStages) // Pass the extraStages state
    const totals = aggregateStageTotals(stages)
    setStageTotals(totals) // Update context
    setLocalStageState(totals) // Update local state
    setKanbanData(stages)
  }, [deals, setStageTotals, extraStages])

  const { mutate: updateItem } = useUpdateItem()
  const { showSnackbar } = useSnackbarContext()

  const handleDroppedUpdate = (fieldName, itemId, newValue) => {
    updateItem(
      {
        primaryKey: 'deal_uuid',
        tableName: 'deals',
        itemId,
        fieldName,
        newValue,
      },
      {
        onSuccess: () => {
          showSnackbar(`Deal ${fieldName} updated successfully`, 'success')
          console.log('Update successful')
        },
        onError: err => {
          console.error('Update failed', err)
        },
      }
    )
  }

  const onDragEnd = result => {
    const { source, destination } = result
    if (!destination) return

    if (source !== destination) {
      handleDroppedUpdate('stage', result.draggableId, destination.droppableId)
    }

    const sourceClone = Array.from(kanbanData[source.droppableId].items)
    const destClone = destination ? Array.from(kanbanData[destination.droppableId].items) : null

    if (source.droppableId !== destination.droppableId && destClone) {
      const [movedItem] = sourceClone.splice(source.index, 1)
      destClone.splice(destination.index, 0, movedItem)

      const newKanbanData = {
        ...kanbanData,
        [source.droppableId]: {
          ...kanbanData[source.droppableId],
          items: sourceClone,
        },
        [destination.droppableId]: {
          ...kanbanData[destination.droppableId],
          items: destClone,
        },
      }

      setKanbanData(newKanbanData)
      localStorage.setItem('kanbanData', JSON.stringify(newKanbanData))
    } else {
      sourceClone.splice(destination.index, 0, sourceClone.splice(source.index, 1)[0])
      const newKanbanData = {
        ...kanbanData,
        [source.droppableId]: {
          ...kanbanData[source.droppableId],
          items: sourceClone,
        },
      }
      setKanbanData(newKanbanData)
      localStorage.setItem('kanbanData', JSON.stringify(newKanbanData))
    }
  }

  return (
    // <Grid container sx={{ width: !extraStages ? '100%' : '95vw' }}>
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 5,
          pr: 5,
          mt: 1,
          mb: 0,
        }}
      >
        <DealsKanbanHeader extraStages={extraStages} setExtraStages={setExtraStages} />
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Root
          sx={{
            backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
          }}
        >
          {Object.keys(kanbanData).map(key => (
            <KanbanColumn
              key={key}
              columnId={key}
              items={kanbanData[key].items}
              label={kanbanData[key].label}
              total={stageTotals[key]?.total}
              grossProfit={stageTotals[key]?.grossProfit}
            />
          ))}
        </Root>
      </DragDropContext>
    </>
    // </Grid>
  )
}

export default DealsKanban
/**
 ContractDialog.js:168 Warning: Cannot update a component (`ContractDialog`) while rendering a different component (`DealsProvider`). To locate the bad setState() call inside `DealsProvider`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
    at DealsProvider (https://localhost:3000/main.d7c8af165ee07f4a2c54.hot-update.js:111:3)
    at ContactsProvider (https://localhost:3000/static/js/bundle.js:74921:3)
    at OrganizationsProvider (https://localhost:3000/static/js/bundle.js:77479:3)
    at GlobalProvider (https://localhost:3000/static/js/bundle.js:76506:3)
    at SlackProvider (https://localhost:3000/static/js/bundle.js:77904:3)
    at GoogleProvider (https://localhost:3000/static/js/bundle.js:76636:3)
    at UsersProvider (https://localhost:3000/static/js/bundle.js:78699:3)
    at ValidationProvider (https://localhost:3000/static/js/bundle.js:78847:3)
    at ProtectedApp
    at ProtectedRoute (https://localhost:3000/static/js/bundle.js:9418:3)
    at RenderedRoute (https://localhost:3000/static/js/bundle.js:298964:5)
    at Routes (https://localhost:3000/static/js/bundle.js:299666:5)
    at Router (https://localhost:3000/static/js/bundle.js:299600:15)
    at BrowserRouter (https://localhost:3000/static/js/bundle.js:297541:5)
    at AuthProvider (https://localhost:3000/static/js/bundle.js:9290:3)
    at QueryClientProvider (https://localhost:3000/static/js/bundle.js:330335:3)
    at Main
    at DefaultPropsProvider (https://localhost:3000/static/js/bundle.js:128674:3)
    at RtlProvider (https://localhost:3000/static/js/bundle.js:128819:7)
    at ThemeProvider (https://localhost:3000/static/js/bundle.js:127713:5)
    at ThemeProvider (https://localhost:3000/static/js/bundle.js:129110:5)
    at ThemeProvider (https://localhost:3000/static/js/bundle.js:124974:14)
    at ThemeProviderWrapper (https://localhost:3000/static/js/bundle.js:81299:3)
    at App
    at ThemeProviderContext (https://localhost:3000/static/js/bundle.js:81208:3)
 */
