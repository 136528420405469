import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import CustomDealsGridToolbar from './CustomDealsGridToolbar'
import { useTheme } from '@mui/material'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import { useUpdateOrganization } from '../../api/aws/useUpdateOrganization'
import { renderDealsColumns } from '../DataGrid/createColumns/renderDealsColumns'
import EditContractDialog from '../EditContractDialog/EditContractDialog'
import { filterPanelProps, gridContainerStyles } from './style'
import { DealsGridFooter } from './DealsGridFooter'
import { useOrganizations } from '../../api/aws/useOrganizations'
import { useUsers } from '../../api/aws/useUsers'
import { useDeals } from '../../api/aws/useDeals'
import DetailsLoading from '../Loading/DetailsLoading'
import { DealDetailsProvider } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
export const initialSortModel = [
  { field: 'lastModified', sort: 'desc' },
  { field: 'creation_timestamp', sort: 'desc' },
  { field: 'name', sort: 'asc' },
  { field: 'organizations', sort: 'asc' },
  { field: 'status', sort: 'asc' },
]
const DealsPage = () => {
  const { deals: rawDeals, isDealsLoading, isDealsError } = useDeals()
  const [selectedDeal, setSelectedDeal] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])
  const [density, setDensity] = useState('standard')
  const [selectedOrganization, setSelectedOrganization] = useState()
  const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations()
  const { users, isUsersLoading, isUsersError } = useUsers()
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const filterMenuBorder =
    theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
  const [isDeleting, setIsDeleting] = useState(false)
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem('dealsFilterModel')
    return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedVisibility = localStorage.getItem('dealColumnVisibilityModel')
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : {
          implementationFee: false,
          total: false,
        }
  })
  const [sortModel, setSortModel] = useState(() => {
    const savedSortModel = localStorage.getItem('dealSortModel')
    return savedSortModel
      ? JSON.parse(savedSortModel)
      : [
          { field: 'lastModified', sort: 'desc' },
          { field: 'creation_timestamp', sort: 'desc' },
          { field: 'name', sort: 'asc' },
          { field: 'organizations', sort: 'asc' },
          { field: 'status', sort: 'asc' },
        ]
  })
  const [isContractDialogOpen, setIsContractDialogOpen] = useState(false)
  const { mutate: deleteItem, isLoading } = useDeleteMutation()
  const { mutate: updateOrganization } = useUpdateOrganization()
  const [massUpdateData, setMassUpdateData] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [deals, setDeals] = useState([])
  // const columns = renderDealsColumns(organizations, users, deals, density)
  const apiRef = useGridApiRef()
  const [visibleRowCount, setVisibleRowCount] = useState(deals ? deals.length : 0)
  const [rowCount, setRowCount] = useState(deals ? deals.length : 0)

  useEffect(() => {
    if (filterModel) {
      // Save the filter model to localStorage when it changes
      localStorage.setItem('dealsFilterModel', JSON.stringify(filterModel))
    }
  }, [filterModel])
  const handleDeleteClick = event => {
    setAnchorEl(event.currentTarget)
  }

  // Pre-process deals to add contractedById
  useEffect(() => {
    if (rawDeals && organizations) {
      const processedDeals = rawDeals.map(deal => {
        const organizationId = Array.isArray(deal.organizations) ? deal.organizations[0] : null
        const organization = organizations.find(org => org.id === organizationId)
        const contractedById =
          organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : null
        return {
          ...deal,
          contractedBy: [contractedById], // Add the contractedById to the deal object
        }
      })
      setDeals(processedDeals) // Update state with processed deals
    }
  }, [rawDeals, organizations])

  const columns = renderDealsColumns(organizations, users, deals, density)
  useEffect(() => {
    if (selectedDeal) {
      const selectedOrgId =
        selectedDeal.organizations && Array.isArray(selectedDeal.organizations) ? selectedDeal.organizations[0] : []
      setSelectedOrganization(organizations.find(org => org.id === selectedOrgId))
    }
  }, [selectedDeal, setSelectedOrganization])

  // Cleanup function for when deals component unmounts
  useEffect(() => {
    return () => {
      setSelectionModel([])
      setSelectedDeal(null)
    }
  }, [])

  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    if (selectedDeal) {
      const params = {
        endpoint: `/aws/delete/deals/${selectedDeal.id}`,
        table: 'deals',
      }
      deleteItem(params, {
        onSuccess: message => {
          setSelectedDeal(null)
          showSnackbar(message, 'success')
          setIsDeleting(false)
        },
        onError: error => {
          showSnackbar(error.message, 'error')
          setIsDeleting(false)
        },
      })
    }
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedDeal(null)
    }
  }

  const handleCloseSnackbar = () => {
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedDeal(null)
      handleClosePopover()
    }
  }

  const handleCloneDeal = () => {
    console.log('This icon will be used to clone the selected deal.')
  }

  const handleRowSelected = deal => {
    setSelectedDeal(deal)
  }

  const handleEditClick = () => {
    if (selectedDeal) {
      setIsContractDialogOpen(true)
    }
  }

  const handleCloseContractDialog = () => {
    setIsContractDialogOpen(false)
    setSelectedDeal(null)
    handleClosePopover()
  }

  const handleContractDialogAction = () => {
    setIsContractDialogOpen(false)
    handleCloseSnackbar()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleSelectionModelChange = newSelectionModel => {
    if (newSelectionModel.length !== selectionModel.length) {
      setSelectionModel(newSelectionModel)
      if (newSelectionModel.length === 0 && handleRowSelected) {
        handleRowSelected(null)
      } else {
        const selectedRowData = deals.find(row => row.id === newSelectionModel[0])
        if (handleRowSelected) {
          handleRowSelected(selectedRowData)
        }
      }
    }
  }

  const handleMassUpdateChange = e => {
    const { name, value } = e.target
    setMassUpdateData(prev => ({ ...prev, [name]: value }))
  }

  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }

  useEffect(() => {
    const handleFilterModelChange = () => {
      const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
      const filteredRowsCount = Object.values(filteredRowsLookup).filter(isVisible => isVisible).length
      setVisibleRowCount(filteredRowsCount)
    }

    const api = apiRef.current
    const unsubscribe = api.subscribeEvent('filterModelChange', handleFilterModelChange)

    // Initialize the visible row count when the component mounts
    handleFilterModelChange()

    return () => {
      unsubscribe()
    }
  }, [apiRef])

  const totalRowCount = deals ? deals.length : 0
  useEffect(() => {
    if (massUpdateData) {
      console.log('Mass Update Data: ', massUpdateData)
    }
  }, [massUpdateData])
  const handleDensityChange = newDensity => {
    setDensity(newDensity)
  }

  // const handleClearSorting = e => {
  //   e.preventDefault()
  //   setSortModel([
  //     { field: 'lastModified', sort: 'desc' },
  //     { field: 'creation_timestamp', sort: 'desc' },
  //     { field: 'name', sort: 'asc' },
  //     { field: 'organizations', sort: 'asc' },
  //     { field: 'status', sort: 'asc' },
  //   ])
  // }
  const handleClearSorting = e => {
    e.preventDefault()
    setSortModel([...initialSortModel]) // Reset the sort model
    localStorage.setItem('dealSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
  }
  const handleClearVisibility = e => {
    e.preventDefault()
    const initialVisibilityState = {
      implementationFee: false,
      total: false,
    }
    setColumnVisibilityModel(initialVisibilityState)
    localStorage.setItem('dealColumnVisibilityModel', JSON.stringify(initialVisibilityState))
  }

  return (
    <>
      <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 20px)' }}>
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [
                { field: 'lastModified', sort: 'desc' },
                { field: 'creation_timestamp', sort: 'desc' },
                { field: 'name', sort: 'asc' },
                { field: 'organizations', sort: 'asc' },
                { field: 'status', sort: 'asc' },
              ],
            },
            filter: {
              filterModel: filterModel,
            },
            columns: {
              columnVisibilityModel: {
                implementationFee: false,
                total: false,
              },
            },
          }}
          rows={deals}
          columns={columns}
          rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52}
          getRowId={row => row.deal_uuid || row.id || row.deal.id}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => {
            setColumnVisibilityModel(newModel)
            localStorage.setItem('dealColumnVisibilityModel', JSON.stringify(newModel))
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel)
            localStorage.setItem('dealSortModel', JSON.stringify(newModel))
          }}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={model => setFilterModel(model)}
          apiRef={apiRef}
          slots={{
            toolbar: CustomDealsGridToolbar,
            footer: DealsGridFooter,
          }}
          slotProps={{
            toolbar: {
              filterModel: filterModel,
              visibilityModel: columnVisibilityModel,
              sortModel: sortModel,
              setFilterModel: setFilterModel,
              onClearSorting: handleClearSorting,
              onClearVisibility: handleClearVisibility,
              context: 'deals',
            },
            footer: {
              selectionModel: selectionModel,
              totalRowCount: totalRowCount,
              visibleRowCount: visibleRowCount,
              rowCount: rowCount,
              massUpdateData: massUpdateData,
              deals: deals,
              anchorEl: anchorEl,
              handleDeleteClick: handleDeleteClick,
              handleConfirmDelete: handleConfirmDelete,
              handleClosePopover: handleClosePopover,
              handleEditClick: handleEditClick,
              handleClone: handleCloneDeal,
              selectedData: selectedDeal,
            },
            panel: {
              sx: {
                border: filterMenuBorder,
                borderRadius: '4px',
              },
            },
            filterPanel: {
              sx: {
                ...filterPanelProps,
              },
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          onDensityChange={handleDensityChange}
          loading={isDealsLoading || isOrganizationsLoading}
        />
      </Box>
      <DealDetailsProvider dealData={selectedDeal}>
        {isContractDialogOpen && (
          <EditContractDialog
            open={isContractDialogOpen}
            onClose={handleCloseContractDialog}
            onAction={handleContractDialogAction} // Remove selectedDeal prop from here
          />
        )}
      </DealDetailsProvider>
    </>
  )
}

export default DealsPage
// import React, { useState, useEffect, useMemo } from 'react'
// import { Box, Typography } from '@mui/material'
// import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
// import CustomDealsGridToolbar from './CustomDealsGridToolbar'
// import { useTheme } from '@mui/material'
// import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
// import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
// import { useUpdateOrganization } from '../../api/aws/useUpdateOrganization'
// import { renderDealsColumns } from '../DataGrid/createColumns/renderDealsColumns'
// import EditContractDialog from '../EditContractDialog/EditContractDialog'
// import { filterPanelProps, gridContainerStyles } from './style'
// import { DealsGridFooter } from './DealsGridFooter'
// import { useOrganizations } from '../../api/aws/useOrganizations'
// import { useUsers } from '../../api/aws/useUsers'
// import { useDeals } from '../../api/aws/useDeals'
// import DetailsLoading from '../Loading/DetailsLoading'
// import { DealDetailsProvider, useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
// import { calculateProgress } from '../Approvals/calculateProgress'
// import { set } from 'lodash'

// export const initialSortModel = [
//   { field: 'lastModified', sort: 'desc' },
//   { field: 'name', sort: 'asc' },
//   { field: 'organizations', sort: 'asc' },
//   { field: 'status', sort: 'asc' },
// ]
// const DealsPage = () => {
//   const { deals: rawDeals, isDealsLoading, isDealsError } = useDeals()
//   const { newDeal, setNewDeal, productRows } = useDealDetailsContext()
//   const [selectedDeal, setSelectedDeal] = useState(null)
//   const [selectionModel, setSelectionModel] = useState([])
//   const [density, setDensity] = useState('standard')
//   const [selectedOrganization, setSelectedOrganization] = useState()
//   const { organizations, isOrganizationsLoading, isOrganizationsError } = useOrganizations()
//   const { users, isUsersLoading, isUsersError } = useUsers()
//   const { showSnackbar } = useSnackbarContext()
//   const theme = useTheme()
//   const filterMenuBorder =
//     theme.palette.mode === 'dark' ? '1px solid rgba(255,255,255,0.5)' : '1px solid rgba(0,0,0,0.5)'
//   const [isDeleting, setIsDeleting] = useState(false)
//   const [filterModel, setFilterModel] = useState(() => {
//     const savedFilters = localStorage.getItem('dealsFilterModel')
//     return savedFilters ? JSON.parse(savedFilters) : { items: [], quickFilterValues: [] }
//   })
//   const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
//     const savedVisibility = localStorage.getItem('dealColumnVisibilityModel')
//     return savedVisibility
//       ? JSON.parse(savedVisibility)
//       : {
//           implementationFee: false,
//           total: false,
//         }
//   })
//   useEffect(() => {
//     if (newDeal) {
//       console.log('[DealsPage] newDeal: ', newDeal)
//     }
//   }, [newDeal])
//   useEffect(() => {
//     if (productRows) {
//       console.log('[DealsPage] productRows: ', productRows)
//     }
//   }, [productRows])
//   const [sortModel, setSortModel] = useState(() => {
//     const savedSortModel = localStorage.getItem('dealSortModel')
//     return savedSortModel ? JSON.parse(savedSortModel) : initialSortModel
//   })
//   const [isContractDialogOpen, setIsContractDialogOpen] = useState(false)
//   const { mutate: deleteItem, isLoading } = useDeleteMutation()
//   const { mutate: updateOrganization } = useUpdateOrganization()
//   const [massUpdateData, setMassUpdateData] = useState()
//   const [anchorEl, setAnchorEl] = useState(null)
//   const [deals, setDeals] = useState([])
//   // const columns = renderDealsColumns(organizations, users, deals, density)
//   const apiRef = useGridApiRef()
//   const [visibleRowCount, setVisibleRowCount] = useState(deals ? deals.length : 0)
//   const [rowCount, setRowCount] = useState(deals ? deals.length : 0)

//   useEffect(() => {
//     if (filterModel) {
//       // Save the filter model to localStorage when it changes
//       localStorage.setItem('dealsFilterModel', JSON.stringify(filterModel))
//     }
//   }, [filterModel])

//   useEffect(() => {
//     if (selectionModel.length === 1) {
//       const selectedDeal = deals.find(org => org.id === selectionModel[0])
//       setSelectedDeal(selectedDeal)
//       setNewDeal(selectedDeal)
//       setMassUpdateData({ vertical: [], type: '' })
//     } else {
//       setSelectedDeal(null)
//     }
//   }, [selectionModel, deals])

//   const handleDeleteClick = event => {
//     setAnchorEl(event.currentTarget)
//   }
//   // Pre-process deals to add contractedById
//   useEffect(() => {
//     if (rawDeals && organizations) {
//       const processedDeals = rawDeals.map(deal => {
//         const organizationId = Array.isArray(deal.organizations) ? deal.organizations[0] : null
//         const organization = organizations.find(org => org.id === organizationId)
//         const contractedById =
//           organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : null
//         return {
//           ...deal,
//           contractedBy: [contractedById], // Add the contractedById to the deal object
//         }
//       })
//       setDeals(processedDeals) // Update state with processed deals
//     }
//   }, [rawDeals, organizations])

//   const columns = useMemo(() => {
//     return renderDealsColumns(organizations, users, deals, density)
//   }, [organizations, users, deals, density])
//   useEffect(() => {
//     if (selectedDeal) {
//       console.log('selectedDeal', selectedDeal)
//       const selectedOrgId =
//         selectedDeal.organizations && Array.isArray(selectedDeal.organizations) ? selectedDeal.organizations[0] : []
//       setSelectedOrganization(organizations.find(org => org.id === selectedOrgId))
//     }
//   }, [selectedDeal, setSelectedOrganization])

//   // Cleanup function for when deals component unmounts
//   useEffect(() => {
//     return () => {
//       setSelectionModel([])
//       setSelectedDeal(null)
//     }
//   }, [])

//   const handleConfirmDelete = async () => {
//     setIsDeleting(true)
//     if (selectedDeal) {
//       const params = {
//         endpoint: `/aws/delete/deals/${selectedDeal.id}`,
//         table: 'deals',
//       }
//       deleteItem(params, {
//         onSuccess: message => {
//           setSelectedDeal(null)
//           showSnackbar(message, 'success')
//           setIsDeleting(false)
//         },
//         onError: error => {
//           showSnackbar(error.message, 'error')
//           setIsDeleting(false)
//         },
//       })
//     }
//   }

//   const handleClosePopover = () => {
//     setAnchorEl(null)
//     if (selectionModel.length > 0) {
//       selectionModel.forEach(id => {
//         apiRef.current.selectRow(id, false)
//       })
//       setSelectionModel([])
//       setSelectedDeal(null)
//     }
//   }

//   const handleCloseSnackbar = () => {
//     if (selectionModel.length > 0) {
//       selectionModel.forEach(id => {
//         apiRef.current.selectRow(id, false)
//       })
//       setSelectionModel([])
//       setSelectedDeal(null)
//       handleClosePopover()
//     }
//   }

//   const handleCloneDeal = () => {
//     console.log('This icon will be used to clone the selected deal.')
//   }

//   const handleRowSelected = deal => {
//     if (deal) {
//       console.log('Deal Selected: ', deal)
//       setSelectedDeal(deal)
//     }
//   }

//   const handleEditClick = () => {
//     if (selectedDeal) {
//       // setNewDeal(prev => ({ ...prev, ...selectedDeal }))
//       setIsContractDialogOpen(true)
//     }
//   }

//   const handleCloseContractDialog = () => {
//     setIsContractDialogOpen(false)
//     setSelectedDeal(null)
//     handleClosePopover()
//   }

//   const handleContractDialogAction = () => {
//     setIsContractDialogOpen(false)
//     handleCloseSnackbar()
//   }

//   const open = Boolean(anchorEl)
//   const id = open ? 'simple-popover' : undefined

//   const handleSelectionModelChange = newSelectionModel => {
//     console.log('New Selection Model:', newSelectionModel)
//     if (newSelectionModel.length !== selectionModel.length) {
//       setSelectionModel(newSelectionModel)
//       if (newSelectionModel.length === 0 && handleRowSelected) {
//         handleRowSelected(null)
//       } else {
//         const selectedRowData = deals.find(row => row.id === newSelectionModel[0])
//         setSelectedDeal(selectedRowData)
//         if (handleRowSelected) {
//           handleRowSelected(selectedRowData)
//         }
//       }
//     }
//   }

//   const handleMassUpdateChange = e => {
//     const { name, value } = e.target
//     setMassUpdateData(prev => ({ ...prev, [name]: value }))
//   }

//   const handleColumnResizeStop = () => {
//     if (apiRef.current) {
//       apiRef.current.updateColumns()
//     }
//   }

//   useEffect(() => {
//     const handleFilterModelChange = () => {
//       const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
//       const filteredRowsCount = Object.values(filteredRowsLookup).filter(isVisible => isVisible).length
//       setVisibleRowCount(filteredRowsCount)
//     }

//     const api = apiRef.current
//     const unsubscribe = api.subscribeEvent('filterModelChange', handleFilterModelChange)

//     // Initialize the visible row count when the component mounts
//     handleFilterModelChange()

//     return () => {
//       unsubscribe()
//     }
//   }, [apiRef])

//   const totalRowCount = deals ? deals.length : 0
//   useEffect(() => {
//     if (massUpdateData) {
//       console.log('Mass Update Data: ', massUpdateData)
//     }
//   }, [massUpdateData])
//   const handleDensityChange = newDensity => {
//     setDensity(newDensity)
//   }

//   useEffect(() => {
//     if (sortModel) {
//       console.log('Deals Page sortModel: ', sortModel)
//     }
//   }, [sortModel])
//   const handleClearSorting = e => {
//     e.preventDefault()
//     const testSort = [...initialSortModel]
//     console.log('settingSort model: ', testSort)
//     setSortModel([...initialSortModel]) // Reset the sort model
//     localStorage.setItem('dealSortModel', JSON.stringify(initialSortModel)) // Also update localStorage
//   }
//   const handleClearVisibility = e => {
//     e.preventDefault()
//     const initialVisibilityState = {
//       implementationFee: false,
//       total: false,
//     }
//     setColumnVisibilityModel(initialVisibilityState)
//     localStorage.setItem('dealColumnVisibilityModel', JSON.stringify(initialVisibilityState))
//   }
//   const source = 'DealsPage'
//   return (
//     <>
//       <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 20px)' }}>
//         <DataGridPro
//           initialState={{
//             sorting: {
//               sortModel: [
//                 { field: 'lastModified', sort: 'desc' },
//                 { field: 'name', sort: 'asc' },
//                 { field: 'organizations', sort: 'asc' },
//                 { field: 'status', sort: 'asc' },
//               ],
//             },
//             filter: {
//               filterModel: filterModel,
//             },
//             columns: {
//               columnVisibilityModel: {
//                 implementationFee: false,
//                 total: false,
//               },
//             },
//           }}
//           rows={deals}
//           columns={columns}
//           rowHeight={density === 'compact' ? 40 : density === 'comfortable' ? 60 : 52}
//           getRowId={row => row.deal_uuid || row.id || row.deal.id}
//           checkboxSelection
//           disableRowSelectionOnClick
//           selectionModel={selectionModel}
//           onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
//           columnVisibilityModel={columnVisibilityModel}
//           onColumnVisibilityModelChange={newModel => {
//             setColumnVisibilityModel(newModel)
//             localStorage.setItem('dealColumnVisibilityModel', JSON.stringify(newModel))
//           }}
//           sortModel={sortModel}
//           onSortModelChange={newModel => {
//             setSortModel(newModel)
//             localStorage.setItem('dealSortModel', JSON.stringify(newModel))
//           }}
//           onRowCountChange={count => setRowCount(count)}
//           filterModel={filterModel}
//           onFilterModelChange={model => setFilterModel(model)}
//           apiRef={apiRef}
//           slots={{
//             toolbar: CustomDealsGridToolbar,
//             footer: DealsGridFooter,
//           }}
//           slotProps={{
//             toolbar: {
//               filterModel: filterModel,
//               visibilityModel: columnVisibilityModel,
//               sortModel: sortModel,
//               setFilterModel: setFilterModel,
//               onClearSorting: handleClearSorting,
//               onClearVisibility: handleClearVisibility,
//               context: 'deals',
//             },
//             footer: {
//               selectionModel: selectionModel,
//               totalRowCount: totalRowCount,
//               visibleRowCount: visibleRowCount,
//               rowCount: rowCount,
//               massUpdateData: massUpdateData,
//               deals: deals,
//               anchorEl: anchorEl,
//               handleDeleteClick: handleDeleteClick,
//               handleConfirmDelete: handleConfirmDelete,
//               handleClosePopover: handleClosePopover,
//               handleEditClick: handleEditClick,
//               handleClone: handleCloneDeal,
//               selectedData: selectedDeal,
//             },
//             panel: {
//               sx: {
//                 border: filterMenuBorder,
//                 borderRadius: '4px',
//               },
//             },
//             filterPanel: {
//               sx: {
//                 ...filterPanelProps,
//               },
//             },
//           }}
//           onColumnResizeStop={handleColumnResizeStop}
//           disableExtendRowFullWidth
//           onDensityChange={handleDensityChange}
//           loading={isDealsLoading || isOrganizationsLoading}
//         />
//       </Box>
//       {/* <DealDetailsProvider dealData={selectedDeal}> */}
//       {isContractDialogOpen && (
//         <EditContractDialog
//           open={isContractDialogOpen}
//           onClose={handleCloseContractDialog}
//           onAction={handleContractDialogAction} // Remove selectedDeal prop from here
//         />
//       )}
//       {/* </DealDetailsProvider> */}
//     </>
//   )
// }

// export default DealsPage
