import React, { useState } from 'react'
import { Avatar, Box, Grid, Button, Typography, Badge, Tooltip, useTheme, IconButton, InputLabel } from '@mui/material'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import ResolveButton from '../ui/resolve-button'
import ThumbsupButton from '../ui/thumbsup-button'
import ThumbsDownButton from '../ui/thumbsdown-button'
import RejectDialog from './RejectDialog'
import { formatDayjsDate } from '../DataGrid/createColumns/renderDealsColumns'
import TypographySecondary from '../ui/typography-secondary'
import TypographyPrimary from '../ui/typography-primary'
import QuestionIconButton from '../ui/info-icon-button'
import ResolveDialog from './ResolveDialog'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { tableSectionStyles } from '../DealApproval/style'
import ResolvedIconButton from '../ui/resolution-icon-button'
import { determineReviewState } from './RenderReviewState'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import AddTaskIcon from '@mui/icons-material/AddTask'
import RenderRoleIcon from './RenderRoleIcon.tsx'

export const RenderApproverStatus = ({ approver }) => {
  const theme = useTheme()
  return approver.hasApproved ? (
    <ThumbUpIcon sx={{ color: theme.palette.success.main, fontSize: '18px' }} />
  ) : approver.hasRejected ? (
    <ThumbDownIcon sx={{ color: theme.palette.icon.decline, fontSize: '18px' }} />
  ) : (
    <HourglassTopIcon sx={{ color: theme.palette.warning.main, fontSize: '18px' }} />
  )
}

export const RenderTime = ({ time }) => {
  return <TypographySecondary text={time ? time : null} />
}

const RenderApprover = ({
  activeUser,
  approvers,
  approver,
  requestor,
  isApproving,
  onApprove,
  rejectText,
  setRejectText,
  isRejecting,
  isRejectTextOpen,
  handleRejectTextOpen,
  handleRejectTextClose,
  handleReject,
  resolveText,
  setResolveText,
  isResolving,
  isResolveTextOpen,
  handleResolveTextOpen,
  handleResolveTextClose,
  handleResolve,
  requestedTime,
  isResolved,
  timeResolved,
  resolution,
}) => {
  const [adminIds, setAdminIds] = useState(['U062JDP8R5X', 'UHKAA49CJ'])
  // const [adminIds, setAdminIds] = useState(['UHKAA49CJ'])
  const theme = useTheme()
  // Helper function to render based on approver's state
  const renderApproverActions = approverId => {
    const reviewState = determineReviewState(activeUser, approver, requestor.hasResolved)
    if (adminIds.includes(activeUser.id) && !approver.hasApproved && !approver.hasRejected && !requestor.hasResolved) {
      // console.log(`AdminId detected: ${activeUser.id}, All approvers in awaiting state.....`)
      return (
        <Box key={approverId} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
          <ThumbsupButton id={approverId} isApproving={isApproving} onApprove={() => onApprove(approver.id)} />
          <ThumbsDownButton id={approverId} isRejecting={isRejecting} onReject={handleRejectTextOpen} />
          <RejectDialog
            open={isRejectTextOpen}
            onClose={() => handleRejectTextClose(approver.id)} // Pass approver.id directly
            rejectText={rejectText}
            setRejectText={setRejectText}
            onReject={() => handleReject(approver.id)} // Ensure approver.id is passed here
            approverId={approver.id}
          />
        </Box>
      )
    } else if (
      adminIds.includes(activeUser.id) &&
      activeUser.id === approver.id &&
      activeUser.id === requestor.id &&
      !approver.hasApproved &&
      approver.hasRejected
    ) {
      return (
        <Box key={approver.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          {approver.hasRejected && <QuestionIconButton isResolving={isResolving} reason={approver.reason} />}
          <ResolveButton onResolveTextOpen={handleResolveTextOpen} onResolve={() => handleResolve(approver.id)} />
          <ResolveDialog
            open={isResolveTextOpen}
            onClose={handleResolveTextClose}
            resolveText={resolveText}
            setResolveText={setResolveText}
            onResolve={handleResolve}
            approver={approver}
            requestor={requestor}
            reason={approver.reason}
          />
        </Box>
      )
    } else if (
      adminIds.includes(activeUser.id) &&
      !approver.hasApproved &&
      approver.hasRejected &&
      !requestor.hasResolved
    ) {
      return (
        <Box key={approver.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
          <ResolveButton onResolveTextOpen={handleResolveTextOpen} onResolve={() => handleResolve(approver.id)} />
          {approver.hasRejected && <QuestionIconButton isResolving={isResolving} reason={approver.reason} />}
          <ResolveDialog
            open={isResolveTextOpen}
            onClose={handleResolveTextClose}
            resolveText={resolveText}
            setResolveText={setResolveText}
            onResolve={handleResolve}
            approver={approver}
            requestor={requestor}
            reason={approver.reason}
          />
        </Box>
      )
    } else if (
      adminIds.includes(activeUser.id) &&
      !approver.hasApproved &&
      !approver.hasRejected &&
      requestor.hasResolved
    ) {
      return (
        <Box key={approver.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
          {requestor.hasResolved && (
            <ResolvedIconButton
              isResolving={isResolving}
              resolution={requestor && requestor.resolution ? requestor.resolution : 'No resolution added'}
            />
          )}
          <ThumbsupButton isApproving={isApproving} onApprove={onApprove} />
          <ThumbsDownButton isRejecting={isRejecting} onReject={handleRejectTextOpen} />
          <RejectDialog
            open={isRejectTextOpen}
            onClose={handleRejectTextClose}
            rejectText={rejectText}
            setRejectText={setRejectText}
            onReject={handleReject}
          />
        </Box>
      )
    } else if (adminIds.includes(activeUser.id) && approver.hasApproved) {
      return (
        <Box key={approver.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
          <ThumbsupButton isApproving={isApproving} onApprove={onApprove} />
          <ThumbsDownButton isRejecting={isRejecting} onReject={handleRejectTextOpen} />
          <RejectDialog
            open={isRejectTextOpen}
            onClose={handleRejectTextClose}
            rejectText={rejectText}
            setRejectText={setRejectText}
            onReject={handleReject}
          />
        </Box>
      )
    }
    if (activeUser.id === approver.id && approver.hasApproved) {
      return (
        <RenderTime
          time={
            approver.hasApproved
              ? formatDayjsDate(approver.timeApproved)
              : formatDayjsDate(approver.timeRejected) || formatDayjsDate(requestedTime)
          }
        />
      )
    } else if (!adminIds.includes(activeUser.id) && activeUser.id === requestor.id && approver.hasApproved) {
      return (
        <Box>
          <InputLabel sx={{ fontSize: 11 }}>{'Time Approved'}</InputLabel>
          <RenderTime
            time={
              approver.hasApproved
                ? formatDayjsDate(approver.timeApproved)
                : formatDayjsDate(approver.timeRejected) || formatDayjsDate(requestedTime)
            }
          />
        </Box>
      )
    } else if (activeUser.id === approver.id && !approver.hasApproved && !approver.hasRejected && !isResolved) {
      // The current user is the approver and has not approved or rejected
      return (
        <Box key={approver.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
          <ThumbsupButton isApproving={isApproving} onApprove={onApprove} />
          <ThumbsDownButton isRejecting={isRejecting} onReject={handleRejectTextOpen} />
          <RejectDialog
            open={isRejectTextOpen}
            onClose={handleRejectTextClose}
            rejectText={rejectText}
            setRejectText={setRejectText}
            onReject={handleReject}
          />
        </Box>
      )
    } else if (activeUser.id === approver.id && !approver.hasApproved && !approver.hasRejected && isResolved) {
      return (
        <Box key={approver.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
          {requestor.hasResolved && (
            <ResolvedIconButton
              isResolving={isResolving}
              resolution={requestor && requestor.resolution ? requestor.resolution : 'No resolution added'}
            />
          )}
          <ThumbsupButton isApproving={isApproving} onApprove={onApprove} />
          <ThumbsDownButton isRejecting={isRejecting} onReject={handleRejectTextOpen} />
          <RejectDialog
            open={isRejectTextOpen}
            onClose={handleRejectTextClose}
            rejectText={rejectText}
            setRejectText={setRejectText}
            onReject={handleReject}
          />
        </Box>
      )
    } else if (activeUser.id === approver.id && !approver.hasApproved && approver.hasRejected && !isResolved) {
      return (
        <>
          <Typography sx={{ color: theme.palette.text.secondary, fontSize: 12 }}>{'Awaiting Resolution...'}</Typography>
        </>
      )
    } else if (
      activeUser.id === requestor.id &&
      !approver.hasApproved &&
      !approver.hasRejected &&
      requestor.hasResolved
    ) {
      // The current user is the requestor and the deal has been approved or rejected

      return (
        <Box>
          <InputLabel sx={{ fontSize: 11 }}>{'Time Resolved'}</InputLabel>
          <RenderTime time={formatDayjsDate(timeResolved)} />
        </Box>
      )
    } else if (activeUser.id === requestor.id && !approver.hasApproved && approver.hasRejected) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1.5 }}>
          <ResolveButton onResolveTextOpen={handleResolveTextOpen} onResolve={handleResolve} />
          {approver.hasRejected && <QuestionIconButton isResolving={isResolving} reason={approver.reason} />}
          <ResolveDialog
            open={isResolveTextOpen}
            onClose={handleResolveTextClose}
            resolveText={resolveText}
            setResolveText={setResolveText}
            onResolve={handleResolve}
            approver={approver}
            requestor={requestor}
            reason={approver.reason}
          />
        </Box>
      )
    } else if (activeUser.id === requestor.id && approver.hasApproved && !approver.hasRejected) {
      return (
        <Box>
          <InputLabel sx={{ fontSize: 11 }}>{'Time Approved'}</InputLabel>
          <RenderTime time={formatDayjsDate(approver.timeApproved)} />
        </Box>
      )
    } else if (
      !adminIds.includes(activeUser.id) &&
      activeUser.id !== approver.id &&
      activeUser.id !== requestor &&
      !approver.hasApproved &&
      approver.hasRejected
    ) {
      // The current user is neither the approver nor the requestor
      return (
        <Box>
          <InputLabel sx={{ fontSize: 11 }}>
            {approver && approver.hasRejected
              ? 'Time Rejected'
              : approver && approver.hasApproved
              ? 'Time Approved'
              : 'Time Requested'}
          </InputLabel>
          <RenderTime
            time={
              approver.hasApproved
                ? formatDayjsDate(approver.timeApproved)
                : formatDayjsDate(approver.timeRejected) || formatDayjsDate(requestedTime)
            }
          />
        </Box>
      )
      // } else if (activeUser.id === requestor && !approver.hasApproved && !approver.hasRejected) {
    } else if (
      !adminIds.includes(activeUser.id) &&
      activeUser.id !== approver.id &&
      activeUser.id !== requestor &&
      approver.hasApproved &&
      !approver.hasRejected
    ) {
      return (
        <Box>
          <InputLabel sx={{ fontSize: 11 }}>
            {approver && approver.hasRejected
              ? 'Time Rejected'
              : approver && approver.hasApproved
              ? 'Time Approved'
              : 'Time Requested'}
          </InputLabel>
          <RenderTime
            time={
              approver && approver.hasRejected
                ? formatDayjsDate(approver.timeRejected)
                : approver && approver.hasApproved
                ? formatDayjsDate(approver.timeApproved)
                : approver.hasResolved
                ? formatDayjsDate(approver.timeResolved)
                : formatDayjsDate(requestedTime)
            }
          />
        </Box>
      )
    } else if (
      !adminIds.includes(activeUser.id) &&
      activeUser.id !== approver.id &&
      activeUser.id !== requestor &&
      !approver.hasApproved &&
      !approver.hasRejected
    ) {
      return (
        <Box>
          <InputLabel sx={{ fontSize: 11 }}>
            {approver && approver.hasRejected
              ? 'Time Rejected'
              : approver && approver.hasApproved
              ? 'Time Approved'
              : 'Time Requested'}
          </InputLabel>
          <RenderTime
            time={
              approver && approver.hasRejected
                ? formatDayjsDate(approver.timeRejected)
                : approver && approver.hasApproved
                ? formatDayjsDate(approver.timeApproved)
                : approver.hasResolved
                ? formatDayjsDate(approver.timeResolved)
                : formatDayjsDate(requestedTime)
            }
          />
        </Box>
      )
    } else if (activeUser.id === requestor.id && !approver.hasApproved && !approver.hasRejected) {
      return (
        <Box>
          <InputLabel sx={{ fontSize: 11 }}>
            {approver && approver.hasRejected
              ? 'Time Rejected'
              : approver && approver.hasApproved
              ? 'Time Approved'
              : 'Time Requested'}
          </InputLabel>
          <RenderTime
            time={
              approver && approver.hasRejected
                ? formatDayjsDate(approver.timeRejected)
                : approver && approver.hasApproved
                ? formatDayjsDate(approver.timeApproved)
                : approver.hasResolved
                ? formatDayjsDate(approver.timeResolved)
                : formatDayjsDate(requestedTime)
            }
          />
        </Box>
      )
    } else if (
      adminIds.includes(activeUser.id) &&
      activeUser.id !== approver.id &&
      activeUser.id !== requestor &&
      !approver.hasApproved &&
      approver.hasRejected
    ) {
      // The current user is neither the approver nor the requestor
      return (
        <Box key={approver.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          {approver.hasRejected && <QuestionIconButton isResolving={isResolving} reason={approver.reason} />}
          <ResolveButton onResolveTextOpen={handleResolveTextOpen} onResolve={() => handleResolve(approver.id)} />
          <ResolveDialog
            open={isResolveTextOpen}
            onClose={handleResolveTextClose}
            resolveText={resolveText}
            setResolveText={setResolveText}
            onResolve={handleResolve}
            approver={approver}
            requestor={requestor}
            reason={approver.reason}
          />
        </Box>
      )
      // } else if (activeUser.id === requestor && !approver.hasApproved && !approver.hasRejected) {
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        spacing: 1,
        mb: 1,
        borderRadius: '25px',
        bgcolor: theme.palette.background.default,
        // flexGrow: 1, // Ensure it grows within the parent container
      }}
    >
      <Grid
        key={approver.id}
        container
        sx={{
          flex: 'flex',
          padding: '10px',
          borderRadius: '25px',
          backgroundColor: theme.palette.background.reviewContent,
          border: theme.palette.mode === 'dark' ? '1px solid rgba(224, 224, 224, 0.4)' : '1px solid rgba(0,0,0,0.4)',
          alignItems: 'center',
          // justifyContent: 'space-between',
        }}
      >
        <RenderRoleIcon
          adminIds={adminIds}
          activeUserId={activeUser.id}
          approverId={approver.id}
          requestorId={requestor.id}
        />
        <Grid item xs={2}>
          <Badge
            badgeContent={<RenderApproverStatus approver={approver} />}
            color='secondary'
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'transparent', // Make the background transparent
              },
            }}
          >
            <Avatar alt={approver.name} src={approver.image} />
          </Badge>
        </Grid>
        <Grid item xs={4}>
          <TypographyPrimary text={approver.name} />
        </Grid>
        <Grid item xs={5} display='flex' justifyContent='flex-end'>
          {renderApproverActions(approver.id)}
        </Grid>
      </Grid>
    </Box>
  )
}

export default RenderApprover
