import { Box, Grid, IconButton, InputLabel, Typography, useTheme } from '@mui/material'
import React from 'react'
import { typographyStyle } from './style'
import DeleteIcon from '@mui/icons-material/Delete'
import { renderLabels } from './EditCampaign'

const ViewMode = ({ campaign, campaignKey }) => {
  const theme = useTheme()
  console.log('[ViewMode] rendered, campaign: ', campaign)
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: theme.palette.background.section,
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
        p: 2,
        mb: 5,
      }}
    >
      <Grid container spacing={2}>
        {/* Render Main Fields */}
        {Object.entries(campaign).map(([key, value], index) => {
          if (key === 'name') {
            return (
              <Grid item xs={12} key={`${index}-${key}`}>
                <Typography sx={{ fontSize: 22 }}>{value ?? '—'}</Typography>
              </Grid>
            )
          }
        })}
        {Object.entries(campaign).map(([key, value], index) => {
          if (
            [
              'key',
              'id',
              'timestamp',
              'campaign_uuid',
              'strategy_uuid',
              'keywords',
              'negativeKeywords',
              'ads',
              'items', // Skip 'items' here as it will be rendered separately
              'name',
            ].includes(key)
          ) {
            return null
          }
          return (
            <Grid item xs={6} key={`${index}-${key}`}>
              <InputLabel sx={{ fontSize: '0.75rem' }}>{key}</InputLabel>
              <Typography sx={typographyStyle(theme)}>{value ?? '—'}</Typography>
            </Grid>
          )
        })}
      </Grid>

      {/* Render Keywords and Negative Keywords */}
      {['keywords', 'negativeKeywords'].map((key, index) =>
        campaign[key] ? (
          <Grid item xs={6} key={`${index}-${key}`}>
            <Typography variant='subtitle2'>{key}</Typography>
            <Box sx={typographyStyle(theme)}>
              {campaign[key].map((item, idx) => (
                <Typography key={`${key}-${idx}`} variant='body2'>
                  {item ?? '—'}
                </Typography>
              ))}
            </Box>
          </Grid>
        ) : null
      )}

      {/* Render Ads */}
      {Array.isArray(campaign.ads) &&
        campaign.ads.map((ad, idx) => (
          <React.Fragment key={`ad-${idx}`}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>{`Ad Set ${idx + 1}`}</Typography>
            </Grid>
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'rgba(255,255,255,1)',
                bgcolor: theme.palette.background.default,
              }}
            >
              <Grid container spacing={2} p={2}>
                {/* Render all ad fields except specific ones */}
                {Object.entries(ad).map(([adKey, adValue]) => {
                  if (['id', 'key', 'setName', 'audience'].includes(adKey)) return null

                  return (
                    <Grid item xs={6} key={`${adKey}-${idx}`}>
                      <InputLabel sx={{ fontSize: '0.75rem' }}>{adKey}</InputLabel>
                      <Typography variant='body2' sx={typographyStyle(theme)}>
                        {adValue ?? '—'}
                      </Typography>
                    </Grid>
                  )
                })}
                {/* Render audience field last */}
                {ad.audience && Array.isArray(ad.audience) && (
                  <Grid item xs={6} key={`audience-${idx}`}>
                    <Typography variant='subtitle1'>Audience</Typography>
                    <Box sx={{ ...typographyStyle(theme) }}>
                      {ad.audience.map((audienceItem, audienceIdx) => (
                        <Typography key={`audience-${audienceIdx}`} variant='body2'>
                          {audienceItem}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </React.Fragment>
        ))}
      {/* Render Campaign Items if present */}
      {Array.isArray(campaign.items) && campaign.items.length > 0 && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <Typography variant='h6'>Campaign Items</Typography>
          {campaign.items.map((item, idx) => (
            <Box key={`item-${idx}`} sx={{ mt: 2, p: 2, border: '1px solid', borderColor: 'grey.400' }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant='subtitle1'>Campaign Item {idx + 1}</Typography>
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                {Object.entries(item).map(([itemKey, itemValue]) => {
                  if (['keywords', 'negativeKeywords'].includes(itemKey)) {
                    return (
                      <Grid item xs={12} key={`${itemKey}-${idx}`}>
                        <Typography variant='subtitle2' sx={{ mb: 1 }}>
                          {renderLabels(itemKey)}
                        </Typography>
                        <Box sx={{ ...typographyStyle(theme), whiteSpace: 'pre-wrap' }}>
                          {/* Multi-line display */}
                          {itemValue.map((keyword, keywordIdx) => (
                            <Typography key={`${itemKey}-${keywordIdx}`} variant='body2'>
                              {keyword ?? '—'}
                            </Typography>
                          ))}
                        </Box>
                      </Grid>
                    )
                  }

                  return (
                    <Grid item xs={6} key={`${itemKey}-${idx}`}>
                      <InputLabel sx={{ fontSize: '0.75rem' }}>{renderLabels(itemKey)}</InputLabel>
                      <Typography variant='body2' sx={typographyStyle(theme)}>
                        {Array.isArray(itemValue) ? itemValue.join(', ') || '—' : itemValue || '—'}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default ViewMode
