import React from 'react'
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  DialogActions,
  TextField,
  DialogTitle,
  Drawer,
  Typography,
  Grid,
  InputLabel,
} from '@mui/material'
const ResolveDialog = ({ open, onClose, resolveText, setResolveText, onResolve, approver, requestor, reason }) => {
  const handleChange = e => {
    onResolve(e?.target?.id, approver?.id)
  }
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='right'
      variant='temporary'
      sx={{
        width: { xs: '100vw', sm: '40vw' }, // Adjust width for small and large screens
        '& .MuiDrawer-paper': {
          width: { xs: '100vw', sm: '40vw' }, // Ensures drawer content matches width
          padding: '16px',
          boxSizing: 'border-box',
        },
      }}
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
    >
      <InputLabel variant='outlined' sx={{ textAlign: 'left', marginBottom: '16px' }}>
        Edit Resolution
      </InputLabel>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type='text'
            value={resolveText}
            onChange={e => setResolveText(e.target.value)}
            placeholder='Please describe how the issue was resolved'
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} sx={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button id={requestor.id} onClick={e => handleChange(e)} color='primary' variant='contained'>
            Confirm
          </Button>
        </Grid>
        <Grid item xs={12}>
          <InputLabel variant='outlined' sx={{ textAlign: 'left', marginBottom: '16px' }}>
            Reason for Rejection
          </InputLabel>
          {/* <TextField
            variant='outlined'
            value={reason ? reason : ''}
            InputProps={{
              readOnly: true,
              sx: {
                '.MuiInputBase-input::placeholder': {
                  fontSize: '16px', // Adjust as needed
                },
              },
            }}
            sx={{ width: '100%', height: '100%' }}
          /> */}
          <Typography sx={{ textAlign: 'left' }}>{reason ? reason : ''}</Typography>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default ResolveDialog
