import React from 'react'
import { Button, useTheme } from '@mui/material'
const ResolveButton = ({ isResolving, onResolveTextOpen }) => {
  const theme = useTheme()
  return (
    <>
      <Button
        sx={{
          backgroundColor: theme.palette.icon.warning,
          color: 'rgba(0,0,0,1)',
          maxHeight: '70%',
        }}
        size='small'
        disabled={isResolving}
        onClick={onResolveTextOpen}
        name='isApproved'
        variant='contained'
      >
        Resolve
      </Button>
    </>
  )
}

export default ResolveButton
