import React, { useEffect } from 'react'
import { Checkbox, FormControlLabel, FormControl, FormHelperText, Typography, useTheme } from '@mui/material'
import { determineApprovalState } from '../DealApproval/determineApprovalState'

const ExistingDeal = ({ noSignature, setNoSignature, newDeal, setNewDeal, organization }) => {
  const theme = useTheme()
  const handleChange = event => {
    const { checked } = event.target
    setNoSignature(checked)
    // Recalculate approval state based on the new value of noSignature
    const updatedApprovalState = determineApprovalState({ ...newDeal, noSignature: checked }, organization)

    // Update the newDeal state with the updated approval state
    setNewDeal(prev => ({
      ...prev,
      ...updatedApprovalState, // Apply the recalculated approval state
      noSignature: checked,
    }))
  }
  return (
    <FormControl sx={{ display: 'flex', alignItems: 'center', maxWidth: '200px' }} fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            checked={noSignature}
            onChange={handleChange} // Use the handleChange function here
            color='primary'
          />
        }
        label={<Typography sx={{ fontSize: 13, color: theme.palette.text.primary }}>No Signature Required</Typography>}
      />
      <FormHelperText sx={{ color: 'rgb(249, 167, 49)' }}>If checked moves to deal won</FormHelperText>
    </FormControl>
  )
}

export default ExistingDeal
