import React from 'react'
import { Table, TableBody, Typography, Box, useTheme, TableCell } from '@mui/material'
import { EmptyPlaceholderRow, EmptyRow } from './style'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import RenderRow from '../ContractDialog/RenderRow'

const ProductRowsTable = ({ newDeal, productRows, onDragEnd, onProductChange, validationErrors, removeRow }) => {
  const theme = useTheme()
  // Calculate how many empty rows to render (3 rows is the target layout consistency)
  const emptyRowsCount = productRows && productRows.length < 3 ? 3 - productRows.length : 0

  return (
    <Table
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.3)' : 'rgba(255,255,255,1)',
      }}
    >
      {productRows && Array.isArray(productRows) && productRows.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {provided => {
              return (
                <TableBody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  {/* Render actual product rows */}
                  {productRows.map((row, index) => (
                    <Draggable key={row.rowId} draggableId={row.rowId} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <RenderRow
                            key={row.rowId}
                            row={row}
                            index={index}
                            handleProductChange={onProductChange}
                            provided={provided}
                            snapshot={snapshot}
                            handleRemoveRow={removeRow}
                            validationErrors={validationErrors}
                            type={newDeal ? newDeal.type : ''}
                          />
                        )
                      }}
                    </Draggable>
                  ))}

                  {/* Render placeholder rows if needed */}
                  {[...Array(emptyRowsCount)].map((_, idx) => (
                    <EmptyPlaceholderRow key={`empty-row-${idx}`} />
                  ))}

                  {provided.placeholder}
                </TableBody>
              )
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        // Render full "ADD SERVICE HERE" if no rows exist
        <TableBody
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '81px', // Ensure a minimum height for the row
          }}
        >
          <EmptyRow>
            {/* <Typography sx={{ color: 'rgb(123, 126, 134)' }} variant='body1' align='center'> */}
            <TableCell sx={{ color: 'rgb(123, 126, 134)' }}>ADD SERVICE HERE</TableCell>
            {/* </Typography> */}
          </EmptyRow>
        </TableBody>
      )}
    </Table>
  )
}

export default ProductRowsTable
