export const createApprovedMessage = (deal, slackUserId) => {
  const dealId = deal ? deal.deal_uuid : ''
  const dealName = deal ? deal.name : ''
  const url = `https://grobotic.app/deals/${dealId}`
  const requestedById = deal ? deal.requestedBy : ''
  const nicCageGif =
    'https://media.giphy.com/media/CiTLZWskt7Fu/giphy.gif?cid=790b7611s5nfuigv06l0rdztt6dmk6f6r0qzt4f91losi8e6&ep=v1_gifs_search&rid=giphy.gif&ct=g'
  const csaTypes = ['CSA', 'RCSA']

  // Standard message text (optional)
  const message = `Hi <@${requestedById}>\n your deal (${
    deal ? deal.type : ''
  }) has been approved:\nName: ${dealName}\nURL: <${url}|Grobotic Link>`

  const blockMessage = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `Hi <@${requestedById}>`,
      },
    },
    {
      type: 'divider',
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Deal Approved*\n:rocket: :sunglasses: :thumbsup:\n*Name*: ${dealName}\n*<${url}|View in Grobotic>*`,
      },
      accessory: {
        type: 'image',
        image_url: nicCageGif,
        alt_text: 'One of us',
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Approved By:* <@${slackUserId}>`,
      },
    },
    {
      type: 'divider',
    },
  ]
  // Construct the payload
  const csaChannel = 'C07H620GSQ4'
  const msaChannel = 'C07H3E45WTV'

  const determineNotificationPayload = () => {
    if (deal && deal.type && csaTypes.includes(deal.type)) {
      return {
        channel: requestedById,
        text: message, // Fallback text for clients that don't support blocks
        blocks: blockMessage, // Properly structured blocks array
        icon_emoji: ':earth_americas:',
        username: 'Grobotic',
      }
    } else {
      return {
        channel: requestedById,
        text: message, // Fallback text for clients that don't support blocks
        blocks: blockMessage, // Properly structured blocks array
        icon_emoji: ':earth_americas:',
        username: 'Grobotic',
      }
    }
  }

  const payload = determineNotificationPayload(deal)

  return JSON.stringify(payload)
}
export const createRequestMessage = (deal, slackUserId, approvers, approverIds) => {
  const aceVenturaGif =
    'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExYWJiZ3Q4a3M1N2g0eDRncnA1ZHh2YzZzYnF6MW9ldnphM3UyMWxsdiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/JtrHPBNTg7KM0/giphy.webp'
  const dealId = deal ? deal.deal_uuid : ''
  const dealName = deal ? deal.name : ''
  const url = `https://grobotic.app/deals/${dealId}/reviewdeal`
  const approverId = approvers[0]

  // Standard message text (optional)
  const message = `<@${slackUserId}> has submitted a new approval request`

  const blockMessage = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: 'New Approval Request',
        emoji: true,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Submitted By*: <@${slackUserId}>`,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `:mag_right: :sunglasses: :thumbsup:\n*Name*: ${dealName}\n<${url}|View in Grobotic>`,
      },
      accessory: {
        type: 'image',
        image_url: aceVenturaGif,
        alt_text: 'aceVenturaGif',
      },
    },
    {
      type: 'divider',
    },
  ]
  const csaChannel = 'C07H620GSQ4'
  // const csaChannel = 'C07JBSH5P52' // test-request-channel
  const msaChannel = 'C07H3E45WTV'
  // const msaChannel = 'C07JBSH5P52' // test-request-channel
  const csaTypes = ['CSA', 'RCSA']
  const payload = {
    channel: deal && deal.type && csaTypes.includes(deal.type) ? csaChannel : msaChannel,
    text: message || '', // Fallback text for clients that don't support blocks
    blocks: blockMessage, // Properly structured blocks array
    icon_emoji: ':earth_americas:',
    username: 'Grobotic',
  }

  return JSON.stringify(payload)
}
export const createApproversNotification = (deal, slackUserId, approvers, approverIds) => {
  const aceVenturaGif =
    'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExYWJiZ3Q4a3M1N2g0eDRncnA1ZHh2YzZzYnF6MW9ldnphM3UyMWxsdiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/JtrHPBNTg7KM0/giphy.webp'
  const dealId = deal ? deal.deal_uuid : ''
  const dealName = deal ? deal.name : ''
  const url = `https://grobotic.app/deals/${dealId}/reviewdeal`

  // Standard message text (optional)
  const message = `<@${slackUserId}> has submitted a new approval request`

  const blockMessage = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: 'New Approval Request',
        emoji: true,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*Submitted By*: <@${slackUserId}>`,
      },
    },
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `:mag_right: :sunglasses: :thumbsup:\n*Name*: ${dealName}\n<${url}|View in Grobotic>`,
      },
      accessory: {
        type: 'image',
        image_url: aceVenturaGif,
        alt_text: 'aceVenturaGif',
      },
    },
    {
      type: 'divider',
    },
  ]
  // const testApproverId = 'U062JDP8R5X'
  const payload = {
    items: approvers.map(approver => ({
      text: message || '',
      channel: approver.id,
      // channel: testApproverId,
      blocks: blockMessage,
      icon_emoji: ':bell:',
    })),
  }
  console.log('[createNotifyPayload] payload: ', payload)
  return JSON.stringify(payload)
}
