import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions' // Ensure baseUrl is correct
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'

const resolveDeal = async ({ dealId, requestor, approverId, resolution }) => {
  // console.log('dealId, requestor, approverId, resolution', dealId, requestor, approverId, resolution)
  try {
    const slackToken = localStorage.getItem('slack_access_token')
    if (!slackToken) {
      throw new Error('No authentication token available')
    }

    const response = await axios.post(
      `${baseUrl}/aws/deals/${dealId}/resolve`, // Pass the dealId correctly here
      { requestor, approverId, resolution, slackToken }, // This is the payload with approverId
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: slackToken,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error approving the deal:', error)
    throw error // Propagate the error to handle it properly in the mutation
  }
}

export const useResolveDeal = () => {
  const { showSnackbar } = useSnackbarContext()

  const mutation = useMutation({
    mutationFn: resolveDeal,
    onSuccess: data => {
      console.log('Deal resolved successfully:', data)
      showSnackbar('Deal resolved successfully', 'success')
    },
    onError: error => {
      console.error('Error in deal approval process:', error)
      showSnackbar('Error during approval', 'error')
    },
  })
  return mutation
}
