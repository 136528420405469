import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { baseUrl } from '../queryMutations/mutationFunctions'

const updateDeal = async ({ dealId, dealData }) => {
  const url = `${baseUrl}/aws/updatedeals/${dealId}`
  const response = await axios.put(url, dealData)
  return response.data
}

const useSaveDeal = () => {
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbarContext()

  const mutation = useMutation({
    mutationFn: updateDeal,
    onMutate: async ({ dealId, dealData }) => {
      await queryClient.cancelQueries(['/aws/deals', dealId])
      const previousDeal = queryClient.getQueryData(['/aws/deals', dealId])

      // queryClient.setQueryData(['/aws/deals', dealId], old => ({
      //   ...old,
      //   ...dealData,
      // }))

      return { previousDeal }
    },
    onError: (err, { dealId }, context) => {
      queryClient.setQueryData(['/aws/deals', dealId], context.previousDeal)
      console.error('Mutation Error:', err)
      showSnackbar(err.response?.data?.message || err.message, 'error')
    },
    onSuccess: (_, { dealId }) => {
      // queryClient.invalidateQueries(['/aws/deals', dealId])
      // queryClient.invalidateQueries(['/aws/organizations'])
      showSnackbar('Deal updated successfully', 'success')
    },
  })

  return mutation
}

export default useSaveDeal
