import React from 'react'
import { Button, useTheme } from '@mui/material'

const ResetSortingButton = ({ isSortModelChanged, onClearSorting }) => {
  const theme = useTheme()
  return (
    <Button
      sx={{
        height: 40,
        color: theme.palette.text.primary, // Active sorting color
        fontWeight: isSortModelChanged ? 'bold' : '',
      }}
      disabled={isSortModelChanged ? false : true}
      onClick={onClearSorting}
    >
      Reset Sorting
    </Button>
  )
}
export default ResetSortingButton
