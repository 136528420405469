import React, { useEffect, useState } from 'react'
import {
  TextField,
  Box,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  Grid,
} from '@mui/material'
import ExistingDeal from '../CreateDeal/ExistingDealSelect'
import { calculateDayjsTotalMonths, calculateTotalMonths } from '../../utility/calculateTotalMonths'

const formatNumber = number => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)
}

const feeFieldStyles = fieldBgColor => ({
  '& .MuiFormLabel-root': {
    fontWeight: 'bold',
    color: 'rgba(249, 180, 45, 1)',
    '&.Mui-focused': {
      color: 'rgba(255,255,255)',
      backgroundColor: fieldBgColor, // Ensures no background change on focus
    },
  },
  '& .MuiInputBase-root': {
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
})
const readOnlyFieldStyles = fieldBgColor => ({
  '& .MuiInputBase-root': {
    pointerEvents: 'none', // Disables interaction
    border: 'none', // Removes border
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Makes the outline invisible
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on focus
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Keeps the outline invisible on hover
  },
  '& .MuiFormControl-root': {
    backgroundColor: fieldBgColor, // Ensures no background change on focus
  },
})

const ContractTotals = ({ newDeal, setNewDeal, totals, onChange, organization }) => {
  const [isSignatureRequired, setIsSignatureRequired] = useState(newDeal?.noSignature || false)
  const [payments, setPayments] = useState(2)
  const { totalMonths, allSame } = newDeal ? calculateDayjsTotalMonths(newDeal) : { totalsMonths: 1 }
  const totalGrossProfit = allSame ? newDeal?.totals?.grossProfit * (totalMonths || 1) : null
  const theme = useTheme()
  const handleChange = event => {
    const { name, value } = event.target
    console.log('name: ', name, 'value: ', value)
    if (name === 'numberOfPayments') {
      setNewDeal(prevDeal => ({
        ...prevDeal,
        numberOfPayments: value, // Update newDeal with selected payments
      }))
    } else {
      onChange(name, parseFloat(value))
    }
  }
  const fieldBgColor = theme.palette.mode === 'dark' ? 'rgb(22, 28, 42, 0.7)' : 'rgba(232, 246, 252, 0.5)'
  const csaTypes = ['CSA', 'RCSA']
  const numberOfPaymentOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
  ]

  // Effect to handle automatic updates to payments based on totals.total
  useEffect(() => {
    if (newDeal && csaTypes.includes(newDeal.type)) {
      console.log('[ContractTotals] setting new csa deal....')
      setNewDeal(prevDeal => ({
        ...prevDeal,
        numberOfPayments:
          totals.total < 2501
            ? 1
            : prevDeal.numberOfPayments === 1 && totals.total > 2500
            ? 2
            : prevDeal.numberOfPayments,
        totals: { ...totals, totalGrossProfit: totalGrossProfit },
      }))
    }
    // Do not automatically change payments if total is above 2500, let user control
  }, [totals.total, setNewDeal])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', marginLeft: '11px' }} gap={7}>
      <TextField
        label='Gross Profit Total'
        value={
          totals && totals.totalGrossProfit ? formatNumber(totals.totalGrossProfit) : formatNumber(totals.grossProfit)
        }
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        }}
        sx={readOnlyFieldStyles(fieldBgColor)}
      />
      <TextField
        label='Implementation Fee'
        name='implementationFee'
        type='number'
        value={totals.implementationFee || 0}
        onChange={handleChange}
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
        }}
        sx={feeFieldStyles(fieldBgColor)}
      />

      {allSame ? (
        <TextField
          label='Monthly Total'
          value={!csaTypes.includes(newDeal.type) ? formatNumber(totals.monthlyTotal) : '—'}
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          }}
          sx={readOnlyFieldStyles(fieldBgColor)}
          disabled={!csaTypes.includes(newDeal.type)}
        />
      ) : (
        <TextField label='Monthly Total' value={'Varying dates'} sx={readOnlyFieldStyles(fieldBgColor)} disabled />
      )}

      <TextField
        label='Total'
        value={formatNumber(totals.total)}
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          endAdornment:
            newDeal && newDeal.type === 'IO' ? (
              <InputAdornment position='end'>
                <Typography sx={{ fontSize: 13, color: 'rgb(134, 137, 145)' }}>{`${totalMonths} mo(s).`}</Typography>
              </InputAdornment>
            ) : null,
        }}
        sx={readOnlyFieldStyles(fieldBgColor)}
      />
      {newDeal && newDeal.type && csaTypes.includes(newDeal.type) && (
        <TextField
          label='Payments'
          name='numberOfPayments'
          InputProps={{
            startAdornment: <InputAdornment position='start'>#</InputAdornment>,
            readOnly: totals && totals.total && totals.total < 2501,
            sx: { borderColor: 'red' },
          }}
          select
          value={newDeal.numberOfPayments}
          onChange={handleChange}
          sx={
            totals && totals.total && totals.total < 2501
              ? { ...readOnlyFieldStyles(fieldBgColor), minWidth: '15%', maxWidth: '15%' }
              : {
                  ...feeFieldStyles(fieldBgColor),
                  minWidth: '15%',
                  maxWidth: '15%',
                }
          }
        >
          {numberOfPaymentOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <ExistingDeal
        noSignature={isSignatureRequired}
        setNoSignature={setIsSignatureRequired}
        newDeal={newDeal}
        setNewDeal={setNewDeal}
        organization={organization}
      />
    </Box>
  )
}

export default ContractTotals
