import React from 'react'
import { Box } from '@mui/material'

const UserImageComponent = ({ imageUrl, width, height }) => {
  const userImageStyles = {
    width: width ? width : '50px', // Set a fixed width
    height: height ? height : '50px', // Set a fixed height
    // borderRadius: '50%', // Make the image circular
    objectFit: 'cover', // Ensure the image covers the box while maintaining aspect ratio
    // padding: 0.3,
  }
  return (
    <Box
      component='img'
      src={imageUrl}
      alt='User'
      sx={{
        width: width ? width : '40px', // Set a fixed width
        height: height ? height : '40px', // Set a fixed height
        // ...userImageStyles,
        objectFit: 'cover',
        borderRadius: '50%',
      }}
    />
  )
}

export default UserImageComponent
