import { useGetQueryByIds } from '../aws/useGetQueryByIds'

export const useApproversById = approverIds => {
  const validIds = Array.isArray(approverIds) ? approverIds.filter(id => !!id) : []

  // Use the custom useGetQueryByIds hook to fetch user data by IDs
  const queryResults = useGetQueryByIds('/aws/users', 'users', validIds)

  // Extract user data from successful queries
  const approvers = queryResults
    .filter(result => result.isSuccess && result.data) // Filter out unsuccessful queries
    .map(result => result.data) // Extract the user object from the data
  const refinedApprovers =
    approvers && Array.isArray(approvers)
      ? approvers.map(approver => {
          return { id: approver.user_uuid, name: approver.name, image: approver.image_512, hasApproved: false }
        })
      : []
  // Aggregate loading and error states
  const isApproversByIdLoading = queryResults.some(result => result.isLoading)
  const isApproversByIdError = queryResults.some(result => result.isError)
  const approversByIdError = queryResults.find(result => result.isError)?.error

  return { approversById: refinedApprovers, isApproversByIdLoading, isApproversByIdError, approversByIdError }
}
