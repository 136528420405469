import React, { useEffect } from 'react'
import { Grid, Box, Typography, useTheme } from '@mui/material'
import RenderFields from './RenderFields'
import RenderProduct from './RenderProduct'

const RenderContent = ({
  strategy,
  setStrategy,
  organization,
  sectionKey,
  sectionLabel,
  section,
  isEditMode,
  onServiceFieldChange,
  errors,
}) => {
  const theme = useTheme()

  // Ensure the section is at least an empty array to avoid undefined errors
  const services = section || []
  const searchSubcategories = ['Search']
  const socialSubcategories = ['Social', 'Audio']
  const paidSearch =
    services && Array.isArray(services)
      ? services.filter(service => searchSubcategories.includes(service.subcategory))
      : []
  const paidSocial =
    services && Array.isArray(services)
      ? services.filter(service => socialSubcategories.includes(service.subcategory))
      : []
  const display =
    services && Array.isArray(services) ? services.filter(service => service.subcategory === 'Display') : []

  // console.log('paidSearch', paidSearch)
  // console.log('paidSocial', paidSocial)
  // console.log('display', display)
  // Check for empty services array before rendering
  if (!services.length) {
    return null
  }

  return (
    <>
      {paidSearch?.length > 0 ? (
        <>
          <Grid item xs={12}>
            <Typography
              sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)', ml: 1 }}
              variant='h6'
              gutterBottom
            >
              {'Search'}
            </Typography>
          </Grid>
          <Box
            sx={{
              bgcolor: theme.palette.background.main,
              border: '1px solid',
              borderColor: theme.palette.text.secondary,
            }}
          >
            {paidSearch.map((service, index) => (
              <Box key={service.instanceId}>
                <Grid item xs={12} pb={0.5}>
                  <RenderProduct strategy={strategy} organization={organization} service={service} />
                </Grid>
                <RenderFields
                  sectionKey={sectionKey}
                  key={service.instanceId}
                  service={service}
                  isEditMode={isEditMode}
                  onChange={onServiceFieldChange}
                  errors={errors?.updatedProducts?.[service.instanceId]}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : null}
      {paidSocial?.length > 0 ? (
        <>
          <Grid item xs={12}>
            <Typography
              sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)', ml: 1 }}
              variant='h6'
              gutterBottom
            >
              {'Social'}
            </Typography>
          </Grid>
          <Box
            sx={{
              bgcolor: theme.palette.background.main,
              border: '1px solid',
              borderColor: theme.palette.text.secondary,
            }}
          >
            {paidSocial.map(service => (
              <Box key={service.instanceId}>
                <Grid item xs={12} pb={0.5}>
                  <RenderProduct strategy={strategy} organization={organization} service={service} />
                </Grid>
                <RenderFields
                  strategy={strategy}
                  sectionKey={sectionKey}
                  key={service.instanceId}
                  service={service}
                  isEditMode={isEditMode}
                  onChange={onServiceFieldChange}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : null}
      {display?.length > 0 ? (
        <>
          <Grid item xs={12}>
            <Typography
              sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)', ml: 1 }}
              variant='h6'
              gutterBottom
            >
              {'Display'}
            </Typography>
          </Grid>
          <Box
            sx={{
              bgcolor: theme.palette.background.main,
              border: '1px solid',
              borderColor: theme.palette.text.secondary,
            }}
          >
            {display.map(service => (
              <Box key={service.instanceId}>
                <Grid item xs={12} pb={0.5}>
                  <RenderProduct strategy={strategy} organization={organization} service={service} />
                </Grid>
                <RenderFields
                  sectionKey={sectionKey}
                  key={service.instanceId}
                  service={service}
                  isEditMode={isEditMode}
                  onChange={onServiceFieldChange}
                  setStrategy={setStrategy}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : null}
    </>
  )
}

export default RenderContent
