import React from 'react'
import { Drawer, Grid, Button, TextField, Typography } from '@mui/material'

const RejectDialog = ({ open, onClose, rejectText, setRejectText, onReject, approverId }) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor='right'
      variant='temporary'
      sx={{
        width: { xs: '100vw', sm: '40vw' },
        '& .MuiDrawer-paper': {
          width: { xs: '100vw', sm: '40vw' },
          padding: '16px',
          boxSizing: 'border-box',
        },
      }}
      disableEnforceFocus
      disableAutoFocus
      disableRestoreFocus
    >
      <Typography variant='h6' sx={{ marginBottom: '16px' }}>
        Edit Description
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type='text'
            value={rejectText}
            onChange={e => setRejectText(e.target.value)}
            placeholder='Please describe reason for rejection'
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose} sx={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button onClick={() => onReject(approverId)} color='primary' variant='contained'>
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default RejectDialog
