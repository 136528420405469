import { RenderTime } from './RenderApprover'
import { formatDayjsDate } from '../DataGrid/createColumns/renderDealsColumns'
import { Typography, useTheme } from '@mui/material'
import RejectComponent from './RejectComponent'

export const determineReviewState = (activeUser, approver, isResolved) => {
  if (activeUser.id === approver.id && approver.hasApproved) {
    return 0
  } else if (activeUser.id === approver.id && !approver.hasApproved && !approver.hasRejected && !isResolved) {
    return 1
  } else if (activeUser.id === approver.id && !approver.hasApproved && !approver.hasRejected && isResolved) {
    return 2
  } else if (activeUser.id === approver.id && !approver.hasApproved && approver.hasRejected && !isResolved) {
    return 3
  }
}
// const RenderReviewState = (activeUser, approvers, approver, index, requestor) => {
//   const adminIds = ['U062JDP8R5X', 'UHKAA49CJ']
//   const theme = useTheme()
//   const determineRenderedComponents = num => {
//     switch (num) {
//       case 1:
//         return <RenderTime time={formatDayjsDate(approver.timeApproved)} />
//       case 2:
//         return (
//           <RejectComponent
//             open={isRejectTextOpen}
//             onClose={handleRejectTextClose}
//             rejectText={rejectText}
//             setRejectText={setRejectText}
//             onReject={handleReject}
//           />
//         )
//       case 3:
//         return (
//           <Typography sx={{ color: theme.palette.text.secondary, fontSize: 12 }}>{'Awaiting Resolution...'}</Typography>
//         )
//     }
//   }
//   const initialRequestorState = {
//     ...requestor,
//     hasResolved: false,
//   }
//   const initialApproverState = {
//     ...approver,
//     hasRejected: false,
//     hasApproved: false,
//   }

//   if (activeUser.id === approver.id && approver.hasApproved) {
//     return determineRenderedComponents(1) // time approved for approver
//   } else if (activeUser.id === approver.id && !approver.hasApproved && approver.hasRejected) {
//     return determineRenderedComponents(2) // awaiting resolution
//   }
// }

// export default RenderReviewState
