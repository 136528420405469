import React, { useState } from 'react'
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  useTheme,
  Grid,
  IconButton,
} from '@mui/material'
import { calculateAccountingSchedule } from './calculateAccountingSchedule'
import { useParams } from 'react-router-dom'
import { useDeal } from '../../api/aws/useDeal'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import { tableCellStyles, tableHeaderStyles } from '../DealApproval/style'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import IOTotalSchedule from './IOTotalSchedule'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DownloadIcon from '@mui/icons-material/Download'
import { downloadContract } from '../Deals/downloadContract'
import PandaDocDownload from './PandaDocDownload'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import useTotals from '../../api/customHooks/useTotals'
import PandaDocViewer from './PandDocViewer'

const AccountingSchedule = () => {
  const { dealId } = useParams()
  // const { deal, isLoading, isError } = useDeal(dealId)
  const { newDeal: deal, productRows } = useDealDetailsContext()
  const totals = useTotals(productRows, deal?.implementationFee || 0, deal.type)
  const [isDownloading, setIsDownloading] = useState(false)
  const theme = useTheme()

  // if (isLoading) return <Typography>Loading...</Typography>
  // if (isError) return <Typography>Error loading payment schedule</Typography>

  let paymentSchedule = calculateAccountingSchedule(deal)

  // Sort the paymentSchedule array by startDate (only for non-CSA)
  if (!['CSA', 'RCSA'].includes(deal.type)) {
    paymentSchedule = paymentSchedule.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
  }
  const handleDownloadContract = async () => {
    setIsDownloading(true)
    try {
      await downloadContract(deal ? deal.contractId : null)
      setIsDownloading(false)
    } catch (e) {
      setIsDownloading(false)
      console.log('Error: ', e)
    }
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // Align items to the top to prevent content from being cut off
        height: 'calc(100vh - 122px)', // Ensure the content fits within the viewport
        overflowY: 'auto', // Enable vertical scrolling if needed
        padding: 2, // Add some padding to prevent content from touching edges
      }}
    >
      {deal && deal.type !== 'IO' ? (
        <Grid container spacing={1} sx={{ maxWidth: '80vw' }}>
          {deal && deal.status === 'Completed' && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <PandaDocViewer contractId={deal ? deal.contractId : null} />
              </Box>
            </>
          )}
          {paymentSchedule.map((item, index) => {
            console.log('item: ', item)
            return (
              <Grid item xs={12} key={`${index} - ${item.paymentNumber || item.month}`}>
                <Table sx={{ marginBottom: 2 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} sx={tableHeaderStyles}>
                        {item.startDate && item.endDate
                          ? `${item.startDate} - ${item.endDate}`
                          : `Payment ${item.paymentNumber}`}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.products.map((product, idx) => (
                      <TableRow key={`${index}-${idx}-${product.productName}`}>
                        <TableCell sx={tableCellStyles(theme)}>
                          {<RenderServiceIcon service={product.productName} />}
                        </TableCell>
                        <TableCell sx={{ ...tableCellStyles(theme) }}>
                          {
                            <Typography
                              sx={{
                                color:
                                  product?.payer === 'Client' ? theme.palette.text.warning : theme.palette.text.primary,
                              }}
                            >
                              {formatCurrency(product.paymentAmount)}
                            </Typography>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell
                        sx={{
                          ...tableCellStyles(theme),
                          textAlign: 'right',
                          borderRight: 'none',
                        }}
                        colSpan={1}
                      >
                        Total:
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableCellStyles(theme),
                          color: 'rgb(50, 187, 130)',
                          borderLeft: 'none',
                        }}
                        colSpan={1}
                      >
                        {formatCurrency(item.totalAmount)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <IOTotalSchedule deal={deal} totals={totals} />
      )}
    </Box>
  )
}

export default AccountingSchedule
