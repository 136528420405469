import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { renderSprintType } from './style'

export const formatDayjsDate = dateStr => {
  // console.log('[renderDealsColumns][formatDayjsDate] dateStr: ', dateStr)
  if (dateStr) {
    return dayjs(dateStr).format('MM/DD/YYYY hh:mm A')
  } else {
    return null
  }
}
const formatDateString = date => {
  if (!date) return ''
  return dayjs.isDayjs(date) ? date.format('MM/DD/YYYY') : dayjs(date).format('MM/DD/YYYY')
}

export const renderSprintsColumns = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 100,
    flex: 1.95,
    valueGetter: params => params,
    renderCell: params => {
      return (
        <Box component='span' sx={{ cursor: 'pointer' }}>
          {params.value}
        </Box>
      )
    },
  },
  {
    field: 'priority',
    headerName: 'priority',
    width: 100,
    flex: 1.0,
    valueGetter: params => params,
    renderCell: params => {
      return (
        <Box component='span' sx={{ cursor: 'pointer' }}>
          {params.value}
        </Box>
      )
    },
  },
  {
    field: 'estimate',
    headerName: 'Estimate',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 100,
    flex: 1,
    valueGetter: params => params,
    renderCell: params => {
      return <Box sx={{ color: renderSprintType(params.value) }}>{params.value}</Box>
    },
  },
  {
    field: 'isCompleted',
    headerName: 'Completed?',
    width: 100,
    flex: 1,
    valueGetter: params => params,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 100,
    flex: 1,
    valueGetter: params => {
      return formatDateString(params)
    },
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 100,
    flex: 1,
    valueGetter: params => {
      return formatDateString(params)
    },
  },
  {
    field: 'creation_timestamp',
    headerName: 'Date Created',
    width: 100,
    flex: 1,
    valueGetter: params => {
      return formatDayjsDate(params)
    },
  },
]
