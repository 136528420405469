const msaAprovers = [
  'U015KLM51B6', // Patric Cavanaugh
  'U06LJAMPWJ1', // Thomas Ziolkowski
  'UHKAA49CJ', // Justin Widmayer
  'U062JDP8R5X', // Colin Cavanaugh
  'U01FK2W9YNS', // Ryan Dixon
]
const csaApprovers = [
  'UMU21GS7Q', // Zach Kent
  'UPH8S2B9B', // Philip Sanford
  'U03RWJJ4LRJ', // Brooke Santana
  'U062JDP8R5X', // Colin Cavanaugh
  'U01HSHDCFHU', // Anna Sella
]
const msaHousingIoApprovers = [
  'UMU21GS7Q', // Zach Kent
  'U062JDP8R5X', // Colin Cavanaugh
]
const msaApproversByCategory = category => {
  let approverIds = []

  switch (category) {
    case 'Digital':
      approverIds.push('U01FK2W9YNS', 'U06LJAMPWJ1', 'UMU21GS7Q')
      break
    case 'SEO':
      approverIds.push('U015KLM51B6', 'U01FK2W9YNS', 'UMU21GS7Q')

      break
    default:
      break
  }
  return approverIds // Return array, not object spread
}

const csaApproversByCategory = category => {
  let approverIds = []

  switch (category) {
    case 'Website':
      approverIds.push('UPH8S2B9B', 'UMU21GS7Q')

      break // Add break here to prevent fallthrough
    case 'Creative':
      // approverIds.push('U062JDP8R5X') //////////////////////////////
      approverIds.push('UMU21GS7Q', 'U03RWJJ4LRJ', 'U01HSHDCFHU')

      break // Add break here to prevent fallthrough
    default:
      break
  }
  return approverIds // Return array, not object spread
}
const approversByTypeAndCategory = (type, category) => {
  let approverIds = []

  switch (type) {
    case 'MSA':
    case 'RMSA':
      approverIds = approverIds.concat(msaApproversByCategory(category)) // Spread array into approverIds
      break
    case 'CSA':
    case 'RCSA':
      approverIds = approverIds.concat(csaApproversByCategory(category)) // Spread array into approverIds
      break
    case 'IO':
    case 'MSA - Housing':
    case 'RMSA - Housing':
      // approverIds.push('U062JDP8R5X') //////////////////////////////
      approverIds.push('UMU21GS7Q') // Add specific approver

      break
    default:
      break
  }

  return approverIds // Return the flattened array of approvers
}
export const determineApproverIds = newDeal => {
  const approverIds =
    newDeal && newDeal.type && Array.isArray(newDeal.products)
      ? newDeal.products.flatMap(product => {
          return approversByTypeAndCategory(
            newDeal.type,
            product && product.category
              ? product.category
              : product && product.selectedProduct
              ? product.selectedProduct.category_name
              : ''
          )
        })
      : []

  // Ensure unique approverIds using a Set
  const uniqueApproverIds = Array.from(new Set(approverIds))

  return uniqueApproverIds
}
