import React from 'react'
import { useTheme } from '../ThemeContext'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import { IconButton } from '@mui/material'

const ThemeToggleButton = () => {
  const { theme, toggleTheme } = useTheme()

  return (
    <IconButton onClick={toggleTheme}>
      {/* Conditionally render the correct icon */}
      {theme === 'dark' ? <LightModeIcon sx={{ cursor: 'pointer' }} /> : <DarkModeIcon sx={{ cursor: 'pointer' }} />}
    </IconButton>
  )
}

export default ThemeToggleButton
