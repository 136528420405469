import React, { useState, useEffect } from 'react'
import { Typography, Chip, FormControl, Select, MenuItem, useTheme } from '@mui/material'
export const dealStatusOptions = [
  {
    label: 'Month To Month',
    color: 'rgb(22, 138, 239)',
  },
  {
    label: 'Active',
    color: 'rgb(80, 200, 120)',
  },
  {
    label: '30 Day Notice',
    color: 'rgb(255, 123, 208)',
  },
  { label: 'Cancelled', color: 'rgb(0,0,0)' },
  {
    label: 'Expired',
    color: 'rgba(220, 20, 60, 0.8)',
  },
]

const DealStatusSelect = ({ newDeal, setNewDeal, error, helperText, onChange }) => {
  const theme = useTheme()
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    if (newDeal.dealStatus) {
      console.log('[DealStatusSelect] newDeal.dealStatus: ', newDeal.dealStatus)
      setSelectedValue(newDeal.dealStatus || 'Select Status')
    }
  }, [newDeal.dealStatus])
  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    // onChange(newValue)
    setNewDeal(prev => ({
      ...prev,
      dealStatus: newValue,
    }))
  }

  return (
    <Select
      id='status-select'
      value={selectedValue || ''}
      onChange={handleChange}
      name='status'
      required
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      {dealStatusOptions.map(option => (
        <MenuItem key={option.label} value={option.label}>
          <Chip
            size='small'
            style={{
              backgroundColor: option.color,
              height: 14,
              width: 14,
              marginRight: 8,
              borderRadius: '50%',
            }}
          />
          <Typography variant='subtitle1'>{option.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  )
}

export default DealStatusSelect
