import React, { createContext, useContext, useState, useEffect } from 'react'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useGetDocuments } from '../../api/PandaDoc/useGetDocuments'
import { useGetTemplates } from '../../api/PandaDoc/useGetTemplates'
import { useFetchWebhookData } from '../../api/aws/useFetchWebhookData'

const PandaContext = createContext()

export const PandaProvider = ({ children }) => {
  const { data: products, isLoading: productsLoading, isError: productsError, error: errorProducts } = useGetQuery(
    '/aws/groproducts',
    'gro_products'
  )
  // const { data: webhookData, error: webhookDataError, isLoading: webhookDataLoading } = useFetchWebhookData()
  // const {
  //   data: pandaDocuments,
  //   isLoading: pandaDocumentsLoading,
  //   isError: pandaDocumentsError,
  //   error: errorPandaDocuments,
  // } = useGetDocuments()
  // const {
  //   data: pandaTemplates,
  //   isLoading: pandaTemplatesLoading,
  //   isError: pandaTemplatesError,
  //   error: errorPandaTemplates,
  // } = useGetTemplates('wMtKSooN8KnGtPw4pxKVGL')
  // console.log('pandaTemplates', pandaTemplates)
  // console.log('TEMPLATES: ', pandaTemplates)
  // 'US4tHGKwBhhmJpVJbqB5X7'
  // 'wMtKSooN8KnGtPw4pxKVGL'
  // 'zNizzU3ngjDQCuA5T6PKun' // IO Template
  // 'wMtKSooN8KnGtPw4pxKVGL' // CSA Template
  // useEffect(() => {
  //   if (productsError) console.error('Products Error:', errorProducts)
  //   if (pandaDocumentsError) console.error('Panda Documents Error:', errorPandaDocuments)
  //   if (pandaTemplatesError) console.error('Panda Templates Error:', errorPandaTemplates)
  // }, [productsError, pandaDocumentsError, pandaTemplatesError])

  const pandaState = {
    products,
    productsLoading,
    productsError,
    // webhookData,
    // webhookDataLoading,
    // webhookDataError,
    // pandaDocuments,
    // pandaDocumentsLoading,
    // pandaDocumentsError,
    // pandaTemplates,
    // pandaTemplatesLoading,
    // pandaTemplatesError,
    errorProducts,
    // errorPandaDocuments,
    // errorPandaTemplates,
  }
  return <PandaContext.Provider value={pandaState}>{children}</PandaContext.Provider>
}

export const usePandaContext = () => useContext(PandaContext)

// import React, { createContext, useContext, useState, useEffect } from 'react'
// import { useGetQuery } from '../../api/aws/useGetQuery'
// import { useGetDocuments } from '../../api/PandaDoc/useGetDocuments'
// import { useGetTemplates } from '../../api/PandaDoc/useGetTemplates'

// const PandaContext = createContext()

// export const PandaProvider = ({ children }) => {
//   const { data: products, isLoading: productsLoading, isError: productsError } = useGetQuery(
//     '/aws/groproducts',
//     'gro_products'
//   )
//   const { data: pandaDocuments, isLoading: pandaDocumentsLoading, isError: pandaDocumentsError } = useGetDocuments()
//   const { data: pandaTemplates, isLoading: pandaTemplatesLoading, isError: pandaTemplatesError } = useGetTemplates(
//     // 'US4tHGKwBhhmJpVJbqB5X7'
//     // 'wMtKSooN8KnGtPw4pxKVGL'
//     // 'zNizzU3ngjDQCuA5T6PKun' // IO Template
//     'wMtKSooN8KnGtPw4pxKVGL' // CSA Template
//   )
//   // if (productsLoading) return <CircularProgress />
//   // if (productsError) return <div>Error...</div>

//   const organizationsState = {
//     products,
//     productsLoading,
//     productsError,
//     pandaDocuments,
//     pandaDocumentsLoading,
//     pandaDocumentsError,
//     pandaTemplates,
//     pandaTemplatesLoading,
//     pandaTemplatesError,
//   }

//   return <PandaContext.Provider value={organizationsState}>{children}</PandaContext.Provider>
// }

// export const usePandaContext = () => useContext(PandaContext)
