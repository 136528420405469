import React from 'react'
import { Button, IconButton, Tooltip, useTheme } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const ResolvedIconButton = ({ isResolving, resolution }) => {
  const theme = useTheme()
  return (
    <Tooltip title={resolution || 'No resolution provided'}>
      <IconButton
        size='small'
        disabled={isResolving}
        // onClick={onApprove}
        name='isResolving'
        variant='contained'
      >
        <HelpOutlineIcon sx={{ color: theme.palette.icon.warning }} />
      </IconButton>
    </Tooltip>
  )
}

export default ResolvedIconButton
