import { Stack, Typography, useTheme } from '@mui/material'
import { getProductIcon } from './RenderServiceIcons'
import BudgetSvg from '../../images/icons/budget.svg'
import DarkBudgetSvg from '../../images/icons/darkBudget.svg'

const renderBudgetIcons = (service, theme) => {
  switch (service) {
    case 'Management Fee / Retainer':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Paid Search Management Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Paid Social Management Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Geofencing Advertising Management Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Video Management Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Paid Search Management Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Programmatic Display Management Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Website Transfer Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Social Ads - Setup Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Setup Fee - Website/Creative':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'Setup Fee - Paid Media':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
    case 'SEO - Setup Fee':
      return theme.palette.mode === 'dark' ? DarkBudgetSvg : BudgetSvg
  }
}

const RenderServiceIcon = ({ service }) => {
  const theme = useTheme()
  const budgetServices = [
    'Management Fee / Retainer',
    'Paid Search Management Fee',
    'Paid Social Management Fee',
    'Geofencing Advertising Management Fee',
    'Video Management Fee',
    'Paid Search Management Fee',
    'Programmatic Display Management Fee',
    'Website Transfer Fee',
    'Social Ads - Setup Fee',
    'Setup Fee - Website/Creative',
    'Setup Fee - Paid Media',
    'SEO - Setup Fee',
  ]

  return (
    <Stack direction='row' alignItems='center' spacing={3}>
      <img
        key={`${service}`}
        src={budgetServices.includes(service) ? renderBudgetIcons(service, theme) : getProductIcon(service)}
        alt={service}
        style={{ width: 24, height: 24 }}
      />
      <Typography>{service}</Typography>
    </Stack>
  )
}

export default RenderServiceIcon
