import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'

const RefreshButton = ({ onClick, tooltipTitle }) => {
  const theme = useTheme()
  return (
    <IconButton
      onClick={onClick}
      sx={{
        gap: 2,
        borderRadius: 0,
        color: theme.palette.text.secondary,
        '&:hover': {
          color: theme.palette.text.primary,
          fontWeight: 'bold',
        },
        '&:hover .MuiSvgIcon-root': {
          color: 'rgb(1, 251, 248)',
        },
        pr: 2,
      }}
    >
      <Tooltip title={tooltipTitle}>
        <RefreshIcon sx={{ height: 30, width: 30, color: theme.palette.text.secondary }} />
      </Tooltip>
    </IconButton>
  )
}

export default RefreshButton
