import React, { useState, useRef, useEffect } from 'react'
import {
  DialogActions,
  Dialog,
  DialogContent,
  Stack,
  Box,
  Button,
  CircularProgress,
  TextField,
  useTheme,
  Collapse,
  Typography,
  Tooltip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ContactsAutocomplete from '../Contacts/ContactsAutocomplete'
import { useCreateOrganization } from '../../api/aws/useCreateOrganization'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { BoxScroller } from '../../style/styleElements'
import {
  dialogStyles,
  dialogContentStyles,
  boxStyles,
  buttonContainerStyles,
  buttonContainerFocusedStyles,
  collapseStyle,
  collapseStyleFocsed,
} from './style'
import PrimaryContactAutocomplete from '../Contacts/PrimaryContactAutocomplete'
import CustomTypeSelect from '../Reusable/CustomTypeSelect'
import CustomParentCompanyAutocomplete from '../CustomCompanyComponents/CustomParentCompanyAutocomplete'
import CustomVerticalAutocomplete from '../CustomCompanyComponents/CustomVerticalAutocomplete'
import CreateOrganizationHeader from './CreateOrganizationHeader'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import AdditionalContactsAutocomplete from '../Contacts/AdditionalContactsAutocomplete'
import DiscoveryPlusIcon from '../Icons/DiscoveryPlusIcon'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { useContacts } from '../../api/aws/useContacts'
import OrgBillingContactAutocomplete from '../Organizations/OrgBillingContactAutocomplete'

const initialState = {
  name: '',
  dba: '',
  type: '',
  billingTerms: '',
  status: 'Prospect',
  primaryContact: [],
  contacts: [],
  teamMemberIds: [],
  parentClient: [],
  contractedBy: [],
  affiliated: [],
  dealIds: [],
  website: '',
  vertical: '',
  billingPhone: '',
  businessPhone: '',
  email: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  closeDate: '',
  cmsUsed: '',
  socialProfiles: '',
  marketingObjective: '',
  geoTargeting: '',
  summary: '',
  owner: [],
}

const testDataState = {
  name: 'Grobotic Test 072224',
  dba: 'Grobot 072224',
  type: '',
  billingTerms: 'net 60',
  status: 'Prospect',
  primaryContact: [],
  contacts: [],
  teamMemberIds: [],
  parentClient: [],
  contractedBy: [],
  affiliated: [],
  dealIds: [],
  website: 'www.grobotic072224.com',
  vertical: '',
  billingPhone: '',
  businessPhone: '',
  email: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  closeDate: '',
  cmsUsed: '',
  socialProfiles: '',
  marketingObjective: '',
  geoTargeting: '',
  summary: '',
  owner: [],
}

export const housingTypeOptions = [
  {
    label: 'Property Management Company',
    type: 'PMC',
    color: 'rgb(93, 79, 251)',
  },
  { label: 'Ownership Group', type: 'OG', color: 'rgb(92, 179, 253)' },
  { label: 'Housing Property', type: 'HP', color: 'rgb(152, 214, 129)' },
]

export const typeOptions = [
  { label: 'Parent Client', type: 'PC', color: 'rgb(93, 79, 251)' },
  { label: 'Agency Whitelabel', type: 'AW', color: 'rgb(92, 179, 253)' },
  { label: 'Organization', type: 'ORG', color: 'rgb(242, 140, 40)' },
]
const parentOrganizationTypes = ['Ownership Group', 'Propery Management Company', 'Agency Whitelabel', 'Parent Client']
const nonHousingVerticals = [
  'Construction',
  'Tourism',
  'Marketing Agency',
  'Manufacturing',
  'Entertainment',
  'Restaurant (Food & Beverage)',
  'City/Government',
  'Financial Institution',
  'Technology',
  'Medical',
  'Lawyer',
  'Education',
  'Mortgage',
  'Other',
  'Real Estate',
]
const subOrganizationTypes = ['Housing Property', 'Organization']
const housingVerticals = ['Student', 'Multifamily', 'Homebuilding', 'Senior', 'Build to rent']
const CreateOrganization = ({ open, onClose }) => {
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { showSnackbar } = useSnackbarContext()
  const [selectedContacts, setSelectedContacts] = useState([])
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState(null)
  const [contactIds, setContactIds] = useState([])
  const [primaryContactId, setPrimaryContactId] = useState(null)
  const { contacts, isContactsLoading, isContactsError } = useContacts()

  const { mutate: createOrganization } = useCreateOrganization()
  const [showTooltip, setShowTooltip] = useState(false)
  const [errors, setErrors] = useState({})
  const [newOrganization, setNewOrganization] = useState({
    ...initialState,
    owner: [activeUser?.user?.id],
  })
  const theme = useTheme()
  const { validateField, errorStyle } = useValidationContext()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const cmsUsedRef = useRef(null)
  const discoveryFieldsRef = useRef(null)
  const handleBlur = e => {
    const { name, value } = e.target

    if (name === 'vertical' && value.length > 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: '', // Clear the error for valid value
      }))
    } else {
      const error = validateField(name, value)
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: error,
      }))
    }

    setShowTooltip(false)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setNewOrganization({ ...newOrganization, [name]: value })
  }

  const handleCompanyChange = (context, value) => {
    setNewOrganization(prev => ({
      ...prev,
      [context]: value,
    }))
  }
  const handleSelectingContacts = newValue => {
    const contactIds = newValue.map(contact => contact.contacts_uuid || contact.id)
    setContactIds(contactIds)
    setSelectedContacts(newValue)
    setNewOrganization(prev => ({ ...prev, contacts: contactIds }))
  }

  const handleSelectPrimaryContact = newValue => {
    setNewOrganization(prev => ({
      ...prev,
      primaryContact: Array.isArray(newValue)
        ? newValue.map(item => (item ? item.contacts_uuid : item.id))
        : newValue
        ? [newValue ? newValue.contacts_uuid : newValue.id]
        : [],
    }))
  }
  const handleSelectBillingContact = newValue => {
    setNewOrganization(prev => ({
      ...prev,
      billingContact: Array.isArray(newValue)
        ? newValue.map(item => (item ? item.contacts_uuid : item.id))
        : newValue
        ? [newValue ? newValue.contacts_uuid : newValue.id]
        : [],
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()

    let formIsValid = true
    const newErrors = {}

    // Validate name
    if (!newOrganization.name) {
      newErrors.name = 'Name is required'
      formIsValid = false
    }

    // Validate vertical field (array)
    if (!newOrganization.vertical || newOrganization.vertical.length === 0) {
      newErrors.vertical = 'Vertical is required'
      formIsValid = false
    }
    if (!newOrganization.website) {
      newErrors.website = 'Website is required'
      formIsValid = false
    }

    setErrors(newErrors)

    // If the form is invalid, do not submit
    if (!formIsValid) return

    const updatedOrganization = {
      ...newOrganization,
      owner: [activeUser?.user?.id || ''], // Ensure fallback to empty string if user ID is not available
    }

    createOrganization(updatedOrganization, {
      onSuccess: data => {
        setNewOrganization(initialState)
        handleClose()
        showSnackbar('Organization created successfully', 'success')
      },
    })
  }

  const handleVerticalChange = event => {
    const { value } = event.target

    // Clear the error if a valid selection is made
    setNewOrganization(prev => ({
      ...prev,
      vertical: value,
      contractedBy: [],
      affiliated: [],
    }))

    if (value.length > 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        vertical: '', // Clear the vertical error
      }))
    }
  }

  const handleCompanyBlur = (context, value) => {
    console.log('[CreateOrganization] handleCompanyBlur: value', value)
  }

  const handleToggleCollapse = () => {
    setIsCollapsed(prev => !prev)
  }

  useEffect(() => {
    if (isCollapsed && cmsUsedRef.current) {
      cmsUsedRef.current.focus()
      if (discoveryFieldsRef.current) {
        discoveryFieldsRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [isCollapsed])

  useEffect(() => {
    if (!open) {
      setNewOrganization(initialState)
      setSelectedContacts([])
    }
  }, [open])
  const handleClose = () => {
    onClose()
    setIsCollapsed(false)
    setSelectedContacts([])
  }

  const handleMouseEnter = () => {
    if (errors.website) {
      setShowTooltip(true)
    }
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        sx: dialogStyles(theme),
      }}
    >
      <CreateOrganizationHeader context={'Create Organizations'} />
      <BoxScroller>
        <DialogContent sx={dialogContentStyles}>
          <Stack spacing={1}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                label='Name'
                name='name'
                value={newOrganization.name}
                onChange={handleChange}
                fullWidth
                onBlur={handleBlur}
                sx={errors.name ? errorStyle : {}}
              />
              <TextField
                label='DBA'
                name='dba'
                value={newOrganization.dba}
                onChange={handleChange}
                fullWidth
                onBlur={handleBlur}
                sx={errors.dba ? errorStyle : {}}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <PrimaryContactAutocomplete
                contacts={contacts}
                label='Primary Contact'
                onChange={handleSelectPrimaryContact}
              />
              <PrimaryContactAutocomplete
                contacts={contacts}
                label='Billing Contact'
                onChange={handleSelectBillingContact}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <ContactsAutocomplete
                contacts={contacts}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
                label='Additional Contacts'
                onChange={handleSelectingContacts}
                isLoading={isContactsLoading}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <CustomVerticalAutocomplete
                label='Vertical'
                onChange={handleVerticalChange}
                onBlur={handleBlur}
                error={!!errors.vertical}
                helperText={errors.vertical}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              {newOrganization &&
              Array.isArray(newOrganization.vertical) &&
              newOrganization.vertical.some(v => housingVerticals.includes(v)) ? (
                <Box sx={{ flex: 1, height: '100%', minWidth: 0 }}>
                  <CustomTypeSelect
                    stateKey='type'
                    value={newOrganization.type}
                    options={housingTypeOptions}
                    displayLabel='Organization Type'
                    setterFn={setNewOrganization}
                  />
                </Box>
              ) : newOrganization &&
                Array.isArray(newOrganization.vertical) &&
                newOrganization.vertical.some(v => nonHousingVerticals.includes(v)) ? (
                <Box sx={{ flex: 1, height: '100%', minWidth: 0 }}>
                  <CustomTypeSelect
                    stateKey='type'
                    value={newOrganization.type}
                    options={typeOptions}
                    displayLabel='Organization Type'
                    setterFn={setNewOrganization}
                  />
                </Box>
              ) : null}
            </Box>
            {newOrganization && newOrganization.type && newOrganization.type === 'Housing Property' ? (
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <Box sx={{ flex: 1, height: '100%', minWidth: 0 }}>
                  <CustomParentCompanyAutocomplete
                    label='Contracted By'
                    value={newOrganization.contractedBy}
                    onChange={handleCompanyChange}
                    context='contractedBy'
                    type={newOrganization.type}
                    onBlur={handleCompanyBlur}
                  />
                </Box>
                <Box sx={{ flex: 1, height: '100%', minWidth: 0 }}>
                  <CustomParentCompanyAutocomplete
                    label='Affiliated'
                    value={newOrganization.affiliated}
                    onChange={handleCompanyChange}
                    context='affiliated'
                    type={newOrganization.type}
                    onBlur={handleCompanyBlur}
                  />
                </Box>
              </Box>
            ) : (newOrganization && newOrganization.type && newOrganization.type === 'Agency Whitelabel') ||
              newOrganization.type === 'Organization' ? (
              newOrganization &&
              Array.isArray(newOrganization.vertical) && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                  <Box sx={{ width: '100%' }}>
                    <CustomParentCompanyAutocomplete
                      label='Parent Client(s)'
                      value={newOrganization.parentClient}
                      onChange={handleCompanyChange}
                      context='parentClient'
                      onBlur={handleCompanyBlur}
                    />
                  </Box>
                </Box>
              )
            ) : null}

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Tooltip title={errors.website || ''} open={showTooltip} arrow placement='right'>
                <TextField
                  label='Website'
                  name='website'
                  value={newOrganization.website}
                  onChange={handleChange}
                  fullWidth
                  onBlur={handleBlur}
                  error={!!errors.website}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  sx={errors.website ? errorStyle : {}}
                />
              </Tooltip>

              <TextField
                label='Billing Phone'
                name='billingPhone'
                value={newOrganization.billingPhone}
                onChange={handleChange}
                fullWidth
                onBlur={handleBlur}
                sx={errors.billingPhone ? errorStyle : {}}
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  label='Street'
                  name='street'
                  value={newOrganization.street}
                  onChange={handleChange}
                  fullWidth
                  onBlur={handleBlur}
                  sx={errors.street ? errorStyle : {}}
                />
                <TextField
                  label='City'
                  name='city'
                  value={newOrganization.city}
                  onChange={handleChange}
                  fullWidth
                  onBlur={handleBlur}
                  sx={errors.city ? errorStyle : {}}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  label='State'
                  name='state'
                  value={newOrganization.state}
                  onChange={handleChange}
                  fullWidth
                  onBlur={handleBlur}
                  sx={errors.state ? errorStyle : {}}
                />
                <TextField
                  label='Zip'
                  name='zip'
                  value={newOrganization.zip}
                  onChange={handleChange}
                  fullWidth
                  onBlur={handleBlur}
                  sx={errors.zip ? errorStyle : {}}
                />
              </Box>
            </Box>

            <Button
              onClick={handleToggleCollapse}
              endIcon={isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              variant='outlined'
              sx={isCollapsed ? collapseStyleFocsed : collapseStyle}
              // sx={{ mt: 2, mb: 1 }}
            >
              {isCollapsed ? (
                <Box sx={buttonContainerFocusedStyles}>
                  <Box>
                    <DiscoveryPlusIcon />
                  </Box>
                  <Typography>Hide Discovery Fields</Typography>
                </Box>
              ) : (
                <Box sx={buttonContainerStyles}>
                  <Box>
                    <DiscoveryPlusIcon />
                  </Box>
                  <Typography>Addt'l Discovery Fields (optional)</Typography>
                </Box>
              )}
            </Button>
            <Collapse in={isCollapsed}>
              <div ref={discoveryFieldsRef}>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <TextField
                    placeholder='ie. Wordpress, entrata, squarespace'
                    label='CMS being used'
                    name='cmsUsed'
                    value={newOrganization.cmsUsed}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                    sx={errors.cmsUsed ? errorStyle : {}}
                    inputRef={cmsUsedRef}
                  />
                  <TextField
                    placeholder='ie. Facebook, Instagram etc.'
                    label='Active Social Profiles'
                    name='socialProfiles'
                    value={newOrganization.socialProfiles}
                    onChange={handleChange}
                    multiline
                    fullWidth
                    onBlur={handleBlur}
                    sx={errors.socialProfiles ? errorStyle : {}}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    mt: 1,
                    mb: 1,
                  }}
                >
                  <TextField
                    placeholder='Do they want Leads, Branding/awareness, In-Store Visits, Website Traffic or a mix of these?'
                    label='Marketing Objective'
                    name='marketingObjective'
                    value={newOrganization.marketingObjective}
                    onChange={handleChange}
                    multiline
                    fullWidth
                    onBlur={handleBlur}
                    sx={errors.marketingObjective ? errorStyle : {}}
                  />
                  <TextField
                    placeholder='ie. State, City, Zip codes, etc'
                    label='Geographic Targeting'
                    name='geoTargeting'
                    value={newOrganization.geoTargeting}
                    onChange={handleChange}
                    multiline
                    fullWidth
                    onBlur={handleBlur}
                    sx={errors.geoTargeting ? errorStyle : {}}
                  />
                </Box>
              </div>
            </Collapse>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                label='Summary & Notes'
                name='summary'
                value={newOrganization.summary}
                onChange={handleChange}
                multiline
                fullWidth
                onBlur={handleBlur}
                sx={errors.summary ? errorStyle : {}}
              />
            </Box>
          </Stack>
        </DialogContent>
      </BoxScroller>
      <DialogActions>
        <Box>
          <Button onClick={handleClose} color='primary' variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary' variant='contained'>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default CreateOrganization
