import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  useTheme,
} from '@mui/material'
import { calculatePaymentSchedule } from './calculatePaymentSchedule'
import { calculateCSAPaymentSchedule } from './calculateCSAPaymentSchedule'
import { useParams } from 'react-router-dom'
import { useDeal } from '../../api/aws/useDeal'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'

const PaymentSchedule = () => {
  const { dealId } = useParams()
  const { deal, isLoading, isError } = useDeal(dealId)
  const theme = useTheme()
  if (isLoading) return <Typography>Loading...</Typography>
  if (isError) return <Typography>Error loading payment schedule</Typography>

  // Determine which calculation function to use based on contract type
  const paymentSchedule =
    deal.type === 'CSA' || deal.type === 'RCSA' ? calculateCSAPaymentSchedule(deal) : calculatePaymentSchedule(deal)
  console.log('PAYMENT SCHEDULE: ', paymentSchedule)
  const totalContractAmount = paymentSchedule
    .reduce((total, item) => total + parseFloat(item.paymentAmount), 0)
    .toFixed(2)

  return (
    <Box sx={{ marginTop: 4, p: 2 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: theme.palette.background.header,
                  color: theme.palette.text.primary,
                  border: '1px solid white',
                }}
              >
                Month
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: theme.palette.background.header,
                  color: theme.palette.text.primary,
                  border: '1px solid white',
                }}
              >
                Year
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: theme.palette.background.header,
                  color: theme.palette.text.primary,
                  border: '1px solid white',
                }}
              >
                Payment Amount ($)
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  backgroundColor: theme.palette.background.header,
                  color: theme.palette.text.primary,
                  border: '1px solid white',
                }}
              >
                Products Associated
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentSchedule.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  border: '1px solid rgba(224, 224, 224, 1)',
                  backgroundColor: 'transparent',
                }}
              >
                <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  {deal.type === 'CSA' || deal.type === 'RCSA' ? `Payment ${index + 1}` : item.month}
                </TableCell>
                <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  {deal.type === 'CSA' || deal.type === 'RCSA' ? item.year : item.year}
                </TableCell>
                <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  {formatCurrency(item.paymentAmount)}
                </TableCell>
                <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  {Array.isArray(item.productsAssociated)
                    ? item.productsAssociated.join(', ')
                    : item.productsAssociated}
                </TableCell>
              </TableRow>
            ))}
            <TableRow
              sx={{
                border: '1px solid rgba(224, 224, 224, 1)',
                backgroundColor: 'rgb(30, 39, 53)',
                color: theme.palette.text.primary,
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  fontWeight: 'bold',
                  color: theme.palette.text.primary,
                  border: '1px solid white',
                }}
              >
                Total Contract Amount
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  fontWeight: 'bold',
                  color: theme.palette.text.primary,
                  border: '1px solid white',
                }}
              >
                ${totalContractAmount}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default PaymentSchedule
