export const boxOneStyle = height => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: height ? height : '0px',
  marginBottom: 7,
})
export const boxTwoStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
export const boxThreeStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: 1,
}
export const boxFourStyle = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 2,
}
export const dialogTitleStyle = (fontsize, titleColor) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  color: titleColor ? titleColor : '',
  fontSize: fontsize ? fontsize : '2rem',
})
