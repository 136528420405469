import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Typography, useTheme } from '@mui/material'
import { useParams, Link, Outlet, useLocation } from 'react-router-dom'
import Loading from '../Loading/Loading'
import { useDeal } from '../../api/aws/useDeal'
import ContactDetailsHeader from './ContactDetailsHeader'
import { useContactById } from '../../api/customHooks/useContactById'

const ContactPage = () => {
  const { contactId } = useParams()
  const {
    data: contact,
    isLoading: contactLoading,
    isError: contactError,
    error: contactDetailsError,
  } = useContactById(contactId)
  const theme = useTheme()
  const location = useLocation()
  const [tabValue, setTabValue] = React.useState(0)
  useEffect(() => {
    // Determine the tab value based on the current route
    if (location.pathname.includes('/additionalinfo')) {
      setTabValue(1)
    } else {
      setTabValue(0)
    }
  }, [location])

  if (contactLoading) {
    return <Loading />
  }

  if (contactError) {
    return <Typography>Error loading contact details</Typography>
  }

  return (
    <>
      <Box>
        <ContactDetailsHeader contact={contact} />
      </Box>
      <Tabs
        value={tabValue}
        aria-label='contact tabs'
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(255, 255, 255)',
        }}
      >
        <Tab label='Details' component={Link} to={`/contacts/${contactId}`} />
        <Tab label='Additional Info' component={Link} to={`/contacts/${contactId}/additionalinfo`} disabled />
      </Tabs>
      <Box
        sx={{
          maxHeight: 'calc(100vh - 122px)', // Adjust this value based on your header height
          overflowY: 'auto',
          p: 3,
        }}
      >
        <Outlet />
      </Box>
    </>
  )
}

export default ContactPage
