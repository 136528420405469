import { v4 as uuidv4 } from 'uuid'

// Helper function to call /aws/campaigns endpoint
export const createCampaignForProduct = async (strategy, product, organization) => {
  // Define campaign details based on product subcategory
  const searchSubcategories = ['Search']
  const socialSubcategories = ['Social', 'Audio']
  const additionalCampaignDetails = searchSubcategories.includes(product.subcategory)
    ? createBrandingCampaign(strategy, product, organization)
    : socialSubcategories.includes(product.subcategory)
    ? createSocialCampaigns(strategy, product, organization)
    : {}

  // Flatten additionalCampaignDetails if it contains nested objects
  const flattenedCampaignDetails = Object.entries(additionalCampaignDetails).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      // If value is an object, spread its properties
      acc = { ...acc, ...value }
    } else {
      // Otherwise, add the value directly
      acc[key] = value
    }
    return acc
  }, {})

  // Return a new campaign object without nested structures
  return {
    name: `${product.productName} Campaign`,
    campaign_uuid: product?.instanceId, // Unique ID
    strategy_uuid: strategy.strategy_uuid,
    ...flattenedCampaignDetails, // Spread flattened details here
  }
}

export const createBrandingCampaign = (strategy, product, property) => {
  return [
    {
      key: `${product?.instanceId}-branding`,
      id: `${product?.instanceId}`,
      name: `Campaign One: ${property.name} - Search - Branded`,
      type: 'Search',
      demographicTargeting: strategy.addtlInfo?.demographicTargeting || '',
      landingPageUrl: property?.landingPageUrl || '',
      targetingSpecifics: strategy.integration?.targetingSpecifics || '',
      location: `${property?.city}, 5-10 miles around address`,
      radius: '5-10 miles',
      targetAddress: `${property?.street}, ${property?.city}, ${property?.state}, ${property?.zip}`,
      adGroup: `${property?.name} - Branded`,
      keywords: [
        `"${property?.name}"`,
        `[${property?.name}]`,
        `"${property?.name} ${property?.city}"`,
        `[${property?.name} ${property?.city}]`,
        `"${property?.name} apartments"`,
        `[${property?.name} apartments]`,
      ],
      negativeKeywords: [],
    },
  ]
}

export const createCityCampaign = (strategy, product, property) => {
  return [
    {
      key: `${product?.instanceId}-city`,
      id: `${product?.instanceId}`,
      name: `Campaign Two: ${property?.name || 'Property Name'} - Search - ${property?.city || 'City'}`,
      type: 'Search',
      demographicTargeting: strategy.addtlInfo?.demographicTargeting || '',
      landingPageUrl: property?.landingPageUrl || '',
      targetingSpecifics: strategy.integration?.targetingSpecifics || '',
      location: `${property?.city || 'City'}, 5-10 miles around address`,
      adGroup: `${property?.name} - City`,
      keywords: [
        `"${property?.city || 'city'} apartments"`,
        `"${property?.city || 'city'} ${property?.state} apartments"`,
        `"${property?.city || 'city'} apartments for rent"`,
        `"Apartments near ${property?.city || 'city'}"`,
        `"Apartments for rent ${property?.city || 'city'}"`,
        `"Apartments in ${property?.city || 'city'} ${property?.state}"`,
      ],
      negativeKeywords: [`${property?.name}`],
    },
  ]
}

export const createAdSet1 = (strategy, product, property) => {
  return {
    key: `socialAds-set1-${product?.instanceId}`,
    id: `${product?.instanceId}`,
    setName: 'Set 1',
    name: 'Leads - Universal Conversion - Interest Targeting',
    conversionLocation: `${property.website || 'Website'}`,
    performanceGoal: 'Maximize number of conversions',
    conversionEvent: 'Universal Conversion',
    audience: [
      'Renting (real estate)',
      'Rent.com (real estate)',
      'Penthouse apartment (real estate)',
      'Apartments.com (real estate)',
      'Apartment List (real estate)',
      'Apartment Guide (real estate)',
      'Apartment (real estate)',
      'Zillow (real estate)',
      'Trulia (real estate)',
      'Property finder (real estate)',
      'Real estate (industry)',
      'Residential area (real estate)',
      'Premier Agent - Zillow & Trulia (real estate)',
    ],
  }
}
export const createAdSet2 = (strategy, product, property) => {
  return {
    key: `socialAds-set2-${product?.instanceId}`,
    id: `${product?.instanceId}`,
    setName: 'Set 2',
    name: 'Open Targeting',
    placements: 'Advantage+ Placements',
  }
}
export const createSocialAds = (strategy, product, property) => {
  return [createAdSet1(strategy, product, property), createAdSet2(strategy, product, property)]
}

export const createSocialCampaigns = (strategy, product, property) => {
  return [
    {
      key: `socialCampaigns-${product?.instanceId}`,
      id: `${product?.instanceId}`,
      name: `${product?.productName} - Targeting ${
        product && product?.socialPlatform ? `- ${product?.socialPlatform}` : ''
      }`,
      type: 'Social',
      platform: product?.socialPlatform || 'No platform designated',
      demographicTargeting: strategy?.addtlInfo?.demographicTargeting || 'Demo Targeting',
      landingPageUrl: strategy?.paidSocial?.landingPageUrl || 'Landing Page Url',
      geoTargeting: '15 miles around city - radius size can be increased',
      exclusions: ['FormFill', 'Account Creation'],
      ads: createSocialAds(strategy, product, property),
    },
  ]
}
export const createNewCampaignObject = campaign => {
  const searchCampaignTypes = ['Search', 'Audio']
  const socialCampaignTypes = ['Social']
  return searchCampaignTypes.includes(campaign?.type)
    ? {
        campaign_uuid: campaign.campaign_uuid,
        id: uuidv4(),
        type: campaign?.type,
        adGroup: '',
        demographicTargeting: '',
        location: '',
        keyWords: [],
      }
    : socialCampaignTypes.includes(campaign?.type)
    ? {
        campaign_uuid: campaign.campaign_uuid,
        id: uuidv4(),
        type: campaign?.type,
        adGroup: '',
        demographicTargeting: '',
        location: '',
        keyWords: [],
      }
    : {
        campaign_uuid: campaign.campaign_uuid,
        id: uuidv4(),
        type: campaign?.type,
        adGroup: '',
        demographicTargeting: '',
        location: '',
        keyWords: [],
      }
}
export const createAdSetObject = (campaignState, itemIndex) => {
  const adSets = campaignState.items[itemIndex].ads || [] // Ensure ads is an array
  return {
    id: uuidv4(),
    setName: `Set ${adSets.length + 1}`,
    name: '',
    audience: [],
    conversionEvent: '',
    conversionLocation: '',
    performanceGoal: '',
    placements: '',
  }
}
