import React from 'react'
import { StyledGridTypography, StyledGridDefaultTypography, BoxScroller } from '../../style/styleElements'
import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { getStatusColor } from '../DataGrid/createColumns/renderOrganizationColumns'
import RenderChip from '../RenderChipComponents/RenderChip'

const MyOrganizations = ({ org }) => {
  const theme = useTheme()
  const rowBackgroundColor = theme.palette.mode === 'dark' ? 'rgba(30, 39, 53, 0.9)' : 'rgb(255, 255, 255, 1)'
  const orgId = org ? org.org_uuid : ''
  return (
    <>
      {orgId ? (
        <Link
          to={`/organizations/${orgId}`}
          style={{
            textDecoration: 'none',
            color: 'inherit',
            backgroundColor: 'transparent',
          }}
        >
          <Grid
            container
            sx={{
              margin: '0 auto',
              marginTop: '15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // This helps space out the items evenly
              backgroundColor: rowBackgroundColor,
              borderRadius: '15px',
              // padding: '10px',
              '& > .MuiGrid-item': {
                padding: '10px',
              },
              '&:hover': {
                transform: 'scale(1.02)',
                // margin: '-1px', // Slightly reduce the margin to compensate for the transform
                zIndex: 1, // Ensure the scaled item is above others if they overlap
              },
              transition: 'all 0.3s ease-in-out', // Include margin in the transition for a smooth effect
            }}
          >
            <Grid item xs={4}>
              <StyledGridDefaultTypography>Name</StyledGridDefaultTypography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  alignItems: 'center',
                  height: '100%',
                  gap: 1,
                }}
              >
                <Avatar
                  src={org.photoUrl}
                  sx={{
                    width: 26,
                    height: 26,
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                  alt={org.name}
                />
                <StyledGridTypography>{org.name}</StyledGridTypography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              {/* <StyledGridDefaultTypography>Type</StyledGridDefaultTypography> */}
              <RenderChip type={org.type} />
            </Grid>
            <Grid item xs={2}>
              <StyledGridDefaultTypography>Deals</StyledGridDefaultTypography>
              <Typography>{org.dealIds && Array.isArray(org.dealIds) ? org.dealIds.length : 0}</Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledGridDefaultTypography>Status</StyledGridDefaultTypography>
              <StyledGridTypography sx={{ color: getStatusColor(org.status) }}>{org.status}</StyledGridTypography>
            </Grid>
          </Grid>
        </Link>
      ) : (
        <Grid
          container
          sx={{
            margin: '0 auto',
            marginTop: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between', // This helps space out the items evenly
            backgroundColor: rowBackgroundColor,
            borderRadius: '15px',
            padding: '10px',
            '& > .MuiGrid-item': {
              padding: '10px',
            },
            '&:hover': {
              transform: 'scale(1.01)',
              // margin: '-1px', // Slightly reduce the margin to compensate for the transform
              zIndex: 1, // Ensure the scaled item is above others if they overlap
            },
            transition: 'all 0.3s ease-in-out', // Include margin in the transition for a smooth effect
          }}
        >
          <Grid item xs={12}>
            <StyledGridDefaultTypography>Name</StyledGridDefaultTypography>
            <StyledGridTypography>{org.name}</StyledGridTypography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default MyOrganizations
