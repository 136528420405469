export const createDealTimelineData = (activeUser, action, type, details, message) => {
  return {
    dataset: 'deals',
    user: {
      id: activeUser?.user?.id || activeUser?.id || '',
      name: activeUser?.real_name,
      image: activeUser?.user?.profile?.image_512,
    },
    action: action,
    type: type,
    details: details,
    message: message,
  }
}

export const updateDealTimelineData = (id, activeUser, action, type, details, message) => {
  return {
    id: id,
    timeline_uuid: id,
    dataset: 'deals',
    user: {
      id: activeUser?.user?.id || activeUser?.id || '',
      name: activeUser?.real_name,
      image: activeUser?.profile?.image_512 || activeUser?.user?.profile?.image_512,
    },
    action: action,
    type: type,
    details: details,
    message: message,
  }
}
export const updateContractTimelineData = (id, activeUser, action, type, details, message) => {
  return {
    id: id,
    timeline_uuid: id,
    dataset: 'deals',
    user: {
      id: activeUser?.user?.id || activeUser?.id || '',
      name: activeUser?.real_name || activeUser?.user?.real_name || activeUser?.user?.profile?.real_name,
      image: activeUser?.profile?.image_512 || activeUser?.user?.profile?.image_512,
    },
    action: action,
    type: type,
    details: details,
    message: message,
  }
}
