import { useQuery } from '@tanstack/react-query'
import { baseUrl } from '../queryMutations/mutationFunctions'
import axios from 'axios'

const getStrategyById = async (endpoint, tableName, id) => {
  try {
    const url = id ? `${baseUrl}${endpoint}/${id}` : `${baseUrl}${endpoint}`
    const response = await axios.get(url, {
      params: id ? {} : { table: tableName }, // Params are only needed for fetching all items
    })
    if (response.status === 204) {
      return { data: id ? {} : [], status: 204 }
    } else if (response.status === 200) {
      return id ? response.data || {} : response.data || []
    }
  } catch (error) {
    console.error('Error fetching strategy data:', error)
    return id ? {} : [] // Return an empty array in case of an error
  }
}

export const useGetStrategyById = (endpoint, tableName, id = null) => {
  return useQuery({
    queryKey: [endpoint, id], // Use endpoint and id as the query key
    queryFn: () => getStrategyById(endpoint, tableName, id),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 10, // Cache data for 10 minutes
    enabled: !!id,
  })
}
