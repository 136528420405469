import React, { useState } from 'react'
import { Box, Grid, IconButton, InputLabel, Typography, useTheme } from '@mui/material'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'
import CampaignIcon from '@mui/icons-material/Campaign'
import { Link, useParams } from 'react-router-dom'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import EditModeButtons from './EditModeButtons'
import RenderServiceIcons from '../RenderServiceIcons/RenderServiceIcons'
import { headerGridStyle } from './style'

const RenderCampaignHeader = ({ service, handleSave, onAddCampaign }) => {
  const theme = useTheme()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        p: 1,
        bgcolor: theme.palette.background.section,
      }}
    >
      <Grid item xs={12} p={1}>
        <EditModeButtons handleSave={handleSave} onAddCampaign={onAddCampaign} />
      </Grid>
      <Grid container sx={headerGridStyle(theme)}>
        <Grid
          item
          xs={4}
          sx={{ display: 'flex', flexDirection: 'column' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box sx={{ position: 'relative' }}>
            <InputLabel
              sx={{
                fontSize: 12,
                opacity: isHovered ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',
              }}
            >
              {isHovered ? `${service.instanceId}` : ''}
            </InputLabel>
            <InputLabel
              sx={{
                fontSize: 12,
                opacity: !isHovered ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',
              }}
            >
              {!isHovered ? 'Service' : ''}
            </InputLabel>
          </Box>
          <RenderServiceIcon service={service.productName} />
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <InputLabel sx={{ fontSize: 12 }}>Payer</InputLabel>
          <Typography>{service.payer || '—'}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <InputLabel sx={{ fontSize: 12 }}>Account Used</InputLabel>
          <Typography>{service.accountUsed || '—'}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <InputLabel sx={{ fontSize: 12 }}>Media Spend</InputLabel>
          <Typography>{formatCurrency(service.mediaSpend)}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <InputLabel
            sx={{
              fontSize: 12,
            }}
          >
            Price
          </InputLabel>
          <Typography
            sx={{ color: service.payer === 'Client' ? theme.palette.text.warning : theme.palette.text.primary }}
          >
            {formatCurrency(service.price)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RenderCampaignHeader
