import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import StarterKit from '@tiptap/starter-kit'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import CharacterCount from '@tiptap/extension-character-count'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import './style.css'
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuButtonBulletedList,
  MenuButtonOrderedList,
  RichTextEditor,
} from 'mui-tiptap'

const editorExtensions = [
  StarterKit.configure({
    bulletList: false,
    orderedList: false,
    listItem: false,
  }),
  BulletList,
  ListItem,
  OrderedList,
  TextStyle,
  Color,
  Highlight,
  CharacterCount.configure({
    limit: 5000,
  }),
]

const RichTextField = ({ content, onContentChange, onBlur }) => {
  const [characterCount, setCharacterCount] = useState(0)

  useEffect(() => {
    onContentChange(content)
  }, [content, onContentChange])

  useEffect(() => {
    if (content === '') {
      setCharacterCount(0)
    }
  }, [content])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        textAlign: 'left',
        '& .MuiCollapse-root': {
          margin: 0,
        },
        '& .MuiMenuBar-root': {
          margin: 0,
        },
      }}
    >
      <RichTextEditor
        className='rich-text full-width-toolbar'
        extensions={editorExtensions}
        content={content}
        onUpdate={({ editor }) => {
          setCharacterCount(editor.storage.characterCount.characters())
          onContentChange(editor.getHTML())
        }}
        onBlur={onBlur}
        renderControls={() => (
          <MenuControlsContainer onClick={e => e.stopPropagation()}>
            <MenuSelectHeading />
            <MenuDivider />
            <MenuButtonBold />
            <MenuButtonItalic />
            <MenuDivider />
            <MenuButtonBulletedList />
            <MenuButtonOrderedList />
          </MenuControlsContainer>
        )}
        editorProps={{
          attributes: {
            style: 'width: 100%; text-align: left;', // Ensure the editor stretches fully across the container
          },
        }}
      />
    </Box>
  )
}

export default RichTextField
