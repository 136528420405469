import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Typography, Box, Tooltip } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useTheme } from '@mui/material/styles'
import useSaveDeal from '../../api/customHooks/useSaveDeal'
import useTotals from '../../api/customHooks/useTotals'
import ContractTotals from '../ContractDialog/ContractTotals'
import { SaveButton, CreateButton } from './style'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { dialogStyles } from './style'
import { useCreateDocument } from '../../api/PandaDoc/useCreateDocument'
import { useUpdateDeal } from '../../api/aws/useUpdateDeal'
import { BoxScroller } from '../../style/styleElements'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import WarningIcon from '@mui/icons-material/Warning'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import DetailsLoading from '../Loading/DetailsLoading'
import { useActiveUser } from '../../api/slack/useActiveUser'
import { determineApprovalState, handleEditDealState } from '../DealApproval/determineApprovalState'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import isEqual from 'lodash.isequal'
import ContractHeaderComponent from '../DialogHeader/ContractHeaderComponent'
import ProductRowsTable from '../ProductRows/ProductRowsTable'
import { useDeal } from '../../api/aws/useDeal'
import ApprovalRequestButton from '../ui/approval-request-button'
import { updateContractTimelineData, updateDealTimelineData } from '../Timeline/createTimelineData'
import { useCreateTimeline } from '../../api/aws/useCreateTimeline'
import { useApproversById } from '../../api/customHooks/useApproversById'
import { determineApproverIds } from '../../context/DealsDetailsProvider/determineApprovers'
import { useUser } from '../../api/aws/useUser'

const EditContractDialog = ({ open, onClose }) => {
  const theme = useTheme()
  const {
    newDeal,
    setNewDeal,
    validateFields,
    validationErrors,
    productRows,
    setProductRows,
    isValid,
    setIsValid,
    addRow,
    removeRow,
    handleProductChange,
    handleChange,
    handleRequestApproval,
    originalApprovalState,
    setApprovalState,
    onDragEnd,
  } = useDealDetailsContext()
  const [isCreating, setIsCreating] = useState(false)
  const [isError, setIsError] = useState(false)
  const csaTypes = ['CSA', 'RCSA']
  const { mutate: createTimelineEntry } = useCreateTimeline()
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal?.type)
  const { showSnackbar } = useSnackbarContext()
  const { mutate: saveDeal } = useSaveDeal()
  const { mutate: createDocument } = useCreateDocument()
  const { mutate: updateDeal } = useUpdateDeal()
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : []
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const {
    activeUser: { user: activeUser },
  } = useActiveUser()
  const [isRequesting, setIsRequesting] = useState(false)
  const { approversById, isApproversByIdLoading, isApproversByIdError } = useApproversById(
    newDeal ? determineApproverIds(newDeal) : []
  )
  const { user: requestor, isLoading: isRequestorLoading, isError: isRequestorError } = useUser(activeUser.id)

  // useEffect(() => {
  //   setNewDeal(prevDetails => ({
  //     ...prevDetails,
  //     totals,
  //   }))
  // }, [totals])
  // Re-run when deal type or noSignature changes
  const handleSave = event => {
    event.preventDefault()
    setIsCreating(true)
    const dealData = {
      ...newDeal,
      typeChange: false,
      products: productRows,
      status: !newDeal.hasProducts
        ? 'Draft'
        : newDeal.hasProducts && newDeal.status === 'Draft'
        ? 'Saved'
        : newDeal.status,
      stage: !newDeal.hasProducts
        ? 'Discovery'
        : newDeal.hasProducts && newDeal.stage === 'Discovery'
        ? 'Proposal'
        : newDeal.stage,
      lastModified: new Date(),
    }
    console.log('[EditContractDialog] saving new deal...', dealData)
    const timelineData = updateContractTimelineData(
      newDeal?.deal_uuid,
      activeUser,
      'Save',
      'deals',
      `Contract saved by ${
        activeUser?.real_name || activeUser?.user?.real_name || activeUser?.user?.profile?.real_name
      }`
    )
    // Use handleEditDealState to calculate the deal state before saving
    saveDeal(
      {
        dealId: newDeal.deal_uuid,
        dealData: dealData,
      },
      {
        onSuccess: () => {
          setIsCreating(false)
          createTimelineEntry(timelineData)
          onClose()
        },
        onError: () => {
          setIsCreating(false)
          setIsError(true)
        },
      }
    )
  }
  const handleApprovalRequest = (requestor, activeUser, approvers) => {
    console.log('requestor', requestor, 'activeUser', activeUser, 'approvers', approvers)
    console.log('Approval Requested!!!!!!')
    try {
      const dealData = {
        ...newDeal,
        isRequested: true,
        requestedBy: activeUser && activeUser.user ? activeUser.user.id : activeUser.id,
        approvers: approvers,
        status: 'Approval Requested',
        requestedTime: new Date(),
        requestor: requestor
          ? {
              id: requestor.id,
              name: requestor.name,
              email: requestor.email,
              image: requestor.image_512,
              hasRequested: true,
            }
          : {},
      }
      console.log('DEAL DATA: ', dealData)
      const timelineData = updateDealTimelineData(
        newDeal?.deal_uuid,
        activeUser,
        'Request',
        'Approval',
        `deal approval requested by ${activeUser?.name}`
      )
      handleRequestApproval(requestor, activeUser, approvers)
      createTimelineEntry(timelineData)
      setNewDeal({ ...dealData })
      onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const handleRequest = () => {
    try {
      handleApprovalRequest(requestor, activeUser, approversById)
    } catch (e) {
      console.error('Error handling request from edit dialog')
    }
  }
  const submitContract = (newDeal, organization, activeUser) => {
    try {
      const documentData = {
        deal: newDeal,
        organization,
        activeUser,
      }

      createDocument(documentData, {
        onSuccess: data => {
          const contractId = data.id
          const dealWithContractId = {
            ...newDeal,
            contractId: contractId,
            status: 'Created',
            stage: 'Contract Created',
            lastModified: new Date(),
            isCreated: true,
          }
          updateDeal(
            {
              dealId: dealWithContractId.deal_uuid,
              dealData: dealWithContractId,
            },
            {
              onSuccess: () => {
                setIsCreating(false)
                onClose()
              },
              onError: error => {
                console.error('Error in updateDeal:', error)
                setIsCreating(false)
              },
            }
          )
          showSnackbar('Document successfully created', 'success')
        },
        onError: error => {
          console.error('Error in createDocument:', error)
          setIsCreating(false)
          setIsError(true)
        },
      })
    } catch (error) {
      console.error('Error in submitContract try-catch:', error)
      setIsCreating(false)
      setIsError(true)
    }
  }
  const handleSubmit = event => {
    event.preventDefault()
    setIsCreating(true)
    try {
      submitContract({ ...newDeal, creation_timestamp: new Date() }, organization, activeUser)
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setIsCreating(false)
      setIsError(true)
    }
    setIsCreating(false)
  }

  useEffect(() => {
    if (open) {
      console.log('New Deal Open: ', newDeal)
      const isValid = validateFields(newDeal)
      setIsValid(isValid)
    }
  }, [open, newDeal])
  const onChange = (fieldName, value) => {
    handleChange(fieldName, value, organization)
  }
  if (isOrganizationLoading) {
    return <DetailsLoading />
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='xl'
      PaperProps={{
        sx: dialogStyles(theme),
      }}
    >
      <ContractHeaderComponent
        newDeal={newDeal}
        setNewDeal={setNewDeal}
        handleChange={onChange}
        organization={organization}
        validationErrors={validationErrors}
      />
      <BoxScroller
        sx={{
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(167,51,51,0.65)' : 'rgba(167,51,51,0.65)',
            borderRadius: '6px',
          },
        }}
      >
        <DialogContent>
          <ProductRowsTable
            productRows={productRows}
            onDragEnd={onDragEnd}
            onProductChange={handleProductChange}
            validationErrors={validationErrors}
            removeRow={removeRow}
          />
        </DialogContent>
      </BoxScroller>
      <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        <Button
          sx={{
            display: 'flex',
            borderRadius: '4px',
            width: '10%',
            '&:hover': {
              opacity: '80%',
            },
          }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={addRow}
        >
          Add Service
        </Button>
      </Box>
      <DialogActions sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <ContractTotals
          newDeal={newDeal}
          setNewDeal={setNewDeal}
          totals={totals}
          onChange={handleChange}
          organization={organization}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', marginRight: -4 }} gap={1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Box sx={{ display: 'flex', gap: 1, maxHeight: '48px' }}>
              {isCreating && (
                <CircularProgress
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
                  }}
                />
              )}
              {isError && <Typography color='error'>Error creating deal</Typography>}
              <Button onClick={onClose} color='primary' variant='outlined'>
                Cancel
              </Button>
              <SaveButton onClick={handleSave} color='secondary' variant='contained'>
                Save
              </SaveButton>
              {newDeal && !newDeal.noSignature && newDeal.isApproved && !newDeal.isCompleted ? (
                <Tooltip
                  title={
                    // Only show the tooltip if the deal needs approval and `canCreateContract` is false
                    newDeal.isApproved && !newDeal.isCompleted ? 'Deal needs approval before creating contract' : ''
                  }
                >
                  <CreateButton
                    onClick={handleSubmit}
                    color='primary'
                    variant='contained'
                    startIcon={<PandaDocImageComponent size={30} fontSize={12} />}
                    sx={{ fontSize: 12 }}
                  >
                    Create Contract
                  </CreateButton>
                </Tooltip>
              ) : (
                <ApprovalRequestButton
                  isRequested={newDeal.isRequested}
                  isRequesting={isRequesting}
                  onRequest={handleRequest}
                  startIcon={true}
                />
              )}
            </Box>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default EditContractDialog
