// src/AppLayout.js
import React, { useState, useContext } from 'react'
import { Box, AppBar, Toolbar, useTheme, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ContainerProvider } from './context/ContainerProvider/ContainerProvider'
import CreateOrganization from './components/CreateOrganization/CreateOrganization'
import CreateDeal from './components/CreateDeal/CreateDeal'
import CreateContact from './components/CreateContact/CreateContact'
import MiniDrawer from './components/AppSidebar/MiniDrawer'
import { useLocation } from 'react-router-dom'
import headerMap from './components/Headers/headerMap'
import { HeaderContext } from './context/HeaderProvider/HeaderProvider'
import { useActiveUser } from './api/slack/useActiveUser'
import { DealDetailsProvider } from './context/DealsDetailsProvider/DealsDetailsProvider'
import { initialDealState } from './context/DealsDetailsProvider/initialDealState'

// const MainContent = styled('main', {
//   shouldForwardProp: prop => prop !== 'open',
// })(({ theme, open }) => ({
//   flexGrow: 1,
//   transition: theme.transitions.create('margin', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   overflowY: 'auto',
//   marginLeft: open ? 260 : 0, // Drawer width is 260, adjust margin when drawer is open
//   marginTop: theme.spacing(9), // Adjust top margin based on your header height
// }))

const MainContent = styled('main', {
  shouldForwardProp: prop => !['open'].includes(prop),
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // overflowY: 'hidden',
  marginLeft: 0,
  ...(open && {
    marginLeft: 20,
    marginRight: 20,
  }),
  // marginTop: theme.spacing(9),
}))
// const MainContent = styled('main')(({ theme }) => ({
//   flexGrow: 1,
//   // padding: theme.spacing(3),
//   maxHeight: '100vh',
//   overflow: 'hidden', // Prevent the whole app from scrolling, only content scrolls
//   marginTop: theme.spacing(8),
// }))

const AppLayout = ({ children }) => {
  const [isCreateOrganizationOpen, setIsCreateOrganizationOpen] = useState(false)
  const [isCreateDealOpen, setIsCreateDealOpen] = useState(false)
  const [isCreateContactOpen, setIsCreateContactOpen] = useState(false)
  const [isNotifyDrawerOpen, setIsNotifyDrawerOpen] = useState(false)
  const [isConnectedUsersOpen, setIsConnectedUsersOpen] = React.useState(false)

  const handleOpenCreateOrganization = () => setIsCreateOrganizationOpen(true)
  const handleCloseCreateOrganization = () => setIsCreateOrganizationOpen(false)

  const handleOpenCreateDeal = () => setIsCreateDealOpen(true)
  const handleCloseCreateDeal = () => setIsCreateDealOpen(false)

  const handleOpenCreateContact = () => setIsCreateContactOpen(true)
  const handleCloseCreateContact = () => setIsCreateContactOpen(false)

  const handleOpenNotifyDrawer = () => setIsNotifyDrawerOpen(true)
  const handleCloseNotifyDrawer = () => setIsNotifyDrawerOpen(false)

  const handleOpenConnectedUsers = () => setIsConnectedUsersOpen(true)
  const handleCloseConnectedUsers = () => setIsConnectedUsersOpen(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const location = useLocation()

  const { organizationInfo, dealInfo, contactInfo } = useContext(HeaderContext)
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const pathsWithAppBar = [
    '/dashboard',
    '/deals',
    '/deals/kanban',
    '/organizations',
    '/contacts',
    '/proposals',
    '/prospects',
    '/leads',
    '/pandadocs',
    '/sprints',
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        overflow: 'hidden', // Prevent scrolling the entire layout
        backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
      }}
    >
      {/* <ContainerProvider> */}
      <DealDetailsProvider dealData={initialDealState}>
        <MiniDrawer
          handleOpenCreateOrganization={handleOpenCreateOrganization}
          handleOpenCreateDeal={handleOpenCreateDeal}
          handleOpenCreateContact={handleOpenCreateContact}
          isNotifyDrawerOpen={isNotifyDrawerOpen}
          onOpenNotify={handleOpenNotifyDrawer}
          onCloseNotify={handleCloseNotifyDrawer}
          isConnectedUsersOpen={isConnectedUsersOpen}
          handleOpenConnectedUsers={handleOpenConnectedUsers}
          handleCloseConnectedUsers={handleCloseConnectedUsers}
        />
        {/* {activeUser && activeUser.user && adminUsers.includes(activeUser.user.id) && <ConnectedUsers />} */}
        <MainContent>
          {children}
          <CreateOrganization open={isCreateOrganizationOpen} onClose={handleCloseCreateOrganization} />
          <CreateDeal open={isCreateDealOpen} onClose={handleCloseCreateDeal} />
          <CreateContact open={isCreateContactOpen} onClose={handleCloseCreateContact} />
        </MainContent>
        {/* </ContainerProvider> */}
      </DealDetailsProvider>
    </Box>
  )
}

export default AppLayout
