import React from 'react'
import { Link } from 'react-router-dom'
import { Box, useTheme, IconButton, Typography, Button } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import CancelIcon from '@mui/icons-material/Cancel'
import EditStrategyButton from '../ui/edit-strategy-button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import { iconButtonSyles, cancelIconStyles } from './style'
import AddIcon from '@mui/icons-material/Add'

const EditModeButtons = ({ handleSave, onAddCampaign }) => {
  const theme = useTheme()
  const { newDeal, isEditMode, handleStartEdit, handleStopEdit } = useDealDetailsContext()

  const handleNavigateBack = () => {
    handleStopEdit()
  }

  return (
    <>
      {isEditMode ? (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'right',
            width: '100%',
            height: '100%',
            pl: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'left', width: '100%', gap: 1 }}>
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: 22,
                height: '100%',
                alignContent: 'center',
              }}
            >
              {'Campaigns (Edit Mode)'}
            </Typography>
            <IconButton sx={{ height: 45, width: 45 }} onClick={onAddCampaign}>
              <AddIcon />
            </IconButton>
          </Box>
          <IconButton onClick={() => handleStopEdit()} sx={iconButtonSyles}>
            <CancelIcon sx={cancelIconStyles} />
            <Typography>Cancel</Typography>
          </IconButton>
          <IconButton
            onClick={handleSave}
            sx={{
              gap: 2,
              borderRadius: 0,
              color: theme.palette.text.secondary,
              '&:hover': {
                color: theme.palette.text.primary,
                fontWeight: 'bold',
              },
              '&:hover .MuiSvgIcon-root': {
                color: 'rgb(50, 187, 130)',
              },
            }}
          >
            <TaskAltIcon
              sx={{
                height: 30,
                width: 30,
                color: theme.palette.text.secondary,
              }}
            />
            <Typography>Save</Typography>
          </IconButton>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignContent: 'center', pl: 1 }}>
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'left', width: '100%', gap: 1 }}>
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: 22,
                height: '100%',
                alignContent: 'center',
              }}
            >
              Campaigns
            </Typography>
            <IconButton sx={{ height: 45, width: 45 }} onClick={onAddCampaign}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'right', width: '100%', gap: 2 }}>
            <Link
              to={`/deals/${newDeal?.deal_uuid}/strategy`}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                backgroundColor: 'transparent',
                display: 'flex',
                alignContent: 'center',
                height: '100%',
              }}
            >
              <IconButton onClick={() => handleNavigateBack()} sx={iconButtonSyles}>
                <ArrowBackIcon
                  sx={{
                    height: 30,
                    width: 30,
                    gap: 2,
                    borderRadius: 0,
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      color: theme.palette.text.primary,
                      fontWeight: 'bold',
                    },
                    '&:hover .MuiSvgIcon-root': {
                      color: 'rgb(50, 187, 130)',
                    },
                  }}
                />
                <Typography>Back</Typography>
              </IconButton>
            </Link>
            <EditStrategyButton color={'primary'} variant='contained' onEdit={() => handleStartEdit()} />
          </Box>
        </Box>
      )}
    </>
    // {/* </Box> */}
  )
}

export default EditModeButtons
