import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  circularProgressClasses,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { getGridStringOperators } from '@mui/x-data-grid-pro'
import { slugify } from '../../../helperFunctions/slugify'
import { formatDayjsDate } from './renderDealsColumns'
import UserImageComponent from '../../Users/UserImageComponent'
import { getDensityStyles, userImageStyles } from '../style'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import RenderApprovers from '../../Approvals/RenderApprovers'
import DoneIcon from '@mui/icons-material/Done'
import { RenderApproverStatus } from '../../Approvals/RenderApprover'

const getStatusColor = progress => {
  if (progress === 1) {
    return 'rgb(6, 255, 167, 0.6)'
  } else if (progress === 2) {
    return 'rgba(220, 20, 60, 0.9)'
  } else if (progress === 3) {
    return 'rgb(249, 167, 49)' // Resolved or pending
  } else {
    return 'rgb(211, 211, 211)' // Pending or no action taken
  }
}

// Tooltip text for each status
const getStatusText = progress => {
  if (progress === 1) {
    return `Approved`
  } else if (progress === 2) {
    return `Rejected`
  } else if (progress === 3) {
    return `Resolved`
  } else {
    return `has not taken any action yet`
  }
}

export const renderApprovalsColumns = (deals, users, theme, density = 'standard') => {
  const densityStyles = getDensityStyles(density)
  const containsOperator = getGridStringOperators().find(op => op.value === 'contains')

  const customContainsApprover = {
    label: 'contains approver',
    value: 'contains',
    getApplyFilterFn: filterItem => {
      if (!filterItem.value) {
        return null
      }
      return params => {
        // Access approver names and check for the active user's name
        const approvers = params?.value?.approvers || []
        const approverNames = approvers.map(approver => approver.name)
        return approverNames.includes(filterItem.value)
      }
    },
  }

  // Custom operator that wraps the 'contains' logic to invert it
  const customNotContainsOperator = {
    ...containsOperator,
    label: 'does not contain',
    value: 'notContains',
    getApplyFilterFn: filterItem => {
      if (filterItem.value === undefined || filterItem.value === null) return null

      const containsFn = containsOperator.getApplyFilterFn({ ...filterItem, value: filterItem.value.toString() })

      return cellValue => !containsFn(cellValue ? cellValue.toString() : '')
    },
  }

  return [
    {
      field: 'name',
      headerName: 'Deal Name',
      width: 100,
      flex: 1.0,
      headerAlign: 'left',
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        const isApproved = params && params.row && params.row.isApproved
        const isRequested = params && params.row && params.row.isRequested
        const dealId = params.row.id
        return (
          <Link
            to={`/deals/${dealId}/reviewdeal`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              backgroundColor: 'transparent',
            }}
          >
            <Tooltip
              title={<Typography sx={{ backgroundColor: 'transparent' }}>{params.value}</Typography>}
              enterDelay={2000}
              sx={{
                backgroundColor: 'transparent', // Transparent background for tooltip
                boxShadow: 'none', // Remove box shadow
                '.MuiTooltip-tooltip': {
                  backgroundColor: 'transparent', // Make tooltip content background transparent
                  boxShadow: 'none', // Remove box shadow around tooltip
                  padding: 0, // Remove padding if you want only the text to be visible
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {isApproved ? (
                  <Typography
                    sx={{ fontSize: theme.palette.text.size, color: theme.palette.text.secondary, textAlign: 'left' }}
                  >
                    {params.value}
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: theme.palette.text.size, color: theme.palette.text.primary, textAlign: 'left' }}
                  >
                    {params.value}
                  </Typography>
                )}
              </Box>
            </Tooltip>
          </Link>
        )
      },
    },
    {
      field: 'progressPercentage',
      headerName: 'Progress',
      width: 60,
      flex: 0.5,
      valueGetter: params => {
        return params
      },
      filterOperators: [...getGridStringOperators(), customNotContainsOperator],
      renderCell: params => {
        // const { progressPercentage, progress } = params.row
        const progressPercentage = params.value

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <LinearProgress
              variant='determinate'
              value={progressPercentage}
              sx={{
                width: '100%',
                height: 25,
                borderRadius: 5,
                backgroundColor: 'lightgray',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: progressPercentage === 100 ? 'rgb(6, 255, 167, 0.6)' : 'rgba(45, 85, 255, 1)',
                },
              }}
            />
            <Box
              sx={{ position: 'absolute', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Typography sx={{ fontSize: '0.75rem', color: theme.palette.text.chip, fontWeight: 'bold' }}>
                {Math.round(progressPercentage)}%
              </Typography>
            </Box>
          </Box>
        )
      },
    },
    {
      field: 'requestedTime',
      headerName: 'Time Requested',
      width: 100,
      flex: 0.5,
      valueGetter: params => {
        return params
      },
      renderCell: params => {
        return (
          <Box>
            {params && params.value ? formatDayjsDate(params && params.value ? params.value : 'No Modified Time') : '—'}
          </Box>
        )
      },
    },
    {
      field: 'requestedBy',
      headerName: 'Requested By',
      width: 100,
      flex: 0.5,
      valueGetter: params => {
        const userId = params ? params : null

        const user = users.find(u => u.id === userId)
        return userId ? userId : null
      },
      renderCell: params => {
        const userId = params && params.value ? params.value : null

        const user = users.find(u => u.id === userId)
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: densityStyles.padding,
              gap: densityStyles.gap,
            }}
          >
            {user ? (
              <UserImageComponent
                style={userImageStyles}
                imageUrl={user.image_512}
                width={densityStyles.avatarSize}
                height={densityStyles.avatarSize}
              />
            ) : (
              <Box
                sx={{
                  width: densityStyles.avatarSize,
                  height: densityStyles.avatarSize,
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: densityStyles.fontSize,
                }}
              >
                <IconButton disabled>
                  <Avatar />
                </IconButton>
              </Box>
            )}
          </Box>
        )
      },
    },
    {
      field: 'approvers',
      headerName: 'Approvers',
      width: 100,
      flex: 1,
      filterOperators: [customContainsApprover],
      valueGetter: params => {
        const approvers = params || []
        const approverIds = approvers.map(approver => approver.id)
        return { approvers, approverIds }
      },
      renderCell: params => {
        const approvers = params?.value?.approvers
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
            {approvers &&
              approvers.map(approver => (
                <Badge
                  key={approver.id}
                  badgeContent={<RenderApproverStatus approver={approver} />}
                  color='secondary'
                  sx={{ '& .MuiBadge-badge': { fontSize: 10, height: 14, minWidth: 12, bgcolor: 'transparent' } }}
                >
                  <Avatar alt={approver.name} src={approver.image} sx={{ width: 36, height: 36 }} />
                </Badge>
              ))}
          </Box>
        )
      },
    },
  ]
}
