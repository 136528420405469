import React from 'react'
import { Box, useTheme, Button, Typography, Tooltip, Chip, IconButton } from '@mui/material'
import MassUpdateVertical from '../MassUpdateComponents/MassUpdateVertical'
import MassUpdateTypeSelect from '../MassUpdateComponents/MassUpdateTypeSelect'
import DeleteIcon from '@mui/icons-material/Delete'
import { deleteButtonStyles, deleteIconStyles } from './style'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { GridFooterContainer, GridRowCount, Ro } from '@mui/x-data-grid-pro'
import SprintsPopover from './SprintsPopover'

const orgTypeOptions = [
  { label: 'Property Management Company', type: 'PMC', color: 'rgb(93, 79, 251)' },
  { label: 'Ownership Group', type: 'OG', color: 'rgb(92, 179, 253)' },
  { label: 'Housing Property', type: 'HP', color: 'rgb(152, 214, 129)' },
  { label: 'Parent Client', type: 'PC', color: 'rgb(93, 79, 251)' },
  { label: 'Agency Whitelabel', type: 'AW', color: 'rgb(92, 179, 253)' },
  { label: 'Organization', type: 'ORG', color: 'rgb(242, 140, 40)' },
]
const tooltipGridFooter = title => {
  return <Typography>{title}</Typography>
}
export const SprintsGridFooter = ({
  selectionModel = [],
  rowCount,
  onUpdate,
  sprints = [],
  anchorEl,
  handleDeleteClick,
  handleConfirmDelete,
  handleClosePopover,
  selectedData,
  selectedDiscoveryForm,
}) => {
  const theme = useTheme()
  const open = Boolean(anchorEl)
  const id = open ? 'grid-footer-popover' : undefined
  return (
    <GridFooterContainer>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
      <>
        {selectionModel.length > 0 ? (
          <Box
            sx={{
              maxHeight: 60,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
              p: 3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant='h8'>{selectionModel.length === 1 ? 'Edit' : 'Update'}</Typography>
              <Chip label={selectionModel.length} sx={{ color: 'rgb(24, 144, 255)', fontSize: 18 }} />
              <Typography variant='h8'> {selectionModel.length === 1 ? 'Sprint' : 'Sprints'}</Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {selectedData && selectionModel.length === 1 && (
                <>
                  <Tooltip title={tooltipGridFooter('Delete')} placement='top'>
                    <IconButton sx={deleteIconStyles(theme)} color='inherit' onClick={handleDeleteClick}>
                      <DeleteIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Tooltip
                title={
                  selectionModel.length === 1
                    ? 'Update functionality in progress...'
                    : 'Mass update functionality in progress...'
                }
                placement='top'
              >
                <Box>
                  <Button
                    color='secondary'
                    variant='contained'
                    onClick={onUpdate}
                    // disabled={selectionModel.length === 0}
                    disabled={true}
                    sx={{ height: 40 }}
                  >
                    {selectionModel.length === 1 ? 'Update' : 'Mass Update'}
                  </Button>
                </Box>
              </Tooltip>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              padding: 1,
              alignItems: 'center',
              minHeight: 50,
              bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
              gap: 1,
            }}
          >
            <Typography variant='body2' mr={2}>
              Total Rows:
            </Typography>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(89, 224, 158,0.8)' : 'rgba(0,0,0,0.6)' }}>
              {rowCount}
            </Typography>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              /
            </Typography>
            <Typography sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)' }}>
              {sprints.length}
            </Typography>
          </Box>
        )}
        <SprintsPopover
          id={id}
          open={open}
          onClose={handleClosePopover}
          handleConfirmDelete={handleConfirmDelete}
          selectedData={selectedData}
        />
      </>
    </GridFooterContainer>
  )
}
