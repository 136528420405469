import { v4 as uuidv4 } from 'uuid'

export const createNoteData = (newNoteContent, activeUser) => {
  return {
    note_id: uuidv4(),
    contents: newNoteContent,
    owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
    createdAt: new Date().toISOString(),
  }
}
