import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Button, CircularProgress, IconButton, Badge } from '@mui/material'
import { fetchViewLink } from '../../api/customHooks/fetchViewLink'
import PandaDocLogo from '../../images/pandadoc.png'
import FileOpenIcon from '@mui/icons-material/FileOpen'

const PandaDocViewer = ({ contractId }) => {
  const [open, setOpen] = useState(false)
  const [blobUrl, setBlobUrl] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleClickOpen = async () => {
    setLoading(true)
    const url = await fetchViewLink(contractId)
    setBlobUrl(url)
    setLoading(false)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setBlobUrl(null) // Clean up the Blob URL when closing
  }

  return (
    <>
      <IconButton color='inherit' onClick={handleClickOpen} sx={{ ml: 2 }}>
        <Badge badgeContent={<img style={{ width: 20, borderRadius: 25 }} src={PandaDocLogo} />}>
          <FileOpenIcon sx={{ width: 40, height: 40 }} />
          {/* <img style={{ width: size ? size : 40, borderRadius: 25 }} src={PandaDocLogo} /> */}
        </Badge>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth='lg' fullWidth>
        <DialogTitle>Document Preview</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : blobUrl ? (
            <iframe src={blobUrl} width='100%' height='800px' title='PandaDoc Document' style={{ border: 'none' }} />
          ) : (
            <p>Error loading document.</p>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PandaDocViewer
