import React from 'react'
import { Button } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'

const ApprovalRequestButton = ({ isRequested, isRequesting, onRequest, startIcon }) => {
  return (
    <Button
      sx={{
        width: '100%',
        backgroundColor: 'rgb(5, 190, 113)',
      }}
      disabled={isRequesting || isRequested}
      onClick={onRequest}
      name='isApproved'
      variant='contained'
      startIcon={startIcon ? <WarningIcon sx={{ color: 'crimson' }} /> : null}
    >
      {!isRequested ? 'Request Approval' : 'Pending Approval'}
    </Button>
  )
}

export default ApprovalRequestButton
