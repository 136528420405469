import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, Chip, Box, Popper } from '@mui/material'

const verticalOptions = [
  'Construction',
  'Tourism',
  'Marketing Agency',
  'Manufacturing',
  'Entertainment',
  'Mortgage',
  'Real Estate',
  'Restaurant (Food & Beverage)',
  'City/Government',
  'Financial Institution',
  'Technology',
  'Medical',
  'Lawyer',
  'Education',
  'Student',
  'Multifamily',
  'Senior',
  'Homebuilding',
  'Build to rent',
  'Other',
]

const CustomPopper = props => (
  <Popper
    {...props}
    placement='top'
    modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
    style={{ width: props.anchorEl ? props.anchorEl.clientWidth : null, zIndex: 2000 }}
  />
)

const EditVerticalAutocomplete = ({ name, value, onChange, onBlur, hasDeals }) => {
  const [selectedValues, setSelectedValues] = useState([])

  // Handle value prop and ensure it is always an array
  useEffect(() => {
    if (Array.isArray(value)) {
      setSelectedValues(value.filter(v => v !== 'No Vertical Assigned'))
    } else if (value) {
      // If value is a single item, wrap it in an array
      setSelectedValues([value])
    } else {
      // If value is null or undefined, set an empty array
      setSelectedValues([])
    }
  }, [value])

  const handleSelectionChange = (event, newValues) => {
    setSelectedValues(newValues)
  }

  const handleBlur = () => {
    // Prevent triggering update if no changes were made
    if (JSON.stringify(value) !== JSON.stringify(selectedValues)) {
      onChange({ target: { name, value: selectedValues } })
      onBlur({ target: { name, value: selectedValues } })
    }
  }

  return (
    <Box sx={{ minWidth: 300, width: 'auto' }}>
      <Autocomplete
        multiple
        options={verticalOptions}
        value={selectedValues}
        onChange={handleSelectionChange}
        onBlur={handleBlur}
        isOptionEqualToValue={(option, value) => option === value}
        PopperComponent={CustomPopper}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...chipProps } = getTagProps({ index })
            return <Chip key={key} {...chipProps} label={option} variant='outlined' />
          })
        }
        renderInput={params => <TextField {...params} variant='outlined' placeholder='Select Vertical' />}
        disabled={hasDeals}
      />
    </Box>
  )
}

export default EditVerticalAutocomplete
