import { Box, Grid, Avatar, Typography, InputLabel, Drawer, Tooltip, Icon } from '@mui/material'
import { formatDayjsDate, formatDayJsTime } from './renderDealsColumns'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { useState } from 'react'
import TimelineType from '../../Timeline/TimelineType'
import UpdateIcon from '@mui/icons-material/Update'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'

export const renderTimelineText = action => {
  switch (action) {
    case 'Approve':
    case 'approve':
      return 'Approve'
    case 'Resolve':
    case 'resolve':
      return 'Resolve'
    case 'Reject':
    case 'reject':
      return 'Reject'
    case 'Request':
    case 'request':
      return 'Request'
    case 'Creation':
    case 'creation':
    case 'Create':
      return 'Creation'
    case 'Approval':
    case 'approval':
      return 'Approval'
    case 'Deals':
    case 'deals':
      return 'Deals'
    case 'Save':
      return 'Save'
    case 'Update':
      return 'Update'
    case 'Update':
    case 'update':
    case 'productUpdate':
      return 'Update'
    case 'Product Update':
      return 'Product Update'
  }
}
export const renderTimelineIcons = action => {
  switch (action) {
    case 'Approval':
    case 'approval':
      return <ThumbUpOffAltIcon />
    case 'Deals':
    case 'deals':
      return <AttachMoneyIcon />
    case 'Update':
    case 'update':
      return <UpdateIcon />
    case 'productUpdate':
      return <ShoppingCartIcon />
  }
}
export const renderTimelineColor = action => {
  switch (action) {
    case 'Approve':
    case 'approve':
      return 'rgb(6, 255, 167, 0.6)'
    case 'Resolve':
    case 'resolve':
      return 'rgb(249, 167, 49)'
    case 'Reject':
    case 'reject':
      return 'rgba(220, 20, 60, 0.9)'
    case 'Request':
    case 'request':
      return 'rgb(233, 136, 103)'
    case 'Creation':
    case 'creation':
    case 'Create':
      return 'rgb(25, 117, 255)'
    case 'Save':
      return 'rgb(114, 229, 218)'
    case 'Update':
      return 'rgb(114, 229, 218)'
    case 'Product Update':
      return 'rgb(255, 123, 187)'
  }
}

export const renderTimelineColumns = isHovered => {
  return [
    {
      field: 'user',
      headerName: 'User',
      width: 100,
      flex: 0.2,
      valueGetter: params => params,
      renderCell: params => {
        const user = params.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Tooltip title={user?.name}>
              <Avatar src={user?.image} />
            </Tooltip>
          </Grid>
        )
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.2,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const action = params?.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography sx={{ color: renderTimelineColor(params?.value), fontWeight: 'bold' }}>
              {renderTimelineText(action)}
            </Typography>
          </Grid>
        )
      },
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 0.5,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const details = params?.value
        const timestamp = params && params.row && params.row.timestamp ? formatDayJsTime(params.row.timestamp) : null
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {/* <Typography>{`${details} @ ${timestamp}`}</Typography> */}
            <Typography>{`${details}`}</Typography>
          </Grid>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Time',
      flex: 0.3,
      minWidth: 100,
      valueGetter: params => params,
      renderCell: params => {
        const details = params?.value
        const timestamp = params && params.row && params.row.timestamp ? formatDayJsTime(params.row.timestamp) : null
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography>{`${timestamp}`}</Typography>
          </Grid>
        )
      },
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 0.7,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const message = params?.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography>{message ? message : '—'}</Typography>
          </Grid>
        )
      },
    },
    {
      field: 'dataset',
      headerName: 'Data Set',
      flex: 0.2,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const dataset = params?.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography>{dataset}</Typography>
          </Grid>
        )
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 0.2,
      minWidth: 200,
      valueGetter: params => params,
      renderCell: params => {
        const type = params?.value
        return <TimelineType type={type} />
      },
    },

    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 100,
      flex: 1,
      valueGetter: params => params,
      renderCell: params => {
        const timestamp = params?.value
        return (
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Typography>{formatDayjsDate(timestamp)}</Typography>
          </Grid>
        )
      },
    },
  ]
}

export default renderTimelineColumns
