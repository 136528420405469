import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Grid, useTheme } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { gridContainerStyles } from './style.js'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { renderTimelineColumns } from '../DataGrid/createColumns/renderTimelineColumns.js'
import { useTimeline } from '../../api/aws/useTimeline.js'
import { useParams } from 'react-router-dom'

const TimelinePage = () => {
  const { dealId } = useParams()
  const { timeline, isLoading, isError } = useTimeline(dealId)
  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const [selectionModel, setSelectionModel] = useState([])
  const [filterModel, setFilterModel] = useState({ items: [] })
  const apiRef = useGridApiRef()
  const [visibleRowCount, setVisibleRowCount] = useState(timeline ? timeline.length : 0)
  const [rowCount, setRowCount] = useState(timeline ? timeline.length : 0)
  const [anchorEl, setAnchorEl] = useState(null)

  const columns = renderTimelineColumns()
  useEffect(() => {
    const handleFilterModelChange = () => {
      const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
      const filteredRowsCount = Object.values(filteredRowsLookup).filter(isVisible => isVisible).length
      setVisibleRowCount(filteredRowsCount)
    }

    const api = apiRef.current
    const unsubscribe = api.subscribeEvent('filterModelChange', handleFilterModelChange)

    // Initialize the visible row count when the component mounts
    handleFilterModelChange()

    return () => {
      unsubscribe()
    }
  }, [apiRef])

  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }

  const totalRowCount = timeline ? timeline.length : 0

  return (
    <>
      <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 122px)' }}>
        <DataGridPro
          sx={{
            bgcolor: theme.palette.background.timelineGrid,
            '& .MuiDataGrid-cell': {
              '&:hover': {
                transform: 'scale(1.02)',
              },
            },
            '& .MuiDataGrid-row': {
              fontSize: '12px',
              '&:hover': {
                // backgroundColor: 'rgba(87, 125, 173, 0.2)',
                // backgroundColor: 'transparent',
              },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'timestamp', sort: 'desc' }],
            },
            columns: {
              columnVisibilityModel: {
                // timestamp: false,
                dataset: false,
              },
            },
          }}
          rows={timeline || []}
          row
          columns={columns}
          rowHeight={46}
          getRowId={row => `${row.timeline_uuid}-${row.timestamp}` || row.id}
          //   checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          //   onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
          // onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={model => setFilterModel(model)}
          apiRef={apiRef}
          slots={
            {
              // toolbar: CustomGridToolbar,
              // footer: SprintsGridFooter,
            }
          }
          slotProps={
            {
              // toolbar: {
              //   filterModel: filterModel,
              //   setFilterModel: setFilterModel,
              //   context: 'sprints',
              // },
              // footer: {
              //   selectionModel: selectionModel,
              //   totalRowCount: totalRowCount,
              //   visibleRowCount: visibleRowCount,
              //   rowCount: rowCount,
              //   sprints: sprints,
              //   anchorEl: anchorEl,
              //   handleDeleteClick: handleDeleteClick,
              //   handleConfirmDelete: handleConfirmDelete,
              //   handleClosePopover: handleClosePopover,
              //   selectedData: selectedSprint,
              // },
            }
          }
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
          isLoading={isLoading}
        />
      </Box>
    </>
  )
}

export default TimelinePage
