import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import DraggableListItem from './DraggableListItem'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { Box, Chip, Typography, useTheme } from '@mui/material'
import { BoxScroller } from '../../style/styleElements'
import { setStageStyle } from './DealsKanban'

const FlexPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  margin: '0 8px', // add space between columns
  minWidth: 280,
  maxHeight: '100%', // Ensure the column takes up the full height
  display: 'flex',
  flexDirection: 'column',
  border: '2px solid',
  borderColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0)',
  overflowY: 'auto', // Enable vertical scrolling within the column
}))

const Header = styled('h3')(({ label, theme }) => ({
  margin: 0, // Remove any margin
  textAlign: 'center',
  color: 'black',
  padding: '8px 0',
  position: 'sticky',
  top: 0, // Stick the header to the top
  zIndex: 1,
  width: '100%',
  backgroundColor: setStageStyle(label), // Background color to match the column
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)', // Optional: Add bottom border for separation
}))

const KanbanColumn = ({ columnId, items, label, total, grossProfit }) => {
  const theme = useTheme()
  const kanbanBgColor = theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgb(232, 246, 252)'

  return (
    <FlexPaper>
      <Header label={label}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
            <Chip label={items.length} />
          </Box>
          <Typography>{`Total: ${total || '0.00'}`}</Typography>
          <Typography>{`Gross Profit: ${grossProfit || '0.00'}`}</Typography>
        </Box>
      </Header>
      <Box
        sx={{
          overflowY: 'auto',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: kanbanBgColor,
        }}
      >
        <Droppable droppableId={columnId}>
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                minHeight: '50px',
                backgroundColor: snapshot.isDraggingOver ? 'rgba(0,0,0,0.3)' : 'transparent',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {items && items.length > 0 ? (
                items.map((item, index) => <DraggableListItem key={item.id} item={item} index={index} />)
              ) : (
                <Box sx={{ padding: '10px', textAlign: 'center' }}>No items</Box>
              )}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    </FlexPaper>
  )
}

export default KanbanColumn
