import React, { useState, useEffect } from 'react'
import { Box, FormControl, Select, MenuItem, Chip, Typography, Popper, OutlinedInput, InputLabel } from '@mui/material'

const CustomPopper = props => {
  return (
    <Popper
      {...props}
      placement='top'
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ]}
      style={{
        zIndex: 2000, // Ensure the zIndex is higher than the Snackbar
      }}
    />
  )
}

const MassUpdateTypeSelect = ({ name, value, options, displayLabel, onChange, onBlur }) => {
  const [selectedValue, setSelectedValue] = useState(value[0] ?? '')
  useEffect(() => {
    setSelectedValue(value[0] ?? '')
  }, [value])

  const handleChange = event => {
    const newValue = event.target.value
    console.log('NEW VALUE: ', newValue)
    setSelectedValue(newValue)
    onChange({ target: { name, value: [newValue] } })
  }

  const handleBlur = () => {
    if (onBlur) {
      onBlur({ target: { name, value: [selectedValue] } })
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel htmlFor={`${name}-select`}>{displayLabel}</InputLabel>
        <Select
          label={displayLabel}
          labelId={`${name}-label`}
          id={`${name}-select`}
          value={selectedValue}
          onChange={handleChange}
          onBlur={handleBlur}
          // input={<OutlinedInput label={displayLabel} fullWidth sx={{ bgcolor: 'red' }} />}
          MenuProps={{
            style: { zIndex: 2000 },
          }}
          sx={{ maxHeight: 45 }}
        >
          {options.map(option => {
            const typeStyle = {
              height: 20,
              width: 50,
              borderColor: option.color,
              borderWidth: '1px',
              borderStyle: 'solid',
              color: 'black',
              borderRadius: '4px',
              marginRight: '8px',
            }
            const statusStyle = {
              height: 20,
              width: 20,
              backgroundColor: option.color,
              marginRight: '8px',
            }
            return (
              <MenuItem key={option.label} value={option.label}>
                <Chip
                  size='small'
                  label={name === 'type' ? option.type : ''}
                  style={name === 'type' ? typeStyle : statusStyle}
                />
                <Typography variant='subtitle1'>{option.label}</Typography>
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default MassUpdateTypeSelect
