// import React, { useState } from 'react'
// import { Autocomplete, Box, TextField, Chip } from '@mui/material'

// const verticalOptions = [
//   { label: 'Construction', group: 'General' },
//   { label: 'Manufacturing', group: 'General' },
//   { label: 'Entertainment', group: 'General' },
//   { label: 'Restaurant (Food & Beverage)', group: 'General' },
//   { label: 'City/Government', group: 'General' },
//   { label: 'Financial Institution', group: 'General' },
//   { label: 'Technology', group: 'General' },
//   { label: 'Medical', group: 'General' },
//   { label: 'Lawyer', group: 'General' },
//   { label: 'Education', group: 'General' },
//   { label: 'Student', group: 'Housing' },
//   { label: 'Multifamily', group: 'Housing' },
//   { label: 'Senior', group: 'Housing' },
//   { label: 'Homebuilding', group: 'Housing' },
// ]

// const CustomVerticalAutocomplete = ({ label, onChange }) => {
//   const [selectedOptions, setSelectedOptions] = useState([])

//   const handleSelectionChange = (event, value) => {
//     setSelectedOptions(value)
//     if (typeof onChange === 'function') {
//       onChange({ target: { name: 'vertical', value: value.map(v => v.label), options: value } })
//     }
//   }

//   return (
//     <Box sx={{ width: '100%' }}>
//       <Autocomplete
//         multiple
//         options={verticalOptions}
//         getOptionLabel={option => option.label}
//         groupBy={option => option.group}
//         renderGroup={params => (
//           <li key={params.key}>
//             <div style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{params.group}</div>
//             <ul style={{ paddingLeft: 20 }}>{params.children}</ul>
//           </li>
//         )}
//         value={selectedOptions}
//         onChange={handleSelectionChange}
//         renderTags={(value, getTagProps) =>
//           value.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} />)
//         }
//         renderInput={params => (
//           <TextField {...params} variant='outlined' label={label || 'Vertical'} placeholder='Select verticals' />
//         )}
//       />
//     </Box>
//   )
// }

// export default CustomVerticalAutocomplete
import React, { useState } from 'react'
import { Autocomplete, Box, TextField, Chip, Tooltip } from '@mui/material'

const verticalOptions = [
  { label: 'Construction', group: 'General' },
  { label: 'Tourism', group: 'General' },
  { label: 'Marketing Agency', group: 'General' },
  { label: 'Manufacturing', group: 'General' },
  { label: 'Mortgage', group: 'General' },
  { label: 'Real Estate', group: 'General' },
  { label: 'Entertainment', group: 'General' },
  { label: 'Restaurant (Food & Beverage)', group: 'General' },
  { label: 'City/Government', group: 'General' },
  { label: 'Financial Institution', group: 'General' },
  { label: 'Technology', group: 'General' },
  { label: 'Medical', group: 'General' },
  { label: 'Lawyer', group: 'General' },
  { label: 'Education', group: 'General' },
  { label: 'Other', group: 'General' },
  { label: 'Student', group: 'Housing' },
  { label: 'Multifamily', group: 'Housing' },
  { label: 'Senior', group: 'Housing' },
  { label: 'Homebuilding', group: 'Housing' },
  { label: 'Build to rent', group: 'Housing' },
]

const CustomVerticalAutocomplete = ({ label, onChange, onBlur, error, helperText }) => {
  const [selectedOptions, setSelectedOptions] = useState([])

  const handleSelectionChange = (event, value) => {
    setSelectedOptions(value)
    if (typeof onChange === 'function') {
      onChange({
        target: {
          name: 'vertical',
          value: value.map(v => v.label),
          options: value,
        },
      })
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Autocomplete
        multiple
        options={verticalOptions}
        getOptionLabel={option => option.label}
        groupBy={option => option.group}
        renderGroup={params => (
          <li key={params.key}>
            <div style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{params.group}</div>
            <ul style={{ paddingLeft: 20 }}>{params.children}</ul>
          </li>
        )}
        value={selectedOptions}
        onChange={handleSelectionChange}
        renderTags={(value, getTagProps) => {
          const maxDisplay = 1 // Display only the first selection
          return (
            <>
              {value.slice(0, maxDisplay).map((option, index) => (
                <Chip label={option.label} {...getTagProps({ index })} />
              ))}
              {value.length > maxDisplay && (
                <Tooltip
                  title={value
                    .slice(maxDisplay)
                    .map(option => option.label)
                    .join(', ')}
                >
                  <Chip label={`+${value.length - maxDisplay}`} />
                </Tooltip>
              )}
            </>
          )
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            label={label || 'Vertical'}
            placeholder='Select verticals'
            onBlur={onBlur}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </Box>
  )
}

export default CustomVerticalAutocomplete
