import { v4 as uuidv4 } from 'uuid'
import { initializeProductFields } from './initializeProductFields'

export const handleCreateStrategyObject = (products = [], organization = {}, existingStrategyState = {}, newDeal) => {
  const initialState = {
    updatedProducts: [], // Only one array for all products
    addtlInfo: {
      active: true,
      marketingObjective:
        organization?.marketingObjective || existingStrategyState?.addtlInfo?.marketingObjective || '',
      geoTargeting: organization?.geoTargeting || existingStrategyState?.addtlInfo?.geoTargeting || '',
      cmsUsed: organization?.cmsUsed || existingStrategyState?.addtlInfo?.cmsUsed || '',
      demographicTargeting:
        organization?.demographicTargeting || existingStrategyState?.addtlInfo?.demographicTargeting || '',
      productsAndServices:
        organization?.productsAndServices || existingStrategyState?.addtlInfo?.productsAndServices || '',
      callTracking: existingStrategyState?.addtlInfo?.callTracking || '',
      street: organization?.street || '',
      city: organization?.city || '',
      state: organization?.state || '',
      zip: organization?.zip || '',
      type: newDeal?.type || '',
    },
    integration: {
      active: true,
      targetingSpecifics:
        organization?.targetingSpecifics || existingStrategyState?.integration?.targetingSpecifics || '',
      formPresent: existingStrategyState?.integration?.formPresent || '',
    },
    generalInfo: {
      active: true,
      organizationName: organization?.name || existingStrategyState?.generalInfo?.organizationName || '',
      website: organization?.website || existingStrategyState?.generalInfo?.website || '',
      type: existingStrategyState?.generalInfo?.type || newDeal?.type || '',
      vertical: organization?.vertical?.[0] || existingStrategyState?.generalInfo?.vertical || '',
      groContact:
        existingStrategyState?.generalInfo?.groContact || newDeal?.recipients?.authorizedRecipient?.[0]?.email || '',
      orgContact:
        existingStrategyState?.generalInfo?.orgContact || newDeal?.recipients?.contactRecipient?.[0]?.email || '',
    },
  }

  // Map products to ensure unique `instanceId` for each product
  const updatedProducts = products.map(product => ({
    ...product,
    ...initializeProductFields(product),
    instanceId: product.instanceId || uuidv4(),
  }))

  return {
    ...initialState,
    updatedProducts, // Only `updatedProducts` is used for products
  }
}

// import { v4 as uuidv4 } from 'uuid'

// export const socialProducts = [
//   'Meta Advertising',
//   'Linkedin Advertising',
//   'Spotify Advertising',
//   'TikTok Advertising',
//   'SnapChat Advertising',
//   'Social Media Advertising Management Fee',
//   'Linkedin Advertising',
//   'Paid Social Management Fee',
// ]
// export const searchProducts = [
//   'Paid Search Advertising',
//   'Paid Search Management Fee',
//   'Paid Search - Media Spend',
//   'Microsoft Advertising',
//   'Amazon Advertising',
//   'Agency Retainer - Digital',
//   'Management Fee / Retainer',
// ]
// export const displayProducts = [
//   'Programmatic Display Advertising',
//   'Programmatic Display Management Fee',
//   'Programmatic Video Advertising',
//   'Programmatic Display Advertising',
//   'Programmatic Audio Advertising',
//   'Google Display Advertising',
//   'Display Management Fee',
//   'Geofencing Advertising Management Fee',
//   'Youtube Advertising',
//   'Retargeting Advertising Management Fee',
//   'Audio Management Fee',
// ]
// export const websiteProducts = [
//   'Website Hosting',
//   'Website Build - Page Creation',
//   'Website Hosting + 30 Minutes of Maintenance',
//   'Website - Add On',
//   'Website Build - Additional Page Creation',
//   'Website Revisions - Additional',
//   'Standard Website Build',
//   'Website Revisions',
//   'Website Hosting',
//   'Premium Website Build',
//   'Monthly Website Plan',
//   'Website Maintenance Retainer',
//   'Website Transfer Fee',
//   'Website Hosting + 60 Minutes of Maintenance',
//   'Agency Retainer - Website',
//   'E-Commerce Website Build',
//   'Monthly Hosting Plan',
// ]
// export const seoProducts = [
//   'SEO - Custom Tier',
//   'SEO - Tier 1',
//   'Monthly SEO Plan - Tier 1',
//   'SEO - Additional Location',
//   'Listing Sync Pro',
//   'SEO - Tier 2',
//   'SEO - Tier 3',
//   'SEO - Tier 4',
// ]
// // Helper function to add services based on instanceId uniqueness
// const addUniqueService = (existingStrategy = [], service) => {
//   if (!Array.isArray(existingStrategy)) {
//     console.error('Expected an array for existingStrategy, but got:', existingStrategy)
//     existingStrategy = [] // Fallback to an empty array if it's not an array
//   }

//   const index = existingStrategy.findIndex(item => item.instanceId === service.instanceId)
//   if (index !== -1) {
//     existingStrategy[index] = service // Update existing service
//   } else {
//     existingStrategy.push(service) // Add new service
//   }

//   return existingStrategy
// }

// export const addServiceToStrategy = (strategyState, product) => {
//   const service = {
//     ...product,
//     instanceId: product.instanceId || uuidv4(), // Ensure each product has a unique instanceId
//   }

//   // Add the product to the respective strategy category based on productName
//   if (searchProducts.includes(product.productName)) {
//     strategyState.paidSearch = addUniqueService(strategyState?.paidSearch, service)
//   } else if (socialProducts.includes(product.productName)) {
//     strategyState.paidSocial = addUniqueService(strategyState?.paidSocial, service)
//   } else if (displayProducts.includes(product.productName)) {
//     strategyState.display = addUniqueService(strategyState?.display, service)
//   } else if (seoProducts.includes(product.productName)) {
//     strategyState.seo = addUniqueService(strategyState?.seo, service)
//   } else if (product.productName.includes('Website')) {
//     strategyState.website = addUniqueService(strategyState?.website, service)
//   }

//   return strategyState
// }

// export const handleCreateStrategyObject = (products = [], organization = {}, existingStrategyState = {}, newDeal) => {
//   const initialState = {
//     paidSearch: [],
//     paidSocial: [],
//     seo: [],
//     smm: [],
//     website: [],
//     display: [],
//     addtlInfo: {
//       active: true,
//       marketingObjective:
//         organization?.marketingObjective || existingStrategyState?.addtlInfo?.marketingObjective || '',
//       geoTargeting: organization?.geoTargeting || existingStrategyState?.addtlInfo?.geoTargeting || '',
//       cmsUsed: organization?.cmsUsed || existingStrategyState?.addtlInfo?.cmsUsed || '',
//       demographicTargeting:
//         organization?.demographicTargeting || existingStrategyState?.addtlInfo?.demographicTargeting || '',
//       productsAndServices:
//         organization?.productsAndServices || existingStrategyState?.addtlInfo?.productsAndServices || '',
//       callTracking: existingStrategyState?.addtlInfo?.callTracking || '',
//       street: organization?.street || '',
//       city: organization?.city || '',
//       state: organization?.state || '',
//       zip: organization?.zip || '',
//       type: newDeal?.type || '',
//     },
//     integration: {
//       active: true,
//       targetingSpecifics:
//         organization?.targetingSpecifics || existingStrategyState?.integration?.targetingSpecifics || '',
//       formPresent: existingStrategyState?.integration?.formPresent || '',
//     },
//     generalInfo: {
//       active: true,
//       organizationName: organization?.name || existingStrategyState?.generalInfo?.organizationName || '',
//       website: organization?.website || existingStrategyState?.generalInfo?.website || '',
//       type: existingStrategyState?.generalInfo?.type || newDeal?.type || '',
//       vertical: organization?.vertical?.[0] || existingStrategyState?.generalInfo?.vertical || '',
//       groContact:
//         existingStrategyState?.generalInfo?.groContact || newDeal?.recipients?.authorizedRecipient?.[0]?.email || '',
//       orgContact:
//         existingStrategyState?.generalInfo?.orgContact || newDeal?.recipients?.contactRecipient?.[0]?.email || '',
//     },
//   }

//   // Check if products is an array; if not, set it to an empty array and log a warning
//   if (!Array.isArray(products)) {
//     console.warn('Expected products to be an array, but got:', products)
//     products = [] // Default to an empty array if products is not an array
//   }

//   const updatedProducts = products.map(product => ({
//     ...product,
//     instanceId: product.instanceId || uuidv4(), // Ensure each product has an instanceId
//   }))
//   console.log('updatedProducts: ', updatedProducts)
//   const strategyState = updatedProducts.reduce(
//     (acc, product) => {
//       const category = acc[product.productName] || []
//       return addServiceToStrategy(acc, product, category)
//     },
//     { ...initialState }
//   )

//   return {
//     ...strategyState,
//     updatedProducts,
//   }
// }
