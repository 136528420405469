import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { renderTimelineIcons, renderTimelineText } from '../DataGrid/createColumns/renderTimelineColumns'

const TimelineType = ({ type }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Grid
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      item
      xs={2}
      sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
    >
      {isHovered ? (
        <Typography>{renderTimelineText(type)}</Typography>
      ) : (
        <Typography>{renderTimelineIcons(type)}</Typography>
      )}
    </Grid>
  )
}

export default TimelineType
