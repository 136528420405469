// BoardHeaders.js
import React, { useContext } from 'react'
import { Box, Button, Typography, Divider, useTheme } from '@mui/material'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { ContainerContext } from './ContainerProvider'

export const renderHeaderContent = boardName => {
  switch (boardName) {
    case 'Organizations':
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <CorporateFareIcon sx={{ color: 'rgb(114, 229, 218)' }} />
          <Divider orientation='vertical' flexItem />
          <Typography>{boardName}</Typography>
        </Box>
      )
    default:
      return null
  }
}

const BoardHeaders = ({ boardName, data, handleOpenCreateOrganization }) => {
  const theme = useTheme()
  const textColor = theme.palette.mode === 'dark' ? 'white' : 'black'

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 1,
      }}
    >
      {renderHeaderContent(boardName)}
      {boardName === 'Organizations' ? (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography sx={{ color: textColor, fontSize: 24, fontWeight: 'bold' }}>{data ? data.name : ''}</Typography>
          <Divider orientation='vertical' flexItem />
          <Typography sx={{ color: textColor }}>{data ? data.status : ''}</Typography>
          <Button onClick={handleOpenCreateOrganization}>Create Organizations</Button>
        </Box>
      ) : (
        <Typography sx={{ color: textColor, flexGrow: 1, textAlign: 'center' }}>{boardName}</Typography>
      )}
    </Box>
  )
}

export default BoardHeaders
