// Helper function to initialize product fields based on `productFieldsConfig`
import { productFieldsConfig } from './strategyFieldConfig'

export const initializeProductFields = product => {
  const fieldsConfig = productFieldsConfig[product.productName] || []
  const initializedFields = {}

  // Set each field in `fieldsConfig` to an empty string or default value
  fieldsConfig.forEach(({ fieldName }) => {
    initializedFields[fieldName] = ''
  })

  return { ...product, ...initializedFields }
}
