import { Table, TableContainer } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ApprovalTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: 20, // Increase the margin top to ensure the top border is visible
  padding: '10px', // Add padding to create space inside the container
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
}))
export const ApprovalTable = styled(Table)(({ theme }) => ({
  marginTop: 5, // Increase the margin top to ensure the top border is visible
  padding: '5px', // Add padding to create space inside the container
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)',
}))

export const tableCellStyles = (theme, color, item) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42)' : 'rgba(255, 255, 255, 1)',
  color: color ? color : theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
  border: theme.palette.mode === 'dark' ? '1px solid rgba(224, 224, 224, 0.4)' : '1px solid rgba(0,0,0,0.4)',
  textAlign: 'left',
})
export const tableRowStyles = theme => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(30, 39, 53)' : 'rgba(255,255,255,1)',
  // border: theme.palette.mode === 'dark' ? '1px solid rgba(224, 224, 224, 0.4)' : '1px solid rgba(0,0,0,0.4)',
})
export const tableHeaderStyles = theme => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(232, 240, 254)', // light gray with hint of blue
  textAlign: 'left',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)',
  border: theme.palette.mode === 'dark' ? '1px solid rgba(224, 224, 224, 0.4)' : '1px solid rgba(0,0,0,0.4)',
})
export const tableSectionStyles = theme => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgb(232, 246, 252)', // lighter gray
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
  // border: theme.palette.mode === 'dark' ? '1px solid rgba(224, 224, 224, 0.4)' : '1px solid rgba(0,0,0,0.4)',
})
export const tableKeyStyles = theme => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgb(22, 28, 42)' : 'rgba(255, 255, 255, 1)',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)',
  // border: theme.palette.mode === 'dark' ? '1px solid rgba(224, 224, 224, 0.4)' : '1px solid rgba(0,0,0,1)',
  textAlign: 'left',
})
export const infoTableContainerStyles = theme => ({
  fontWeight: 'bold',
  borderRadius: '25px',
  minHeight: '330px',
  textAlign: 'left',
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)',
  border: theme.palette.mode === 'dark' ? '1px solid rgba(224, 224, 224, 0.4)' : '1px solid rgba(0,0,0,0.4)',
  bgcolor: theme.palette.background.paper,
})
export const infoTableHeaderStyles = theme => ({
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)',
})
export const infoTableTextStyles = theme => ({
  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
})
// backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(0, 0, 0, 0.2)',
// backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(250, 212, 212)', // light coral
// backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(243, 229, 245)', // pale lavender
// backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(245, 245, 220)', // warm beige
// backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(230, 249, 232)', // pastel green
// backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(224, 247, 250)', // muted aqua
// backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(232, 240, 254)', // light gray with hint of blue
// backgroundColor: theme.palette.mode === 'dark' ? 'rgb(26, 22, 22)' : 'rgba(221, 238, 255)', // soft blue
