import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { baseUrl } from '../queryMutations/mutationFunctions' // Ensure baseUrl is correct
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'

const rejectDeal = async ({ dealId, requestor, approverId, reason }) => {
  try {
    const slackToken = localStorage.getItem('slack_access_token')
    if (!slackToken) {
      throw new Error('No authentication token available')
    }

    const response = await axios.post(
      `${baseUrl}/aws/deals/${dealId}/reject`, // Pass the dealId correctly here
      { requestor, approverId, reason, slackToken }, // This is the payload with approverId
      {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: slackToken,
        },
      }
    )
    return response.data
  } catch (error) {
    console.error('Error approving the deal:', error)
    throw error // Propagate the error to handle it properly in the mutation
  }
}

export const useRejectDeal = () => {
  const { showSnackbar } = useSnackbarContext()

  const mutation = useMutation({
    mutationFn: rejectDeal,
    onSuccess: data => {
      console.log('Deal rejected successfully:', data)
      showSnackbar('Deal rejected successfully', 'success')
    },
    onError: error => {
      console.error('Error in deal approval process:', error)
      showSnackbar('Error during approval', 'error')
    },
  })
  return mutation
}
